import { faEdit, faPlus } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { connect } from "react-redux";
import API from "../../../Api";
import { loginUserInfoAdmin } from '../../../Redux/Actions/AdminAuthenticationActions'
import AddUser from "../ConfigurationsPopups/AddUser";
import ResetPassword from "../ConfigurationsPopups/ResetPassword";


class MasterUsers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      masterUsers: [],      
      selectedUser: {},
      errorMessage: '',
      isPopupOpen: false, 
      isChangeOpen: false,
      showToast: false,
      toastMessage: '',
      toastHeader: ''           
      
    };
  }

  async componentDidMount() {
    new API().getMasterUsers().then(result => {
      this.setState({ masterUsers: result.data, isChangeOpen: false, isPopupOpen: false })
    })
  }  

  onChangePasswordClick = (e) => {
    let id = e.target.id;
    if (e.target.nodeName == "path") {
      id = e.target.parentNode.id;
    }

    this.setState({ isChangeOpen: true, isPopupOpen: false, errorMessage: '', selectedUser: this.state.masterUsers.filter(p => p.id == id)[0] });
  }

  onAddClick = (e) => {
    this.setState({ isChangeOpen: false,  isPopupOpen: true, errorMessage: '', selectedUser: null });
  }

  onCloseModel = (e) => {
    this.setState({ isChangeOpen: false, isPopupOpen: false });
  }

  onChangePassword = (e) =>{
    let  toastMessage = 'Password reset successfully.';    
 

    new API().resetPassword(e).then(result => {
      if (result.data.result) {
        this.setState({ errorMessage: '', showToast: true, toastHeader: 'Success', toastMessage: toastMessage, isPopupOpen: false });
        this.componentDidMount();
      }
      else {
        if (result.data.errorMessage != "") {
          this.setState({ isChangeOpen: true, errorMessage: result.data.errorMessage });
        }
      }

    });
  }

  onConfirm = (e) => {
    let toastMessage = '';    
    if (this.state.selectedUser == null) {
      toastMessage = 'User added successfully.';      
    }

    new API().addMasterUser(e).then(result => {
      if (result.data.result) {
        this.setState({ errorMessage: '', showToast: true, toastHeader: 'Success', toastMessage: toastMessage, isPopupOpen: false });
        this.componentDidMount();
      }
      else {
        if (result.data.errorMessage != "") {
          this.setState({ isPopupOpen: true, errorMessage: result.data.errorMessage });
        }
      }

    });
  }  

  OnCloseToast = (e) => {
    this.setState({ showToast: !this.state.showToast });
  }
  
  render() {

    return <>


      <ToastContainer position="bottom-end">
        <Toast delay={10000} autohide show={this.state.showToast} onClose={this.OnCloseToast}>
          <Toast.Header>
            <strong className="me-auto"> {this.state.toastHeader}</strong>
          </Toast.Header>
          <Toast.Body className={this.state.isDeletedToast ? "bg-danger text-white" : "bg-success text-white"}> {this.state.toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <div className="d-flex justify-content-between align-items-center mob-colls">
        <h2> Master Users </h2>
        <div className="d-flex">         
          <button onClick={this.onAddClick} className="add-new me-2"> <FontAwesomeIcon icon={faPlus} /> </button>
        </div>
      </div>

      <div className="table-responsive bg-white">

        {
          this.state.masterUsers.length == 0 ?          
            <div className="nobookingtext text-center"> Users data not found  </div> : ""
        }
       
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">Username</th>                  
                  <th scope="col">Reset Password</th>
                </tr>
              </thead>
              <tbody>
                {this.state.masterUsers.map((item, i) => {
                  return [
                    <tr key={item.id}>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.username}</td>
                         <td > <button className="edit" onClick={this.onChangePasswordClick} id={item.id}> <FontAwesomeIcon onClick={this.onChangePasswordClick} id={item.id} icon={faEdit} /> </button>
                        </td>
                    </tr>
                  ]
                })}
              </tbody>
            </table>      
      </div>     

      {
        this.state.isPopupOpen ?
          <AddUser isPopupOpen={this.state.isPopupOpen} onCloseModel={this.onCloseModel} errorMessage={this.state.errorMessage} onConfirm={this.onConfirm}  />
          : ""
      }

      {
        this.state.isChangeOpen ?
          <ResetPassword selectedUser={this.state.selectedUser} isChangeOpen={this.state.isChangeOpen} onCloseModel={this.onCloseModel} onChangePassword={this.onChangePassword} errorMessage={this.state.errorMessage} />
          : ""
      }
    </>
  }
}

const mapStateToProps = (state) => ({
  AdminAuthentication: state.AdminAuthentication,
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(MasterUsers);