import { ADMINEDITBOOKINGINITIALDATA } from './../Actions/Type'

let initialState = {   
    locations: [],
    customerTypes: [],
    OpportunitySizes: [],
    timeSlots: [],
    regions: [],
    verticals: [],
    visitType: []
};

function AdminInitialDataReducer(state = initialState, action) {
    if (action.type == ADMINEDITBOOKINGINITIALDATA)
        return { ...state,  
                           locations : action.bookingInfo.locations, 
                           customerTypes : action.bookingInfo.customerTypes, 
                           OpportunitySizes : action.bookingInfo.OpportunitySizes, 
                           timeSlots : action.bookingInfo.timeSlots, 
                           regions : action.bookingInfo.regions, 
                           verticals : action.bookingInfo.verticals,
                           visitType : action.bookingInfo.visitType }
    else
        return state;
}

export default AdminInitialDataReducer;