import React, { Component } from "react";
import axios from "axios";
import API from "../../Api";
import FluidIcon from '../../Images/fluid-ec.png'
import Countdown, { zeroPad } from 'react-countdown';
import { connect } from "react-redux";
import { bookingInfo } from '../../Redux/Actions/BookingAction';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownLong } from "@fortawesome/free-solid-svg-icons";
import withRouter from "../WithRouter";
import { Navigate } from "react-router-dom";

import { bookingInfoNext } from '../../Redux/Actions/BookingActionNext'
import { bookingProducts, clearBookingProducts } from '../../Redux/Actions/BookingProductsAction'
import { currentPage } from '../../Redux/Actions/CurrentPageAction'
import { customerLunch } from '../../Redux/Actions/CustomerLunchAction'
import { clearCustomersList } from '../../Redux/Actions/CustomersListAction'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { faQuestionCircle } from "@fortawesome/fontawesome-free-solid";


class OTPValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name:  this.props.BookingInfo.page1.bookieName ? this.props.BookingInfo.page1.bookieName : '',
      email: this.props.BookingInfo.page1.bookieEmail ? this.props.BookingInfo.page1.bookieEmail : '',
      otpValidated: false,
      otpError: '',
      isResendEnable: false,
      redirect: '/fluidbooking',
      currentDateCount: Date.now() + (60000 * (localStorage.getItem("otptimer") != null ? localStorage.getItem("otptimer") : 1)),
      isVerifyEnabled: false,
      otptimer: localStorage.getItem("otptimer") != null ? localStorage.getItem("otptimer") : 1
    }
    this.ref0 = React.createRef()
    this.ref1 = React.createRef()
    this.ref2 = React.createRef()
    this.ref3 = React.createRef()
    this.countdownRef = React.createRef();
  }
 
  

  handleBackSpace = (e, index) => {
    e.preventDefault();
    if (e.keyCode == 8) {
      switch (index) {
        case 1:
          this.setState({
            focusIndex: 1,
            firstDigi: '',
            firstDigiLength: 0,
            isVerifyEnabled: false
          })
          break;
        case 2:
          this.setState({
            focusIndex: 1,
            secondDigi: '',
            secondDigiLength: 0,
            isVerifyEnabled: false
          })
          break;
        case 3:
          this.setState({
            focusIndex: 2,
            thirdDigi: '',
            thirdDigiLength: 0,
            isVerifyEnabled: false
          })
          break;
        case 4:
          this.setState({
            focusIndex: 3,
            fourthDigi: '',
            forthDigiLength: 0,
            isVerifyEnabled: false
          })
          break;
      }
    }
  }

  storeDigi = (e, index) => {
    e.preventDefault();
    this.setState({ otpError: "" })
    let valueToken = e.nativeEvent.data
    const re = /^[0-9]{1}\b$/;
    if ((valueToken === '' || re.test(valueToken))) {
      switch (index) {
        case 1:
          this.setState({
            focusIndex: 2,
            firstDigi: valueToken,
            firstDigiLength: e.target.value.length,
            isVerifyEnabled: e.target.value.length > 0 && this.state.secondDigiLength > 0 && this.state.thirdDigiLength > 0 && this.state.forthDigiLength > 0
          })
          break;
        case 2:
          this.setState({
            focusIndex: 3,
            secondDigi: valueToken,
            secondDigiLength: e.target.value.length,
            isVerifyEnabled: this.state.firstDigiLength > 0 && e.target.value.length > 0 && this.state.thirdDigiLength > 0 && this.state.forthDigiLength > 0
          })
          break;
        case 3:
          this.setState({
            focusIndex: 4,
            thirdDigi: valueToken,
            thirdDigiLength: e.target.value.length,
            isVerifyEnabled: this.state.firstDigiLength > 0 && this.state.secondDigiLength > 0 && e.target.value.length > 0 && this.state.forthDigiLength > 0
          })
          break;
        case 4:
          this.setState({
            focusIndex: 5,
            fourthDigi: valueToken,
            forthDigiLength: e.target.value.length,
            isVerifyEnabled: this.state.firstDigiLength > 0 && this.state.secondDigiLength > 0 && this.state.thirdDigiLength > 0 && e.target.value.length > 0
          })
          break;
      }
    }
  }

  focusDiv = () => {
    if (this.state.focusIndex) {
      switch (this.state.focusIndex) {
        case 1:
          this.ref0.current.focus()
          break;
        case 2:
          this.ref1.current.focus()
          break;
        case 3:
          this.ref2.current.focus()
          break;
        case 4:
          this.ref3.current.focus()
          break;
      }
    }
  }


  OnSubmitClick = (e) => {
    e.preventDefault();
    let otpInfo = {
      name: this.props.BookingInfo.page1.bookieName,
      email: this.props.BookingInfo.page1.bookieEmail,
      otp: this.getNumber(),
      URL : process.env.REACT_APP_PORTAL_URL
    };

    new API().verifyOtp(otpInfo).then(result => {
      if (result.data) {
        this.setState({
          otpValidated: true,
          otpError: ''
        })

        this.props.bookingInfo({
          page1: {
            isvalidated : true,
            locationId: '',
            bookieName: this.props.BookingInfo.page1.bookieName,
            bookieEmail: this.props.BookingInfo.page1.bookieEmail,
            customerName: '',
            oppotunitySizeId: '',
            opportunityId: '',
            customerTypeId: '',
            regionId: '',
            subregionId: '',
            verticalId: '',
            visitTypeId :'',
            timezone : '',
            disableFields : false
          }
        });
        this.props.bookingInfoNext({
          page2: {
            sizeOfVisitingParty: '',
            hclParticipants: '',
            timeSlotId: '',
            selectedDate: new Date(),
            selectedEndDate: new Date(),
            selectedTime: '',
          }
        });
        this.props.clearBookingProducts();
        this.props.currentPage(1);
        this.props.customerLunch(false);
        this.props.clearCustomersList();


      }
      else {
        this.setState({
          otpError: "Please enter valid OTP"
        })
      }

    });
  }


  sendOTPEmailToUser(headers, otp) {
    let bodyMsg =
      `<html><head></head><body><br />Dear ${this.props.BookingInfo.page1.bookieName}<br /><br /> Your OTP for login to DWP Fluid Booking Application is:<br />
                 <span style=color:blue;>${otp}</span>              
                 <br />  ${process.env.REACT_APP_PORTAL_URL}    
                 <br /> <b>Thanks</b>
                 Fluid Experience Centre Team      
                </body>
               </html>`
    bodyMsg = bodyMsg.replace(/(?:\r\n|\r|\n)/g, '<br>')
    let sendmessageBody = `{
               "message": {
               "subject": "OTP Confirmation Alert",
               "body": {
               "contentType": "Html",
               "content": "${bodyMsg}"
               },
               "toRecipients": [
               {
               "emailAddress": {
               "address": "${this.props.BookingInfo.page1.bookieEmail}"
               }
               }
               ]
               },
               "saveToSentItems": "true"
               }`
    const URL = `https://graph.microsoft.com/v1.0/users/fluidcentrebookingsystem@hcldwpdev.com/sendMail`;
    axios.post(URL, sendmessageBody, { headers })
      .then(response => {
      }).catch(error => console.log("error while sending mail", error));
  }

  renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return null;
    }
    else
      return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;

  };

  enableResend = () => {
    this.setState({ isResendEnable: true, otpError: '' })
  }
  getNumber = () => {
    return Number(this.state.firstDigi * 1000 + this.state.secondDigi * 100 + this.state.thirdDigi * 10 + this.state.fourthDigi * 1)
  }

  componentDidMount() {
    this.focusDiv();
  }

  componentDidUpdate() {
    this.focusDiv();
  }  

  exceptThisSymbols(key) {
    return !!(key.keyCode > 31 && (key.keyCode < 48 || key.keyCode > 57) || key.keyCode == 8 || key.keyCode == 32 || key.keyCode == 9 || key.keyCode == 37 || key.keyCode == 39);
  }

  OnResendClick = (e) => {
    this.countdownRef.current.api.start()
    e.preventDefault();

    this.setState({
      isResendEnable: false,
      firstDigi: '',
      secondDigi: '',
      thirdDigi: '',
      fourthDigi: '',
      currentDateCount: Date.now() + (60000 * this.state.otptimer)
    })

    let otp = (Math.floor(1000 + Math.random() * 9000));
    new API().getAccessToken().then(result => {
      const headers = {
        'Authorization': `Bearer ${result.data}`,
        'Content-Type': 'application/json'
      };
      this.sendOTPEmailToUser(headers, otp);

      //insert login details with otp in DB         
      let loginInfo = {
        name: this.props.BookingInfo.page1.bookieName,
        email: this.props.BookingInfo.page1.bookieEmail,
        otp: otp,
        URL : process.env.REACT_APP_PORTAL_URL
        //creationdate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
      };
      new API().addLoginEntryOtp(loginInfo).then(result => {
      });

      this.setState({
        loginSubmitted: true
      })
    })
  }

  renderTooltip = (title) => (
    <Tooltip placement='right'>{title}</Tooltip>
  );

  render() {

    if (this.state.otpValidated) {
      return <Navigate replace to="/fluidbooking" />
    }



    return (
      <form className="w-100">
        <div className="login-wrapper">
          <div className="login-box text-center position-relative">
            <a className="backarrow" href="/"> <FontAwesomeIcon icon={faArrowDownLong} /> </a>
            <div className="w-50 mx-auto"> <img src={FluidIcon} className="img-fluid" alt="" /></div>
            <div className="row">
              <div className="title">
                <h3 className="otp-heading">OTP VERIFICATION</h3>
                {
                  !this.state.isResendEnable && <p className="info">An OTP has been sent to {this.props.BookingInfo.page1.bookieEmail} <OverlayTrigger overlay={this.renderTooltip('Incase OTP not received in Inbox then "Check in Junk & Spam folders"')}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger> </p>
                }
                <p className="msg">Please enter OTP to verify</p>
              </div>
              <div className="otp-input-fields">
                <input name="otp1" autoFocus type="number" onChange={(e) => this.storeDigi(e, 1)} onKeyDown={(e) => this.exceptThisSymbols(e) && this.handleBackSpace(e, 1)} ref={this.ref0} value={this.state.firstDigi} className="otp__digit otp__field__1" tabIndex={1} pattern="\d*" />
                <input name="otp2" type="number" onChange={(e) => this.storeDigi(e, 2)} onKeyDown={(e) => this.exceptThisSymbols(e) && this.handleBackSpace(e, 2)} ref={this.ref1} value={this.state.secondDigi} className="otp__digit otp__field__2" tabIndex={2} pattern="\d*" />
                <input name="otp3" type="number" onChange={(e) => this.storeDigi(e, 3)} onKeyDown={(e) => this.exceptThisSymbols(e) && this.handleBackSpace(e, 3)} ref={this.ref2} value={this.state.thirdDigi} className="otp__digit otp__field__3" tabIndex={3} pattern="\d*" />
                <input name="otp4" type="number" onChange={(e) => this.storeDigi(e, 4)} onKeyDown={(e) => this.exceptThisSymbols(e) && this.handleBackSpace(e, 4)} ref={this.ref3} value={this.state.fourthDigi} className="otp__digit otp__field__4" tabIndex={4} pattern="\d*" />
              </div>
            </div>

            <div className="modal-footer justify-content-center">
              <button type="button" disabled={this.state.isResendEnable ? false : true} onClick={this.OnResendClick} className={this.state.isResendEnable ? "otp-enable-btn" : "otp-disable-btn"} data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal">Resend OTP</button>
            </div>

            <div className={this.state.isResendEnable ? "disable" : ""}>
              <Countdown ref={this.countdownRef} key={this.state.currentDateCount} date={this.state.currentDateCount} renderer={this.renderer} onComplete={this.enableResend} autoStart={true}>
              </Countdown>
            </div>

            <div className="col-md-12 justify-content-center">
              <button type="button" disabled={this.state.isResendEnable || !this.state.isVerifyEnabled ? true : false} onClick={this.OnSubmitClick} className={this.state.isResendEnable || !this.state.isVerifyEnabled ? "fill-button-greyed btn-small mt-5 mx-auto w-100 justify-content-center" : "fill-button btn-small mt-5 mx-auto w-100 justify-content-center"} data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal">Verify {"&"} Continue</button>
            </div>
            <label className="errorMessage">
              {this.state.otpValidated ? "" : this.state.otpError}
            </label>
          </div>
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  BookingInfo: state.BookingInfo,
  BookingInfoNext: state.BookingInfoNext,
  CurrentPage: state.CurrentPage,
  BookingProducts: state.BookingProducts,
  CustomerLunch: state.CustomerLunch,
  CustomersList: state.CustomersList,
})

export default withRouter(connect(mapStateToProps, { bookingInfo, clearBookingProducts, bookingInfoNext, currentPage, clearCustomersList, customerLunch, bookingProducts })(OTPValidation));


