import { Component } from "react";
import { Modal } from 'react-bootstrap'
import { AllowOnlyAlphabatsWithBrackets } from "../Validation";

class UpdateConfiguration extends Component {

  constructor(props) {
    super(props);
    this.state = {
      configurationId: this.props.selectedConfiguration.configurationId,
      fieldValue: this.props.selectedConfiguration != null ? this.props.selectedConfiguration.configurationValue : '',
      isfieldError: false,
      errorMessage: '',
      displayName: this.props.selectedConfiguration != null ? this.props.selectedConfiguration.displayName : '',
    }
  }

  onfieldchange = (e) => {
    this.setState({ fieldValue: e.target.value })

    if (this.state.configurationId == "BookingIDInitials") {
      if (e.target.value.length < 1)
        this.setState({ isfieldError: true, errorMessage: 'Please provide atleast 1 characters' });
      else
        this.setState({ isfieldError: false, errorMessage: '' })
    }
    if (this.state.configurationId == "AdminEmails") {
      let splitValues = e.target.value.split(',');
      if (splitValues.length > 0) {
        splitValues.map(item => {
          const regex = /[a-zA-Z]@(hcl|hcltech)\.com\b$/g;
          if (regex.test(item.toLowerCase()) === false) {
            this.setState({ isfieldError: true, errorMessage: 'Invalid email. Please use HCL email id.' })
          }
          else
            this.setState({ isfieldError: false, errorMessage: '' })

        })
      }
    }
    if (this.state.configurationId == "OTPResetTimer" || this.state.configurationId =="PerceptionRed" || this.state.configurationId == "PerceptionAmber") {
      if (e.target.value.length < 1)
        this.setState({ isfieldError: true, errorMessage: 'Value should be greater than 0' })
      else
        this.setState({ isfieldError: false, errorMessage: '' })
    }
  }

  onYesClick = (e) => {

    if (this.state.configurationId == "BookingIDInitials") {
      if (this.state.fieldValue.length < 1)
        this.setState({ isfieldError: true, errorMessage: 'Please provide atleast 1 characters' });
      else {
        this.setState({ isfieldError: false, errorMessage: '' });
        this.props.onConfirm(this.state.fieldValue);
      }

    }
    if (this.state.configurationId == "AdminEmails") {
      let splitValues = this.state.fieldValue.split(',');
      if (splitValues.length > 0) {
        let haserror = false;
        splitValues.map(item => {
          const regex = /[a-zA-Z]@(hcl|hcltech)\.com\b$/g;
          if (regex.test(item.toLowerCase()) === false) {
            haserror = true;
            this.setState({ isfieldError: true, errorMessage: 'Invalid email. Please use HCL email id.' })
          }
          else
            this.setState({ isfieldError: false, errorMessage: '' })
        })
        if (!haserror)
          this.props.onConfirm(this.state.fieldValue);
      }
    }
    if (this.state.configurationId == "OTPResetTimer" || this.state.configurationId =="PerceptionRed" || this.state.configurationId == "PerceptionAmber") {
      if (this.state.fieldValue.length < 1)
        this.setState({ isfieldError: true, errorMessage: 'Value should be greater than 0' })
      else {
        this.setState({ isfieldError: false, errorMessage: '' });
        this.props.onConfirm(this.state.fieldValue);
      }
    }
  }

  checkOnBlur = () => {
    if (this.state.configurationId == "BookingIDInitials") {
      if (this.state.fieldValue.length < 1)
        this.setState({ isfieldError: true, errorMessage: 'Please provide atleast 1 characters' });
      else {
        this.setState({ isfieldError: false, errorMessage: '' });
      }

    }
    if (this.state.configurationId == "AdminEmails") {
      let splitValues = this.state.fieldValue.split(',');
      if (splitValues.length > 0) {
        splitValues.map(item => {
          const regex = /[a-zA-Z]@(hcl|hcltech)\.com\b$/g;
          if (regex.test(item.toLowerCase()) === false) {
            this.setState({ isfieldError: true, errorMessage: 'Invalid email. Please use HCL email id.' })
          }
          else
            this.setState({ isfieldError: false, errorMessage: '' })

        })
      }
    }
    if (this.state.configurationId == "OTPResetTimer" || this.state.configurationId =="PerceptionRed" || this.state.configurationId == "PerceptionAmber") {
      if (this.state.fieldValue.length < 1)
        this.setState({ isfieldError: true, errorMessage: 'Value should be greater than 0' })
      else {
        this.setState({ isfieldError: false, errorMessage: '' });
      }
    }
  }

  OnInputNumber = (e) => {
    if (e.target.value.length > e.target.maxLength)
      e.target.value = e.target.value.slice(0, e.target.maxLength);
  }

  render() {
    return (
      <div className="modal show" style={{ display: 'block', position: 'initial' }}>
        <Modal show={this.props.isPopupOpen} onHide={this.props.onCloseModel} >
          <Modal.Header className="modal-footer justify-content-center">
            <h5 id="exampleModalLabel">{this.props.isEditClick ? "Update" : "Add"}Configuration </h5>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-12 w-100">
              <div className="mb-3">
                <h6 > {this.state.displayName} </h6>
              </div>
            </div>

            <div className="feedback-wrapper">
              <div className="mt-3">
                {
                  this.state.configurationId == "OTPResetTimer" || this.state.configurationId =="PerceptionRed" || this.state.configurationId == "PerceptionAmber" ?
                    <input type="number" onInput={this.OnInputNumber} maxLength={2} onChange={this.onfieldchange} value={this.state.fieldValue} className={this.state.isfieldError ? "form-control cancelBox is-invalid" : "form-control cancelBox"} placeholder="Enter Configuration value in minutes upto 2 numbers"
                      id="exampleFormControlTextarea1" name="fieldValue" rows="2" onBlur={() => this.checkOnBlur()}></input>
                    : ""
                }
                {this.state.configurationId == "AdminEmails" ?
                  <textarea onChange={this.onfieldchange} value={this.state.fieldValue} className={this.state.isfieldError ? "form-control cancelBox is-invalid" : "form-control cancelBox"} placeholder="Enter Configuration value"
                    id="exampleFormControlTextarea1" name="fieldValue" rows="2" onBlur={() => this.checkOnBlur()}></textarea>
                  : ""}
                {this.state.configurationId == "BookingIDInitials" ?
                  <textarea onPaste={e => !AllowOnlyAlphabatsWithBrackets(e) && e.preventDefault()} onKeyDown={e => !AllowOnlyAlphabatsWithBrackets(e) && e.preventDefault()} onChange={this.onfieldchange} value={this.state.fieldValue} className={this.state.isfieldError ? "form-control cancelBox is-invalid" : "form-control cancelBox"} placeholder="Enter Configuration value"
                    id="exampleFormControlTextarea1" name="fieldValue" rows="2" onBlur={() => this.checkOnBlur()}></textarea>
                  : ""}

                
              </div>
            </div>
            {
              this.state.isfieldError ?
                <div style={{ color: 'red' }}> {this.state.errorMessage}</div> : ''
            }


            <div className="d-flex justify-content-center mt-4">
              <button type="button" onClick={this.props.onCloseModel} className="btn outline-button btn-small" data-bs-dismiss="modal">Cancel</button>
              <button type="button" onClick={(e) => this.onYesClick(e)} className="btn fill-button btn-small">Update</button>
            </div>
          </Modal.Body>



        </Modal>

      </div>
    );
  }

}

export default UpdateConfiguration;