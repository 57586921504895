import { Component } from "react";
import API from "../../Api";
import withRouter from "../WithRouter";
import BackgroundImage from '../../Images/bg.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import ArrowImage from '../../Images/arrow.png'
import { Navigate } from "react-router-dom";

class CustomerListFeedBackQR extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            redirect: '',
            bid : this.props.params.bid,
            kid : this.props.params.kid,
            translations : []
        };
    }

    OnFeedbackButtonClick = (e) => {
        if (e.target.id != '') {
            this.setState({ redirect: "/feedbackcustomerqr/" + this.props.params.bid + "/" +  this.props.params.kid + "/" + e.target.id });
        }
    }    

    async componentDidMount() {

        await new API().getMasterTranslationsByType(14).then(result=>{
            this.setState({translations : result.data})
        })
        await new API().getBookingCustomersFromQR(this.props.params.bid , this.props.params.kid).then(result => {
            this.setState({ customers: result.data });
        });

    }

    getTranslations(key) {   

        if(this.state.translations.filter(p => p.appKey == key)[0] !=null )
        return this.state.translations.filter(p => p.appKey == key)[0].appValue;
        else
        return "";
      }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
       
            return <>

                <div id="wrapper">

                    <div id="sidebar">
                        <img src={BackgroundImage} width="100%" alt="" />
                    </div>

                    <div className="content-area">
                        <section className="wizard-section booking-area">


                            <div className="row no-gutters mt-4">
                                <h6 className="pb-1"> {this.getTranslations("Admin_CustomerListPage_Heading")} </h6>
                            </div>

                            <div className="row">


                                {this.state.customers.map((customer, i) => {
                                    return [
                                        <div key={customer.publicId} className={customer.isFeedbackDone ? "col-md-12 vc-feedback-disable" : "col-md-12 vc-feedback"} >
                                            <button onClick={this.OnFeedbackButtonClick} key={customer.publicId}
                                                id={customer.publicId}> {customer.customerName}
                                                <i id={customer.publicId} className="fa fa-angle-right float-end">
                                                    {
                                                        customer.isFeedbackDone ?
                                                            <FontAwesomeIcon id={customer.publicId} icon={faCircleCheck}></FontAwesomeIcon>
                                                            : <img width={11} id={customer.publicId} src={ArrowImage}></img>
                                                    }

                                                </i><br />
                                                <span id={customer.publicId}> {customer.isFeedbackDone ? this.getTranslations("Admin_CustomerListPage_FeedbackDone") : this.getTranslations("Admin_CustomerListPage_Feedback")} </span>
                                            </button>
                                        </div>
                                    ]
                                })}


                            </div>
                           
                        </section>
                    </div>

                </div>

                <footer className="text-center">
                Copyright © {new Date().getFullYear()} HCL Technologies Limited
                </footer>


            </>
        
    }
}


export default (withRouter(CustomerListFeedBackQR));