import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { DateRangePicker } from "rsuite";
import API from "../../../Api";
import DownloadFile from "../DownloadFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/fontawesome-free-solid";

class ErrorLogs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errorLogs: [],
            isConsentOpen: false,
            selectedLocation: {},
            errorMessage: '',
            activePage: 1,
            TotalRecords: 0,
            RecordPerPage: 0,
            startDate: null,
            endDate: null,
            dataTodownload: [],
            isDownloadOpen: false
        };
    }

    async componentDidMount() {
        new API().getErrorLogs(1).then(result => {
            this.setState({ errorLogs: result.data.errors, RecordPerPage: result.data.recordPerPage, TotalRecords: result.data.totalCount })
        })
    }

    handleSubmitClick = (value) => {
        let startDateValue = moment(value[0]).format('YYYY-MM-DD')
        let endDateValue = moment(value[1]).format('YYYY-MM-DD hh:mm')
        this.setState({ startDate: startDateValue, endDate: endDateValue });

        new API().getErrorLogsFiltered(1, startDateValue, endDateValue).then(result => {
            this.setState({ errorLogs: result.data.errors, RecordPerPage: result.data.recordPerPage, TotalRecords: result.data.totalCount })

        });

    }

    OnDateChange = (value) => {
        this.setState({ startDate: value != null ? value[0] : null, endDate: value != null ? value[1] : null });
        if (value == null) {
            new API().getErrorLogs(1).then(result => {
                this.setState({ errorLogs: result.data.errors, RecordPerPage: result.data.recordPerPage, TotalRecords: result.data.totalCount })
            })
        }
    }

    OnPageClick = (e) => {
        this.setState({ activePage: e.selected + 1 });
        if (this.state.startDate == null && this.state.endDate == null) {
            new API().getErrorLogs(e.selected + 1).then(result => {
                this.setState({ errorLogs: result.data.errors, RecordPerPage: result.data.recordPerPage, TotalRecords: result.data.totalCount })
            })
        }
        else {
            let startDateValue = moment(this.state.startDate).format('YYYY-MM-DD')
            let endDateValue = moment(this.state.endDate).format('YYYY-MM-DD')

            new API().getErrorLogsFiltered(e.selected + 1, startDateValue, endDateValue).then(result => {
                this.setState({ errorLogs: result.data.errors, RecordPerPage: result.data.recordPerPage, TotalRecords: result.data.totalCount })

            });
        }

    }

    downloadErrorLogs = () => {

        if (this.state.startDate != null && this.state.endDate != null) {
            new API().downloadErrorLogsFiltered(this.state.startDate, this.state.endDate).then(result => {
                let data = [];
                result.data.map(item => {
                    data.push({
                        id: item.id,
                        errorInformation: item.errorInformation,
                        loggedDate: moment(moment.utc(item.loggedDate).toDate()).local().format('MMMM Do YYYY HH:mm A'),
                        stackTrace: item.stackTrace
                    })
                });

                this.setState({ dataTodownload: data, isDownloadOpen: true });
            })
        }
        else {
            new API().downloadErrorLogs().then(result => {
                let data = [];
                result.data.map(item => {
                    data.push({
                        ID: item.id,
                        ErrorInformation: item.errorInformation,
                        LoggedDate: moment(moment.utc(item.loggedDate).toDate()).local().format('MMMM Do YYYY HH:mm A'),
                        StackTrace: item.stackTrace
                    })
                });

                this.setState({ dataTodownload: data, isDownloadOpen: true });
            })
        }


    }

    onDownload = () => {
        if (this.state.dataTodownload.length > 0) {
            this.generateCSV(this.state.dataTodownload);
        }
        this.setState({ isDownloadOpen: false });
    }

    onCloseModel = () => {
        this.setState({ isDownloadOpen: false });
    }

    generateCSV(data) {
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: 'ErrorLogs'
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);
    }

    render() {

        return <>
            <div className="d-flex justify-content-between align-items-center mb-2 mob-colls">
                <div className="d-flex align-items-center mob-colls">
                    <h2 className="me-2"> Error Logs </h2>


                    <DateRangePicker
                        size="sm"
                        name="selectedDate"
                        placeholder="Select Error Logs Date Range"
                        onChange={this.OnDateChange}
                        // onChangeRaw={(e) => e.preventDefault()}
                        value={this.state.value}
                        showOneCalendar={true}
                        onOk={this.handleSubmitClick}
                        ranges={[]}
                        character=' - '
                        format='dd/MMM/yyyy'
                    />
                </div>

                <div className="d-flex align-items-center">
                    <button className={this.state.TotalRecords == 0 ? "disabled fill-button btn-small" : "fill-button btn-small"} disabled={this.state.TotalRecords == 0 ? true : false} onClick={this.downloadErrorLogs}> <FontAwesomeIcon className="me-2" icon={faDownload}></FontAwesomeIcon> <span> Download </span> </button>


                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={this.OnPageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={(this.state.TotalRecords > this.state.RecordPerPage) ? Math.ceil(this.state.TotalRecords / this.state.RecordPerPage) : 1}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        className="cust-pagination px-0"
                    />
                </div>
            </div>

            <div className="table-responsive bg-white">


                <table className="table table-hover error-log-table">
                    <thead>
                        <tr>
                            <th scope="col" >Logged Date</th>
                            <th scope="col">Error</th>
                            <th scope="col">Trace</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.errorLogs.map((item, i) => {
                            return [
                                <tr key={item.id}>
                                    <td style={{ whiteSpace: "pre-wrap" }}>{moment(moment.utc(item.loggedDate).toDate()).local().format('MMMM Do YYYY HH:mm A')}</td>
                                    <td style={{ whiteSpace: "pre-wrap" }}>{item.errorInformation}</td>
                                    <td style={{ whiteSpace: "pre-wrap" }}>{item.stackTrace}</td>
                                </tr>
                            ]
                        })}
                    </tbody>
                </table>
            </div>

            <DownloadFile onCloseModel={this.onCloseModel} isDownloadOpen={this.state.isDownloadOpen} onDownload={this.onDownload} />


        </>
    }
}

export default ErrorLogs;