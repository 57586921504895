import { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import API from "../../Api";
import { connect } from "react-redux";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/fontawesome-free-solid";
import { loginUserInfoAdmin } from "../../Redux/Actions/AdminAuthenticationActions";
import { adminCurrentPage } from "../../Redux/Actions/AdminCurrentPageAction";
import { saveEditBookingInfo } from "../../Redux/Actions/AdminEditBookingAction";

class EditPage5 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      products: [],
      selectedProducts: this.props.AdminEditBooking.bookingDetails.products,
      isProductSelected: true,
      isSubmitButtonDisabled: false
    };
  }

  async componentDidMount() {

    await new API().getMasterProducts(this.props.AdminEditBooking.bookingDetails.locationId).then(result => {
      this.setState({ products: result.data });
    });
  }

  PreviousButtonClick = () => {
    this.props.adminCurrentPage(4);
    this.props.sendData(4)
  }

  OnChangeHandler = (e) => {
    const selectedProductCount = this.props.AdminEditBooking.bookingDetails.products.length
    if (e.target.checked) {
      this.setState({ isProductSelected: true });

      let newProductToAdd = {
        id: 0,
        categoryId: e.target.id
      }
      let bookingDetails = this.props.AdminEditBooking.bookingDetails;
      let newProducts = bookingDetails.productsEdited;
      bookingDetails.productsEdited = newProducts.concat(newProductToAdd);
      this.props.saveEditBookingInfo({ bookingDetails: bookingDetails });
    }
    else {
      let bookingDetails = this.props.AdminEditBooking.bookingDetails;
      let newProducts = bookingDetails.productsEdited.filter(p => p.categoryId != e.target.id)
      bookingDetails.productsEdited = newProducts;
      this.props.saveEditBookingInfo({ bookingDetails: bookingDetails });

      if (selectedProductCount - 1 <= 0) {
        this.setState({ isProductSelected: false });
      }
    }
  }

  async saveData() {
    const products = [];
    this.props.AdminEditBooking.bookingDetails.productsEdited.map(p => {
      products.push({ CategoryId: p.categoryId });
    });

    const customers = [];
    this.props.AdminEditBooking.bookingDetails.customersEdited.map(p => {
      customers.push({ Id: p.id, Name: p.customerName, Role: p.customerRole, Email: p.customerEmail });
    });

    let bookingInfo = {
      BookingDetails: {
        id: this.props.AdminEditBooking.bookingDetails.id,
        location: this.props.AdminEditBooking.bookingDetails.locationId,
        VisitType: this.props.AdminEditBooking.bookingDetails.visitTypeId,
        bookieName: this.props.AdminEditBooking.bookingDetails.bookieName,
        bookieEmail: this.props.AdminEditBooking.bookingDetails.bookieEmail,
        customerName: this.props.AdminEditBooking.bookingDetails.customerName,
        visitingPartySize: this.props.AdminEditBooking.bookingDetails.sizeOfVisitingParty,
        participantsSize: this.props.AdminEditBooking.bookingDetails.hclParticipants,
        customerType: this.props.AdminEditBooking.bookingDetails.customerTypeId != "" ? this.props.AdminEditBooking.bookingDetails.customerTypeId : 0,
        bookingSlot: this.props.AdminEditBooking.bookingDetails.timeSlotId,
        bookingDate: moment(this.props.AdminEditBooking.bookingDetails.selectedDate),
        bookingEndDate: moment(this.props.AdminEditBooking.bookingDetails.selectedEndDate),
        opportunityId: this.props.AdminEditBooking.bookingDetails.opportunityId,
        opportunitySize: this.props.AdminEditBooking.bookingDetails.oppotunitySizeId != "" ? this.props.AdminEditBooking.bookingDetails.oppotunitySizeId : 0,
        lunchRequired: this.props.AdminEditBooking.bookingDetails.lunch,
        bookingtime: '',
        regionId: this.props.AdminEditBooking.bookingDetails.regionId,
        vertical: this.props.AdminEditBooking.bookingDetails.verticalId,
        subregionId: this.props.AdminEditBooking.bookingDetails.subregionId,
      },
      Customers: customers,
      Products: products,
      StartDate : this.props.AdminEditBooking.bookingDetails.selectedDateTimeZone,
      EndDate : this.props.AdminEditBooking.bookingDetails.selectedEndDateTimeZone
    };

    new API().updateBookingByAdmin(bookingInfo).then(result => {
      if (result.data.isError) {
        this.props.displayErrorMessage(result.data.errorMessage);
      }
      else {
        this.props.setBookingconfirmationId(result.data.id);
        this.props.adminCurrentPage(6);
        this.props.sendData(6)
      }
    });
  }

  renderTooltip = (title) => (
    <Tooltip placement='right'>{title}</Tooltip>
  );

  NextButtonClick = () => {
    if (this.props.AdminEditBooking.bookingDetails.productsEdited.length <= 0) {
      this.setState({ isProductSelected: false });
    }
    else {
      this.setState({ isSubmitButtonDisabled: true });
      this.saveData();
    }
    //this.saveData();
    //clear storage on save data in database
  }

  getTranslations(key) {

    if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
      return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
      return "";
  }


  render() {

    return <fieldset className="wizard-fieldset show">
      <div className="col-md-12 right-section">
        <h6 className="text-center pb-4 px-2"> {this.getTranslations("User_BookingProductsPage_Heading")}
          <OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingProductsPage_HeadingToottip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
        </h6>
        <div className="s-product-category">
          {this.state.products.map(product => this.state.selectedProducts.some(p => p.categoryId == product.id) ?

            <div key={product.id} className="form-check form-switch">
              <input disabled={this.state.isSubmitButtonDisabled} defaultChecked={true} onChange={this.OnChangeHandler} key={product.id} className={this.state.isProductSelected ? "form-check-input" : "form-check-input is-invalid"} type="checkbox" id={product.id} />
              <label className="form-check-label" htmlFor={product.id}> {product.productName} </label>
            </div> :
            <div key={product.id} className="form-check form-switch">
              <input disabled={this.state.isSubmitButtonDisabled} onChange={this.OnChangeHandler} key={product.id} className={this.state.isProductSelected ? "form-check-input" : "form-check-input is-invalid"} type="checkbox" id={product.id} />
              <label className="form-check-label" htmlFor={product.id}> {product.productName} </label>
            </div>
          )}


          <label className={this.state.isProductSelected ? "disable" : "errorMessage"}>
            Please select atleast one product category.
          </label>
        </div>
      </div>
      <div className="form-group clearfix row btn-fix-bottom">
        <div className="text-center main-btn-fix">
          <button disabled={this.state.isSubmitButtonDisabled} onClick={this.PreviousButtonClick} className="form-wizard-previous-btn float-left">Previous</button>
          <button disabled={this.state.isSubmitButtonDisabled} onClick={this.NextButtonClick} className={this.state.isSubmitButtonDisabled ? "form-wizard-next-btn float-right disabled" : "form-wizard-next-btn float-right"}> Save <i className="fa fa-angle-right"></i></button>
        </div>
      </div>
    </fieldset>

  }
}

const mapStateToProps = (state) => ({
  Translations: state.Translations,
  AdminAuthentication: state.AdminAuthentication,
  AdminEditBooking: state.AdminEditBooking,
  AdminCurrentPage: state.AdminCurrentPage,
  AdminInitialData: state.AdminInitialData
})

export default connect(mapStateToProps, { loginUserInfoAdmin, adminCurrentPage, saveEditBookingInfo })(EditPage5);
