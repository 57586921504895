import { Component } from "react";
import { Modal } from 'react-bootstrap'
import API from "../../Api";

class ConvertToDeal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: '',
            isCommentError: false,
            isButtonDisabled: false,
            bookingId: this.props.bookingId,
            products: [],
            productValues: [],
            ConvertToDealBool: false
        }
    }

    componentDidMount() {

        new API().getBookingProducts(this.state.bookingId).then(result => {
            this.setState({ products: result.data.products, comments: result.data.comments == null ? '' : result.data.comments, ConvertToDealBool: result.data.isConvertToDeal == null ? false : result.data.isConvertToDeal });
        });
    }

    oncommentschange = (e) => {
        this.setState({ comments: e.target.value })
    }

    getTranslations(key) {

        if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
            return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
            return "";
    }


    convertBooking = (e) => {

        let dataToPost = {
            comments: this.state.comments,
            isConvertToDeal: this.state.ConvertToDealBool,
            productSummaries: this.state.productValues,
            BookingID: this.state.bookingId
        }
        this.props.onConvertConfirm(dataToPost);
    }

    // handleSummaryChange = (e) => {

    //     let oldValues = this.state.productValues.filter(p => p.id != e.target.id);
    //     oldValues = oldValues.concat({
    //         id: e.target.id,
    //         summary: e.target.value
    //     });
    //     this.setState({ productValues: oldValues });

    //     let productsOld = this.state.products;
    //     let objIndex = productsOld.findIndex((obj => obj.id == e.target.id));
    //     productsOld[objIndex].summary = e.target.value;
    //     this.setState({ products: productsOld });

    // }

    OnRadioChange = (e) => {
        this.setState({ ConvertToDealBool: e.target.checked });
    }




    render() {
        return (
            <div className="modal show" style={{ display: 'block', position: 'initial' }}>
                <Modal className="modal-lg" show={this.props.isConvertOpen} onHide={this.props.onCloseModel} >
                    <Modal.Header className="modal-footer justify-content-center">
                        <h5 id="exampleModalLabel"> {this.getTranslations("Admin_ConvertDealPage_Heading")} </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-md-12 w-100">
                            <div className="mb-3">
                                {this.getTranslations("Admin_ConvertDealPage_BusinessSummary")}
                            </div>
                        </div>

                        <div className="feedback-wrapper">
                            <div className="mt-3 mb-2">
                                <textarea onChange={this.oncommentschange} value={this.state.comments} className={this.state.isCommentError ? "form-control cancelBox is-invalid" : "form-control cancelBox"} placeholder= {this.getTranslations("Admin_ConvertDealPage_Watermark")}
                                    id="exampleFormControlTextarea1" name="comments" rows="3" maxLength={450}></textarea>
                            </div>
                        </div>
                        {
                            this.state.isCommentError ?
                                <div style={{ color: 'red' }}>Please provide atleast 3 characters.</div> : ''
                        }

                        {/* <div className="col-md-12 w-100">
                            <div className="mb-1 mt-2">
                                {this.getTranslations("Admin_ConvertDealPage_Products")}
                            </div>
                        </div> */}
                        {/* <div className="modalscroll">


                            {
                                this.state.products.map((item, i) => {
                                    return [
                                        <div className="demo-products-cmt" key={item.id}>
                                            <label>{item.productName} </label>
                                            <textarea className="form-control" maxLength={450} value={item.summary} id={item.id} onChange={this.handleSummaryChange} />
                                        </div>
                                    ]
                                })
                            }

                        </div> */}

                        <div className="d-flex ">

                            <div className="form-check form-switch w-100">
                                <input className="form-check-input" type="checkbox" id="1" onChange={this.OnRadioChange}></input>
                                <label className="form-check-label" for="1"> {this.getTranslations("Admin_ConvertDealPage_ConvertToDeal")} </label>
                            </div>
                        </div>
                        {
                            this.state.ConvertToDealBool ? <div> {this.getTranslations("Admin_ConvertDealPage_ConvertToDealMessage")} </div> : ""
                        }


                        <div className="d-flex justify-content-center mt-4">
                            <button type="button" onClick={this.props.onCloseModel} className="btn outline-button btn-small" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" disabled={this.state.isButtonDisabled} onClick={(e) => this.convertBooking(e)} className="btn fill-button btn-small">Submit</button>
                        </div>
                    </Modal.Body>



                </Modal>

            </div>
        );
    }

}

export default ConvertToDeal;