export const BOOKING_INFO1 = "BOOKING_INFO1"
export const BOOKING_INFO2 = "BOOKING_INFO2"
export const CURRENTPAGE = "CURRENTPAGE"
export const BOOKING_PRODUCTS = "BOOKING_PRODUCTS"
export const REMOVE_PRODUCTS = "REMOVE_PRODUCTS"
export const CUSTOMERLUNCH = "CUSTOMERLUNCH"
export const CUSTOMERS_LIST = "CUSTOMERS_LIST"
export const CLEAR_CUSTOMERS_LIST = "CLEAR_CUSTOMERS_LIST"
export const REMOVE_ITEM_CUSTOMERS_LIST = "REMOVE_ITEM_CUSTOMERS_LIST"
export const EDIT_CUSTOMERS_LIST = "EDIT_CUSTOMERS_LIST"
export const CLEAR_BOOKING_PRODUCTS = "CLEAR_BOOKING_PRODUCTS"
export const AUTHENTICATION = "AUTHENTICATION"
export const ADMINAUTHENTICATION = "ADMINAUTHENTICATION"
export const ADMINLEFTMENU = "ADMINLEFTMENU"
export const TRANSLATIONS = "TRANSLATIONS"
export const ADMINEDITBOOKING = "ADMINEDITBOOKING"
export const ADMINEDITBOOKINGINITIALDATA = "ADMINEDITBOOKINGINITIALDATA"
export const ADMINCURRENTPAGE = "ADMINCURRENTPAGE"




