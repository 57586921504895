import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdminLogin from './Components/Admin/AdminLogin';
import Booking from './Components/User/Booking';
import './index.css';
import './Css/style-b.css'
import './Css/Style.css'
import './Css/slider.css'
import './Css/feedback.css'
import BookingsList from './Components/Admin/BookingsList';
import BookingDetails from './Components/Admin/BookingDetails';
import { Provider } from 'react-redux'
import { persistor,store } from './Redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import FeedbackDetails from './Components/Admin/FeedbackDetails';
import CustomerListFeedback from './Components/Admin/CustomerListFeedback';
import Login from './Components/User/Login';
import OTPValidation from './Components/User/OTPValidation';
import BookingsListUser from './Components/User/BookingListUser';
import AdminDashboard from './Components/AdminDashboard/AdminDashboard';
import BookingDetailsPrint from './Components/Admin/BookingDetailsPrint';
import FeedbackDetailCustomer from './Components/Admin/FeedbackDetailCustomer';
import EditBooking from './Components/AdminEditBooking/EditBooking';
import CustomerListFeedbackQR from './Components/QRFeedback/CustomerListFeedbackQR';
import FeedbackDetailsCustomerQr from './Components/QRFeedback/FeedbackDetailsCustomerQr';
import FeedbackDetailsQR from './Components/QRFeedback/FeedbackDetailsQR';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={ store }>        
    <PersistGate loading={null} persistor={persistor}></PersistGate>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/fluidbooking" element={<Booking />} />
            <Route path="/mybookings" element={<BookingsListUser />} />            
            <Route path="/dashboard" element={<AdminDashboard />} />
            <Route path="/dashboard/*" element={<AdminDashboard />} />                    
            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/bookingList" element={<BookingsList />} />
            <Route path="/editbooking/:id" element={<EditBooking />} />            
            <Route path="/bookingdetails/:id" element={<BookingDetails />} />
            <Route path="/printbookingdetails/:id" element={<BookingDetailsPrint />} />
            <Route path="/feedback/:id/:custId" element={<FeedbackDetailCustomer />} />
            <Route path="/feedbackCustomers/:id" element={<CustomerListFeedback />} />
            <Route path="/feedbackqr/:bid/:kid" element={<CustomerListFeedbackQR />} />
            <Route path="/feedbacksalesqr/:bid/:kid" element={<FeedbackDetailsQR />} />
            <Route path="/feedbackcustomerqr/:bid/:kid/:cid" element={<FeedbackDetailsCustomerQr />} />
            <Route path="/bookiefeedback/:id" element={<FeedbackDetails />} />
            <Route path="/OTPValidation" element={<OTPValidation />} />

        </Routes>      
      </BrowserRouter>
    </Provider>
  
);