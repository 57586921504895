import axios from "axios";

class API {

  constructor() {
    axios.defaults.baseURL = process.env.REACT_APP_BaseURL;
  }

  async GetQuaterlyDataOfVisitsByCustomerType(year) {
    return await axios.get(process.env.REACT_APP_AD_GetQuaterlyDataOfVisitsByCustomerType + "?year=" + year).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async GetNoOfCustomersByMonth() {
    return await axios.get(process.env.REACT_APP_AD_GetNoOfCustomersByMonth).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async GetCustomerFeedbackResults(year) {
    return await axios.get(process.env.REACT_APP_AD_GetCustomerFeedbackResults+"?year=" + year).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async GetMonthlyData(year , filterId) {
    return await axios.get(process.env.REACT_APP_AD_GetNoOfBookingsByMonth+"?year=" + year + "&filterId=" + filterId).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async GetQuarterlyData(year , filterId) {
    return await axios.get(process.env.REACT_APP_AD_GetNoOfBookingsByQuarter+"?year=" + year + "&filterId=" + filterId).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }
  
  async GetNoOfBookingsByOpportunitySize(year) {
    return await axios.get(process.env.REACT_APP_AD_GetNoOfBookingsByOpportunitySize + "?year="+ year).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async GetDashboardDataCount(year) {
    return await axios.get(process.env.REACT_APP_AD_GetDashboardDataCount + "?year="+ year).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async DownloadFeedbackGraphData(year) {
    return await axios.get(process.env.REACT_APP_AD_DownloadFeedbackGraphData + "?year="+ year).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async DownloadFeedbackGraphDataSales(year) {
    return await axios.get(process.env.REACT_APP_AD_DownloadFeedbackGraphDataSales + "?year="+ year).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

 
async getMasterUsers() {
  return await axios.get(process.env.REACT_APP_AD_MasterUsers).catch(function (error) {
    new API().logServiceErrorToDatabase(error)
  });
}

async addMasterUser(request) {
  return await axios.post(process.env.REACT_APP_AD_AddMasterUser, request).catch(function (error) {
    new API().logServiceErrorToDatabase(error)
  });
}

async updateMasterUserPassword(request) {
  return await axios.post(process.env.REACT_APP_AD_ChangePassword, request).catch(function (error) {
    new API().logServiceErrorToDatabase(error)
  });
}

async resetPassword(request) {
  return await axios.post(process.env.REACT_APP_AD_ResetPassword, request).catch(function (error) {
    new API().logServiceErrorToDatabase(error)
  });
}

  async DownloadGraphData(year) {
    return await axios.get(process.env.REACT_APP_AD_DownloadGraphData+"?year="+ year).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getBookingProducts(bookingId) {
    return await axios.get(process.env.REACT_APP_GetBookingProducts+"?bookingId="+ bookingId).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async DownloadGraphDataForBooking(bookingId) {
    return await axios.get(process.env.REACT_APP_AD_DownloadGraphDataForBooking+"?bookingId="+ bookingId).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getToken() {
    return await axios.get(process.env.REACT_APP_GetToken + "?user=" + "user" + "&password=" + "pass").catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async IsValidBookingSlot(date, slotId) {
    return await axios.get(process.env.REACT_APP_CheckValidBooking + '?date=' + date + "&slotId=" + slotId).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async CancelBookingWithComments(id, comments) {
    return await axios.get(process.env.REACT_APP_CancelBookingWithComments + '?bookingID=' + id + '&comments=' + comments).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async IsValidBookingSlotForConfirm(date, bookingId, slotId) {
    return await axios.get(process.env.REACT_APP_CheckValidBookingInUpdate + '?bookingId=' + bookingId + '&date=' + date + "&slotId=" + slotId).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async GetBookedSlots(locationId) {
    return await axios.get(process.env.REACT_APP_GetBookedSlots+ '?locationId=' + locationId).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async GetBookedSlotsForEdit(locationId,bookingId) {
    return await axios.get(process.env.REACT_APP_GetBookedSlotsForEdit+ '?locationId=' + locationId + "&bookingId=" + bookingId).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getBookingByFilter(bookingId) {
    return await axios.get(process.env.REACT_APP_GetBookingByFilter + '?bookingId=' + bookingId).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getFeedbackQuestions(feedbackRequest) {
    return await axios.post(process.env.REACT_APP_GetFeedbackQuestion, feedbackRequest).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async addBookingReminder(reminder) {
    return await axios.post(process.env.REACT_APP_AddBookingReminder, reminder).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async updateBookingReminder(reminder) {
    return await axios.post(process.env.REACT_APP_UpdateBookingReminder, reminder).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async updateBookingReminderAfterConfirm(reminder) {
    return await axios.post(process.env.REACT_APP_UpdateBookingReminderAfterConfirm, reminder).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async updateBookingReminderAfterCancel(reminder) {
    return await axios.post(process.env.REACT_APP_UpdateBookingReminderAfterCancel, reminder).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getBookingCustomers(bookingId) {
    return await axios.get(process.env.REACT_APP_GetBookingCustomers + '?bookingId=' + bookingId).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getBookingCustomersFromQR(bookingId,key) {
    return await axios.get(process.env.REACT_APP_GetBookingCustomersFromQR + '?bookingId=' + bookingId + "&key=" + key).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }


  async getMasterTranslations() {
    return await axios.get(process.env.REACT_APP_AD_GetMasterTranslations).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getMasterTranslationsByType(type) {
    return await axios.get(process.env.REACT_APP_AD_GetMasterTranslationsByType + '?type='+type).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getBookingFeedback(request) {
    return await axios.post(process.env.REACT_APP_AD_GetBookingFeedback ,request).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getBookingFeedbackQR(request) {
    return await axios.post(process.env.REACT_APP_AD_GetBookingFeedbackQR ,request).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getBookingFeedbackSalesQR(request) {
    return await axios.post(process.env.REACT_APP_AD_GetBookingFeedbackSalesQR ,request).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  

  async updateMasterTranslations(translation) {
    return await axios.post(process.env.REACT_APP_AD_UpdateMasterTranslation , translation).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async addBookingFeedback(request) {
    return await axios.post(process.env.REACT_APP_AddBookingFeedback , request).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async addBookingFeedbackQR(request) {
    return await axios.post(process.env.REACT_APP_AddBookingFeedbackQR , request).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async addBookingFeedbackSalesQR(request) {
    return await axios.post(process.env.REACT_APP_AddBookingFeedbackSalesQR , request).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  

  async getBookingDetailsForAdmin(bookingId) {
    return await axios.get(process.env.REACT_APP_AD_GetBookingDetailsForAdmin + '?bookingId=' + bookingId).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getQRCodeForbooking(bookingId) {
    return await axios.get(process.env.REACT_APP_AD_GetQRCodeForBooking + '?bookingId=' + bookingId).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }  

  async getSalesQRCodeForbooking(bookingId) {
    return await axios.get(process.env.REACT_APP_AD_GetQRCodeForBookingSales + '?bookingId=' + bookingId).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  } 

  async updateBookingByAdmin(bookingInfo) {
    return await axios.post(process.env.REACT_APP_AD_UpdateBooking , bookingInfo).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }


  async getMasterSubRegions(regionId) {
    return await axios.get(process.env.REACT_APP_GetMasterSubRegions + '?regionId=' + regionId).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getDeletedMasterSubRegions(regionId) {
    return await axios.get(process.env.REACT_APP_AD_GetDeletedMasterSubRegions + '?regionId=' + regionId).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }



  async addFeedback(feedbackRequest) {
    return await axios.post(process.env.REACT_APP_AddFeedback, feedbackRequest).catch(function (error) {
      console.log(error);
      new API().logServiceErrorToDatabase(error);
    });
  }

  async cancelBooking(bookingId) {
    return await axios.get(process.env.REACT_APP_CancelBooking + '?bookingId=' + bookingId).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async convertedBookingToDeal(dataToPost) {
    return await axios.post(process.env.REACT_APP_ConvertedBookingToDeal, dataToPost).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }



  async getFilterBookings(filterId, pageno) {
    return await axios.get(process.env.REACT_APP_GetFilterBookings + '?filterId=' + filterId + '&pageNo=' + pageno).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getFilterBookingsForUser(filterId, pageno, email) {
    return await axios.get(process.env.REACT_APP_GetFilteredBookingsForUser + '?filterId=' + filterId + '&pageNo=' + pageno + '&email=' + email).catch(function (error) {
      console.log(error);
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getDateFilteredBookings(filterId, pageno, startDateVal, endDateVal) {
    return await axios.get(process.env.REACT_APP_GetDateFilteredBookingsForAdmin + '?filterId=' + filterId + '&pageNo=' + pageno + '&startDate=' + startDateVal + '&endDate=' + endDateVal).catch(function (error) {
      console.log(error);
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getDateFilteredBookingsForUser(filterId, pageno, startDateVal, endDateVal, email) {
    return await axios.get(process.env.REACT_APP_GetDateFilteredBookingsForUser + '?filterId=' + filterId + '&pageNo=' + pageno + '&startDate=' + startDateVal + '&endDate=' + endDateVal + '&email=' + email).catch(function (error) {
      console.log(error);
      new API().logServiceErrorToDatabase(error)
    });
  }

  async addBookings(bookingInfo) {
    return await axios.post(process.env.REACT_APP_AddBooking, bookingInfo).catch(function (error) {
      console.log(error);
      new API().logServiceErrorToDatabase(error)
    });
  }

  async confirmAndUpdateBookings(bookingInfo) {
    return await axios.post(process.env.REACT_APP_ConfirmUpdateBooking, bookingInfo).catch(function (error) {
      console.log(error);
      new API().logServiceErrorToDatabase(error)
    });
  }

  async updateBookingDate(bookingInfo) {
    return await axios.post(process.env.REACT_APP_AD_UpdateBookingDate, bookingInfo).catch(function (error) {
      console.log(error);
      new API().logServiceErrorToDatabase(error)
    });
  }

  



  async getInitialDataForBookings() {
    return await axios.get(process.env.REACT_APP_GetInitialDataForBooking).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }
  async confirmBooking(bookingId) {
    return await axios.get(process.env.REACT_APP_ConfirmBooking + '?bookingId=' + bookingId).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }  

  async GetDeletedProductCategoriesForLocation(locationId) {
    return await axios.get(process.env.REACT_APP_AD_GetDeletedMasterProductCategories + '?locationId=' + locationId).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getBookingDetails(bookingId) {
    return await axios.get(process.env.REACT_APP_GetBookingDetails + '?bookingId=' + bookingId).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getMasterProducts(locationId) {
    return await axios.get(process.env.REACT_APP_GetMasterProductsForLocation + '?locationId=' + locationId).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async addUpdateMasterProduct(product) {
    return await axios.post(process.env.REACT_APP_AD_AddUpdateMasterProductCategories, product).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async addUpdateMasterSubRegion(subregion) {
    return await axios.post(process.env.REACT_APP_AD_AddUpdateMasterSubRegion, subregion).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async addUpdateMasterFeedbackQuestions(feedback) {
    return await axios.post(process.env.REACT_APP_AD_AddUpdateMasterFeedbackQuestions, feedback).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getMasterFeedbackQuestions(type) {
    return await axios.get(process.env.REACT_APP_AD_GetMasterFeedbackQuestions + '?type=' + type).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getDeletedMasterFeedbackQuestions(type) {
    return await axios.get(process.env.REACT_APP_AD_GetDeletedMasterFeedbackQuestions + '?type=' + type).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async addUpdateMasterRegions(region) {
    return await axios.post(process.env.REACT_APP_AD_AddUpdateMasterRegions, region).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }


  async getMasterRegions() {
    return await axios.get(process.env.REACT_APP_AD_GetMasterRegions).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getMasterVisitTypes() {
    return await axios.get(process.env.REACT_APP_AD_GetMasterVisitTypes).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getDeletedMasterVisitTypes() {
    return await axios.get(process.env.REACT_APP_AD_GetDeletedMasterVisitTypes).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async addUpdateMasterVisitTypes(visitType) {
    return await axios.post(process.env.REACT_APP_AD_AddUpdateMasterVisitTypes, visitType).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getDeletedMasterRegions() {
    return await axios.get(process.env.REACT_APP_AD_GetDeletedMasterRegions).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }


  async getMasterSlots() {
    return await axios.get(process.env.REACT_APP_AD_GetMasterSlots).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getDeletedMasterSlots() {
    return await axios.get(process.env.REACT_APP_AD_GetDeletedMasterSlots).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async addUpdateMasterSlots(slot) {
    return await axios.post(process.env.REACT_APP_AD_AddUpdateMasterSlots, slot).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async addUpdateMasterOpportunitySizes(opportunity) {
    return await axios.post(process.env.REACT_APP_AD_AddUpdateMasterOpprtunitySizes, opportunity).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getMasterOpportunitySizes() {
    return await axios.get(process.env.REACT_APP_AD_GetMasterOpprtunitySizes).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getDeletedMasterOpportunitySizes() {
    return await axios.get(process.env.REACT_APP_AD_GetDeletedMasterOpprtunitySizes).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }


  async addUpdateMasterCustomerTypes(customerType) {
    return await axios.post(process.env.REACT_APP_AD_AddUpdateMasterCustomerType, customerType).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getMasterCustomerType() {
    return await axios.get(process.env.REACT_APP_AD_GetMasterCustomerType).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getDeletedMasterCustomerType() {
    return await axios.get(process.env.REACT_APP_AD_GetDeletedMasterCustomerType).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async addUpdateMasterVertical(vertical) {
    return await axios.post(process.env.REACT_APP_AD_AddUpdateMasterVertical, vertical).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getMasterVerticals() {
    return await axios.get(process.env.REACT_APP_AD_GetMasterVerticals).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getDeletedMasterVerticals() {
    return await axios.get(process.env.REACT_APP_AD_GetDeletedMasterVerticals).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async UpdateMasterConfigurations(Configuration) {
    return await axios.post(process.env.REACT_APP_AD_UpdateMasterConfigurations, Configuration).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getMasterConfigurations() {
    return await axios.get(process.env.REACT_APP_AD_GetMasterConfigurations).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async addUpdateMasterLocation(location) {
    return await axios.post(process.env.REACT_APP_AD_AddUpdateMasterLocations, location).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getMasterLocations() {
    return await axios.get(process.env.REACT_APP_AD_GetMasterLocations).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getDeletedMasterLocations() {
    return await axios.get(process.env.REACT_APP_AD_GetDeletedMasterLocations).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }
  async deleteMasterLocation(request) {
    return await axios.post(process.env.REACT_APP_AD_DeleteMasterLocation, request).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async restoreMasterLocation(request) {
    return await axios.post(process.env.REACT_APP_AD_RestoreMasterLocation, request).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async deleteMasterVisistTypes(request) {
    return await axios.post(process.env.REACT_APP_AD_DeleteMasterVisitTypes, request).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async restoreMasterVisistTypes(request) {
    return await axios.post(process.env.REACT_APP_AD_RestoreMasterVisitTypes, request).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async deleteOpportunitySize(size) {
    return await axios.post(process.env.REACT_APP_AD_DeleteMasterOpportunity, size).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async restoreOpportunitySize(size) {
    return await axios.post(process.env.REACT_APP_AD_RestoreMasterOpportunity, size).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async deleteCustomerType(customer) {
    return await axios.post(process.env.REACT_APP_AD_DeleteMasterCustomerType, customer).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async restoreCustomerType(customer) {
    return await axios.post(process.env.REACT_APP_AD_RestoreMasterCustomerType, customer).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async deleteTimeSlot(slot) {
    return await axios.post(process.env.REACT_APP_AD_DeleteMasterSlot, slot).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async restoreTimeSlot(slot) {
    return await axios.post(process.env.REACT_APP_AD_RestoreMasterSlot, slot).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async deleteMasterRegion(region) {
    return await axios.post(process.env.REACT_APP_AD_DeleteMasterRegion, region).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async restoreMasterRegion(region) {
    return await axios.post(process.env.REACT_APP_AD_RestoreMasterRegion, region).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async deleteMasterSubRegion(subregion) {
    return await axios.post(process.env.REACT_APP_AD_DeleteMasterSubRegion, subregion).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async restoreMasterSubRegion(subregion) {
    return await axios.post(process.env.REACT_APP_AD_RestoreMasterSubRegion, subregion).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async deleteMasterVertical(vertical) {
    return await axios.post(process.env.REACT_APP_AD_DeleteMasterVertical, vertical).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async restoreMasterVertical(vertical) {
    return await axios.post(process.env.REACT_APP_AD_RestoreMasterVertical, vertical).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async deleteMasterFeedbackQuestionl(question) {
    return await axios.post(process.env.REACT_APP_AD_DeleteMasterFeedbackQuestion, question).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async restoreMasterFeedbackQuestion(question) {
    return await axios.post(process.env.REACT_APP_AD_RestoreMasterFeedbackQuestion, question).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async deleteMasterProductCategory(product) {
    return await axios.post(process.env.REACT_APP_AD_DeleteMasterProductCategory, product).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async restoreMasterProductCategory(product) {
    return await axios.post(process.env.REACT_APP_AD_RestoreMasterProductCategory, product).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async VerifyMasterUser(userInfo) {
    return await axios.post(process.env.REACT_APP_AD_VerifyMasterUser, userInfo).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }
  async getErrorLogs(pageNo) {
    return await axios.get(process.env.REACT_APP_GetErrorLogs + "?pageNo=" + pageNo).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getAuditLogs(pageNo) {
    return await axios.get(process.env.REACT_APP_AD_GetAuditLogs + "?pageNo=" + pageNo).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getAuditLogsFiltered(pageNo, startDate, endDate) {
    return await axios.get(process.env.REACT_APP_AD_GetAuditLogsFiltered + "?pageNo=" + pageNo + "&startDate=" + startDate + "&endDate=" + endDate).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async downloadAuditLogs() {
    return await axios.get(process.env.REACT_APP_AD_DownloadAuditLogs).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async downloadAuditLogsFiltered(startDate, endDate) {
    return await axios.get(process.env.REACT_APP_AD_DownloadAuditLogsFiltered + "?startDate=" + startDate + "&endDate=" + endDate).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async downloadErrorLogs() {
    return await axios.get(process.env.REACT_APP_AD_DownloadErrorLogs).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async downloadErrorLogsFiltered(startDate, endDate) {
    return await axios.get(process.env.REACT_APP_AD_DownloadErrorLogsFiltered + "?startDate=" + startDate + "&endDate=" + endDate).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }

  async getErrorLogsFiltered(pageNo, startDate, endDate) {
    return await axios.get(process.env.REACT_APP_GetFilteredErrorLogs + "?pageNo=" + pageNo + "&startDate=" + startDate + "&endDate=" + endDate).catch(function (error) {
      new API().logServiceErrorToDatabase(error)
    });
  }



  async insertErrorInDB(errorInfo) {
    return await axios.post(process.env.REACT_APP_AddErrorLogs, errorInfo);
  }

  logServiceErrorToDatabase(error) {
    let errorToLog = {
      ErrorInformation: error.message,
      StackTrace: String(JSON.stringify(error.response.data)),
    };

    this.insertErrorInDB(errorToLog);
  }

  async getAccessToken() {
    return await axios.get(process.env.REACT_APP_GetAccessToken)
  }


  async addLoginEntryOtp(loginRequest) {
    return await axios.post(process.env.REACT_APP_AddLoginEntryOtp, loginRequest).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

  async verifyOtp(otpInfo) {
    return await axios.post(process.env.REACT_APP_VerifyOtp, otpInfo).catch(function (error) {
      console.log(error)
      new API().logServiceErrorToDatabase(error)
    });
  }

}

export default API;
