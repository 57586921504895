export const AllowOnlyAlphabatsWithBrackets = (key) => {
    return !!((key.keyCode > 64 && key.keyCode < 91)  || 
         (key.keyCode > 96 && key.keyCode < 123) || 
         key.keyCode == 8 || 
         key.keyCode == 9 || 
         key.keyCode == 32 || 
         key.keyCode == 37 || 
         key.keyCode == 39 || 
         key.keyCode == 46 || 
         (key.shiftKey && (key.keyCode == 50 || 
         key.keyCode == 55 ||
         key.keyCode == 56 || 
         key.keyCode == 57 || 
         key.keyCode == 48)) || 
         key.keyCode == 189);
 }; 

 export const AllowAlphabatsNumbersWithBrackets = (key) => {
    
    return !!((key.keyCode > 64 && key.keyCode < 91)  || 
         (key.keyCode > 96 && key.keyCode < 123) || 
         (key.keyCode > 47 && key.keyCode < 58) ||
         key.keyCode == 8 || 
         key.keyCode == 9 || 
         key.keyCode == 32 || 
         key.keyCode == 37 || 
         key.keyCode == 39 || 
         key.keyCode == 46 || 
         (key.shiftKey && (key.keyCode == 50 || 
         key.keyCode == 55 ||
         key.keyCode == 56 || 
         key.keyCode == 57 || 
         key.keyCode == 48)) || 
         key.keyCode == 189);
 }; 

export const AllowOnlyNumbersWithHyphon = (key) => {
    return !!((!key.shiftKey && ((key.keyCode > 47 && key.keyCode < 58) || 
                             key.keyCode == 8 || 
                             key.keyCode == 9 || 
                             key.keyCode == 32 || 
                             key.keyCode == 37 || 
                             key.keyCode == 39 || 
                             key.keyCode == 189 || 
                             key.keyCode == 46 )) || ( key.shiftKey && (key.keyCode == 188 || key.keyCode == 190) ) );
}

export const AllowOnlyNumbers = (key) => {
    return !!(!key.shiftKey && ((key.keyCode > 47 && key.keyCode < 58) || 
                             key.keyCode == 8 ||
                             key.keyCode == 46 ));
}