import { Component } from "react";
import { connect } from "react-redux";
import checkImage from '../../Images/check.png'
import { loginUserInfoAdmin } from "../../Redux/Actions/AdminAuthenticationActions";
import { adminCurrentPage } from "../../Redux/Actions/AdminCurrentPageAction";
import { saveEditBookingInfo } from "../../Redux/Actions/AdminEditBookingAction";

class EditPage6 extends Component{
   
    constructor(props){
        super(props);  
        this.state={
            bookingId : this.props.bookingId
        };
        let bookingDetails= {
            id: '',
            locationId: '',
            visitTypeId: '',
            bookieName: '',
            bookieEmail: '',
            customerName: '',
            oppotunitySizeId: '',
            opportunityId: '',
            customerTypeId: '',
            regionId: '',
            subregionId: '',
            verticalId: '',
            sizeOfVisitingParty: '',
            hclParticipants: '',
            timeSlotId: '',
            selectedDate: '',
            selectedTime: '',
            selectedEndDate: '',
            lunch: false,
            products: [],
            customers: [],
            customersEdited: [],
            productsEdited: [],
        }
        this.props.saveEditBookingInfo({
            bookingDetails : bookingDetails 
        });
        this.props.adminCurrentPage(1);
        
    }      

    getTranslations(key) {

        if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
          return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
          return "";
      }
      
    render(){

        return  <fieldset className="wizard-fieldset show">
               
        <div className="col-md-12 right-section">          
            <div className="booking_confirm">
              <center>
               
                <div className="p-4 text-gray">
                    <img src={checkImage} className="check_img"/></div>
                <div className="successfully_msg"> Booking Id - <b> {this.state.bookingId} </b> {this.getTranslations("Admin_BookingSavePage_Message1")} </div>
                <div className="successfully_msg"> {this.getTranslations("Admin_BookingSavePage_Message2")} </div>
                <div className="mt-2"><a href="/bookinglist" > {this.getTranslations("Admin_BookingSavePage_Link")} </a></div>
                <br/>

              </center>
            </div>   
         
        </div>
      
      
       
      </fieldset>	

    }
}
const mapStateToProps = (state) => ({      
    Translations : state.Translations,
    AdminAuthentication: state.AdminAuthentication,
    AdminEditBooking: state.AdminEditBooking,
    AdminCurrentPage: state.AdminCurrentPage,
})

export default connect(mapStateToProps,{ loginUserInfoAdmin , adminCurrentPage , saveEditBookingInfo })(EditPage6);

