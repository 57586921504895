import { Component } from "react";
import { Modal } from 'react-bootstrap'
import API from "../../Api";
import QRCode from "react-qr-code";

class ViewQRCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookingId: this.props.bookingId,
            qrData: '',
            qrType: this.props.qrType
        }
    }

    componentDidMount() {
        if (this.state.qrType == 1) {
            new API().getQRCodeForbooking(this.state.bookingId).then(result => {
                this.setState({ qrData: result.data });

                let element = document.getElementsByClassName('modal-dialog');
                element[0].classList.add("modal-fullscreen");

            });
        }
        else {
            new API().getSalesQRCodeForbooking(this.state.bookingId).then(result => {
                this.setState({ qrData: result.data });

                let element = document.getElementsByClassName('modal-dialog');
                element[0].classList.add("modal-fullscreen");

            });
        }
    }


    getTranslations(key) {

        if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
            return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
            return "";
    }

    downloadQR = (e) => {
        window.print();
        setTimeout(function () { window.close(); }, 100);
    }



    render() {
        return (
            <div className="modal show" >
                <Modal show={this.props.isQRCodeOpen} onHide={this.props.onCloseModel} >
                    <Modal.Header className="modal-footer justify-content-center">
                        <h5 id="exampleModalLabel">
                            {this.getTranslations("Admin_QRCode_Heading")}  ({ this.state.qrType == 1 ? this.getTranslations("Admin_QRCode_NoteForCustomer") : this.getTranslations("Admin_QRCode_NoteForSales")})
                        </h5>
                    </Modal.Header>
                    <Modal.Body>

                        <div> <span className="date-bold"> {this.getTranslations("Admin_QRCode_Booking")} : {this.props.displayId}</span>

                        </div>

                        <div id="barcode" className="mt-4" style={{ height: "auto", margin: "0 auto", maxWidth: 400, width: "100%" }}>
                            <QRCode
                                size={256}
                                className="QRCode"
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={this.state.qrData}
                                viewBox={`0 0 256 256`}
                            />
                        </div>



                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-center mt-4">
                            <button type="button" onClick={this.props.onCloseModel} className="btn outline-button btn-small" data-bs-dismiss="modal"> Close </button>
                            <button type="button" onClick={this.downloadQR} className="btn fill-button btn-small" data-bs-dismiss="modal"> Download </button>
                        </div>
                    </Modal.Footer>



                </Modal>

            </div>
        );
    }

}

export default ViewQRCode;