import { faEdit } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Component } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { connect } from "react-redux";
import API from "../../../Api";
import { loginUserInfoAdmin } from '../../../Redux/Actions/AdminAuthenticationActions'
import UpdateTranslation from "../ConfigurationsPopups/UpdateTranslation";

class MasterTranslations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedType: 0,
            masterTranslations: [],
            selectedTranslation: {},
            errorMessage: '',
            isPopupOpen: false,
            isEditClick: false,
            showToast: false,
            toastMessage: '',
            toastHeader: '',
            isUserSelected: true
        };
    }

    onEditClick = (e) => {
        let id = e.target.id;
        if (e.target.nodeName == "path") {
            id = e.target.parentNode.id;
        }
        this.setState({ isEditClick: true, isPopupOpen: true, errorMessage: '', selectedTranslation: this.state.masterTranslations.filter(p => p.id == id)[0] });
    }

    onCloseModel = (e) => {
        this.setState({ isEditClick: false, isPopupOpen: false });
    }


    onConfirm = (e) => {
        let toastMessage = '';
        let translationToUpdate = {};
        if (this.state.selectedTranslation != null) {
            toastMessage = 'Translation updated successfully.';
            translationToUpdate = {
                id: this.state.selectedTranslation.id,
                value: e,
                ModifiedBy: this.props.AdminAuthentication.Username,
            };

        }

        if(this.state.selectedTranslation.appValue != e.trim())
        {
            new API().updateMasterTranslations(translationToUpdate).then(result => {
                if (result.data) {
                    this.setState({ showToast: true, isDeletedToast: false, toastHeader: 'Success', toastMessage: toastMessage, isPopupOpen: false, isEditClick: false });
    
                    new API().getMasterTranslationsByType(this.state.selectedType).then(result => {
                        this.setState({ masterTranslations: result.data });
                    });
                }
                else {
                    if (result.errorMessage != "") {
                        this.setState({ errorMessage: result.data.errorMessage });
                    }
                }
            });
        }
        else
        {
            this.setState({ isDeletedToast: false, isPopupOpen: false, isEditClick: false });
    
        }

        

    }

    OnCloseToast = (e) => {
        this.setState({ showToast: !this.state.showToast });
    }

    OnDropDownChange = (e) => {
        this.setState({ selectedType: e.target.value })

        if (e.target.value != "") {
            new API().getMasterTranslationsByType(e.target.value).then(result => {
                this.setState({ masterTranslations: result.data });
            });
        }
        else {
            this.setState({ masterTranslations: [] });
        }
    }

    OnRadioChanged = (e) => {
        this.setState({
            selectedType: "",
            isUserSelected: e.target.value == "0" ? true : false,
            masterTranslations: []
        })
    }

    render() {

        return <>
            <ToastContainer position="bottom-end">

                <Toast delay={10000} autohide show={this.state.showToast} onClose={this.OnCloseToast}>
                    <Toast.Header>
                        <strong className="me-auto"> {this.state.toastHeader}</strong>
                    </Toast.Header>
                    <Toast.Body className={this.state.isDeletedToast ? "bg-danger text-white" : "bg-success text-white"}> {this.state.toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>



            <div className="d-flex justify-content-between align-items-center mob-colls">
                <h2> Master Translations </h2>
                <div className="d-flex">
                    <div className="form-check form-check-cust">
                        <input id="user" className="form-check-input" type="radio" checked={this.state.isUserSelected} value="0" onChange={this.OnRadioChanged} name="radio1" /> <label htmlFor="user" className="form-check-label"> User</label>
                    </div>
                    <div className="form-check form-check-cust">
                        <input type="radio" className="form-check-input" id="admin" checked={!this.state.isUserSelected} value="1" onChange={this.OnRadioChanged} name="radio1" /> <label htmlFor="admin" className="form-check-label"> Admin</label>
                    </div>
                </div>


            </div>

            <div className="table-responsive bg-white">
                <div className="mb-2">
                    <label>Select Page</label>
                    <select value={this.state.selectedType} className="form-control form-select" onChange={this.OnDropDownChange}>
                        {
                            this.state.isUserSelected ? <>
                                <option id={0} defaultChecked="" value=""> Click to Select Page</option>
                                <option id={1} value="1"> BookingPage</option>
                                <option id={2} value="2"> BookingNextPage</option>
                                <option id={3} value="3"> VisitingDelegationPage</option>
                                <option id={19} value="19"> EditVisitingDelegationPage</option>
                                <option id={4} value="4"> BookingLunchPage</option>
                                {/* <option id={5} value="5"> BookingProductsPage</option> */}
                                <option id={6} value="6"> BookingSubmittedPage</option>
                                <option id={18} value="18"> BookingListPage</option>
                            </>
                                :
                                <>
                                    <option id={0} defaultChecked="" value=""> Click to Select Page</option>
                                    <option id={7} value="7"> Dashboard</option>
                                    <option id={8} value="8"> BookingListPage</option>
                                    <option id={16} value="16"> BookingDetailsPage</option>
                                    <option id={9} value="9"> CancelPage</option>
                                    <option id={10} value="10"> ConfirmPage</option>
                                    <option id={11} value="11"> ConvertDealPage</option>
                                    <option id={12} value="12"> SaleFeedbackPage</option>
                                    <option id={13} value="13"> CustomerFeedbackPage</option>
                                    <option id={14} value="14"> CustomerListPage</option>
                                    <option id={15} value="15"> GuestPage</option>
                                    <option id={17} value="17"> BookingSavePage</option>
                                    <option id={20} value="20"> QRCodePage</option>
                                </>
                        }


                    </select> </div>

                {
                    this.state.masterTranslations.length == 0 ?

                        <div className="nobookingtext text-center"> Master Translations data not found </div> : ""
                }
                {

                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Key</th>
                                <th scope="col">Value</th>
                                <th scope="col">Created By</th>
                                <th scope="col">Created Date</th>
                                <th scope="col">Updated By</th>
                                <th scope="col">Updated Date</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.masterTranslations.map((item, i) => {
                                return [
                                    <tr key={item.id}>
                                        <td style={{ whiteSpace: "pre-wrap" }}>{item.appKey}</td>
                                        <td style={{ whiteSpace: "pre-wrap" }}>{item.appValue}</td>
                                        <td style={{ whiteSpace: "pre-wrap" }}>{item.createdBy}</td>
                                        <td style={{ whiteSpace: "pre-wrap" }}>{moment(moment.utc(item.createdDate).toDate()).local().format('MMMM Do YYYY, HH:mm A')}</td>
                                        <td style={{ whiteSpace: "pre-wrap" }}>{item.modifiedBy}</td>
                                        <td style={{ whiteSpace: "pre-wrap" }}>{item.modifiedDate != null ? moment(moment.utc(item.modifiedDate).toDate()).local().format('MMMM Do YYYY, HH:mm A') : ""}</td>
                                        <td> <button className="edit" onClick={this.onEditClick} id={item.id}>
                                            <FontAwesomeIcon onClick={this.onEditClick} id={item.id} icon={faEdit} /> </button>
                                        </td>
                                    </tr>
                                ]
                            })}
                        </tbody>
                    </table>
                }
            </div>

            {
                this.state.isPopupOpen ?
                    <UpdateTranslation errorMessage={this.state.errorMessage} selectedTranslation={this.state.selectedTranslation} isPopupOpen={this.state.isPopupOpen} onCloseModel={this.onCloseModel} onConfirm={this.onConfirm} isEditClick={this.state.isEditClick} />
                    : ""
            }

        </>


    }
}


const mapStateToProps = (state) => ({
    AdminAuthentication: state.AdminAuthentication,
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(MasterTranslations);