import {  BOOKING_PRODUCTS,REMOVE_PRODUCTS,CLEAR_BOOKING_PRODUCTS } from './Type'


export function bookingProducts(products){
return {
            type : BOOKING_PRODUCTS,
            products : products
       }
}

export function removeBookingProducts(products){
    return {
                type : REMOVE_PRODUCTS,
                products : products
           }
    }

    export function clearBookingProducts(products){
     return {
                 type : CLEAR_BOOKING_PRODUCTS,
                 products : products
            }
     }