import { faEdit, faPlus, faTrashAlt, faUndo } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Component } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { connect } from "react-redux";
import API from "../../../Api";
import DeleteConsent from "../DeleteConsent";
import { loginUserInfoAdmin } from '../../../Redux/Actions/AdminAuthenticationActions'
import AddProductCategory from "../ConfigurationsPopups/AddProductCategory";
import RestoreConsent from "../RestoreConsent";
import WarningConsent from "../WarningConsent";

class MasterProductCategories extends Component {

  constructor(props) {
    super(props);
    this.state = {
      masterLocations: [],
      selectedLocation: 0,
      masterProductCategories: [],
      isConsentOpen: false,
      selectedProductCategory: {},
      errorMessage: '',
      isPopupOpen: false,
      isEditClick: false,
      showToast: false,
      toastMessage: '',
      toastHeader: '',
      isDeletedToast: false,
      showDeletedTable: 0,
      isRestoreOpen: false,
      isWarningOpen: false
    };
  }

  async componentDidMount() {
    new API().getMasterLocations().then(result => {
      this.setState({ masterLocations: result.data, isWarningOpen: false, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: false })
    })
  }

  onDeleteClick = (e) => {
    let id = e.target.id;
    if (e.target.nodeName == "path") {
      id = e.target.parentNode.id;
    }
    this.setState({ isConsentOpen: true, isWarningOpen: false, isRestoreOpen: false, isPopupOpen: false, errorMessage: '', selectedProductCategory: this.state.masterProductCategories.filter(p => p.id == id)[0] });
  }

  onEditClick = (e) => {
    let id = e.target.id;
    if (e.target.nodeName == "path") {
      id = e.target.parentNode.id;
    }
    this.setState({ isEditClick: true, isWarningOpen: false, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: true, errorMessage: '', selectedProductCategory: this.state.masterProductCategories.filter(p => p.id == id)[0] });
  }

  onAddClick = (e) => {
    if (this.state.selectedLocation != 0) {
      this.setState({ isEditClick: false, isWarningOpen: false, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: true, errorMessage: '', selectedProductCategory: null });
    }
    else {
      this.setState({ isWarningOpen: true });
    }
  }

  onCloseModel = (e) => {
    this.setState({ isEditClick: false, isWarningOpen: false, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: false });
  }


  onConfirm = (e) => {
    let toastMessage = '';
    let productToAdd = {};
    if (this.state.selectedProductCategory == null) {
      toastMessage = 'Product category added successfully.';
      productToAdd = {
        ProductName: e,
        LocationId: this.state.selectedLocation,
        createdBy: this.props.AdminAuthentication.Username,
        isDisplay: true

      };
    }
    else {

      toastMessage = 'Product category updated successfully.';
      productToAdd = {
        id: this.state.selectedProductCategory.id,
        ProductName: e,
        LocationId: this.state.selectedLocation,
        ModifiedBy: this.props.AdminAuthentication.Username,
      };
    }

    if(this.state.selectedProductCategory==null || (this.state.selectedProductCategory !=null && this.state.selectedProductCategory.productName != e.trim()))
    {
      new API().addUpdateMasterProduct(productToAdd).then(result => {
        if (result.data.isSuccess) {
          this.setState({ showDeletedTable: 0, isWarningOpen: false, showToast: true, isRestoreOpen: false, isDeletedToast: false, toastHeader: 'Success', toastMessage: toastMessage, isPopupOpen: false, isEditClick: false });
  
          new API().getMasterProducts(this.state.selectedLocation).then(result => {
            this.setState({ masterProductCategories: result.data });
          });
        }
        else {
          if (result.errorMessage != "") {
            this.setState({ errorMessage: result.data.errorMessage });
          }
        }
      });
    }
    else
    {
      this.setState({ showDeletedTable: 0, isWarningOpen: false,  isRestoreOpen: false, isDeletedToast: false, isPopupOpen: false, isEditClick: false });

    }

    

  }

  onDeleteConfirm = (e) => {

    let deleteRequest = {
      id: this.state.selectedProductCategory.id,
      DeletedBy: this.props.AdminAuthentication.Username
    }


    new API().deleteMasterProductCategory(deleteRequest).then(result => {

      if (result.data.isSuccess) {
        this.setState({ showToast: true, isRestoreOpen: false, isDeletedToast: true, toastHeader: 'Deleted', toastMessage: 'Product category deleted successfully.', isPopupOpen: false, isEditClick: false });

        new API().getMasterProducts(this.state.selectedLocation).then(result1 => {
          this.setState({ masterProductCategories: result1.data, isWarningOpen: false, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: false });
        });
      }
      else {
        if (result.errorMessage != "") {
          this.setState({ errorMessage: result.data.errorMessage });
        }

      }
    })

  }

  OnCloseToast = (e) => {
    this.setState({ showToast: !this.state.showToast });
  }

  OnRegionChange = (e) => {
    this.setState({ selectedLocation: e.target.value })

    if (e.target.value != "") {
      if (this.state.showDeletedTable == "0") {
        new API().getMasterProducts(e.target.value).then(result => {
          this.setState({ masterProductCategories: result.data });
        });
      }
      else {
        new API().GetDeletedProductCategoriesForLocation(e.target.value).then(result => {
          this.setState({ masterProductCategories: result.data });
        });
      }
    }
    else {
      this.setState({ masterProductCategories: [] });
    }

  }

  onRestoreConfirm = (e) => {

    let restoreRequest = {
      id: this.state.selectedProductCategory.id,
      ModifiedBy: this.props.AdminAuthentication.Username
    }

    new API().restoreMasterProductCategory(restoreRequest).then(result => {
      if (result.data.isSuccess) {
        this.setState({ showToast: true, isWarningOpen: false, isDeletedToast: false, toastHeader: 'Restored', toastMessage: 'Product category restored successfully.', isPopupOpen: false, isEditClick: false });

        if (this.state.showDeletedTable == "0") {
          new API().getMasterProducts(this.state.selectedLocation).then(result1 => {
            this.setState({ masterProductCategories: result1.data, isWarningOpen: false, isPopupOpen: false, isConsentOpen: false });
          });
        }
        else {
          new API().GetDeletedProductCategoriesForLocation(this.state.selectedLocation).then(result => {
            this.setState({ masterProductCategories: result.data, isWarningOpen: false, isConsentOpen: false, isPopupOpen: false, isRestoreOpen: false })
          })
        }
      }
      else {
        if (result.errorMessage != "") {
          this.setState({ errorMessage: result.data.errorMessage });
        }

      }
    })

  }

  OnRadioChanged = (e) => {
    this.setState({ showDeletedTable: e.target.value });
    if (e.target.value == 0) {
      new API().getMasterProducts(this.state.selectedLocation).then(result1 => {
        this.setState({ masterProductCategories: result1.data, isWarningOpen: false, isPopupOpen: false, isConsentOpen: false });
      });
    }
    else {
      new API().GetDeletedProductCategoriesForLocation(this.state.selectedLocation).then(result => {
        this.setState({ masterProductCategories: result.data, isWarningOpen: false, isConsentOpen: false, isPopupOpen: false })
      })
    }

  }

  onRestoreClick = (e) => {
    let id = e.target.id;
    if (e.target.nodeName == "path") {
      id = e.target.parentNode.id;
    }
    this.setState({ isConsentOpen: false, isRestoreOpen: true, isWarningOpen: false, isPopupOpen: false, errorMessage: '', selectedProductCategory: this.state.masterProductCategories.filter(p => p.id == id)[0] });
  }

  render() {

    return <>

      <ToastContainer position="bottom-end">

        <Toast delay={10000} autohide show={this.state.showToast} onClose={this.OnCloseToast}>
          <Toast.Header>
            <strong className="me-auto"> {this.state.toastHeader}</strong>
          </Toast.Header>
          <Toast.Body className={this.state.isDeletedToast ? "bg-danger text-white" : "bg-success text-white"}> {this.state.toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <div className="d-flex justify-content-between align-items-center mob-colls">
        <h2> Master Product Categories </h2>
        <div className="d-flex">
          <div className="form-check form-check-cust">
            <input type="radio" className="form-check-input" id="active" checked={this.state.showDeletedTable == "0" ? true : false} value="0" onChange={this.OnRadioChanged} name="radio1" /> <label htmlFor="active" className="form-check-label"> Active</label>
          </div>
          <div className="form-check form-check-cust">
            <input type="radio" className="form-check-input" id="delete" checked={this.state.showDeletedTable == "1" ? true : false} value="1" onChange={this.OnRadioChanged} name="radio1" /> <label htmlFor="delete" className="form-check-label"> Archive</label>
          </div>
          <button onClick={this.onAddClick} className="add-new me-2"><FontAwesomeIcon icon={faPlus} />  </button>
        </div> </div>

      <div className="table-responsive bg-white">
        <div className="mb-2">
          <label>Select Location</label>
          <select className="form-control form-select" onChange={this.OnRegionChange}>
            <option id={0} defaultChecked="" value=""> Click to Select</option>

            {this.state.masterLocations.map(reg =>
              (<option value={reg.id} key={reg.id} id={reg.id} > {reg.locationName} </option>)
            )}

          </select> </div>

        {
          this.state.masterProductCategories.length == 0 ?

            <div className="nobookingtext text-center"> {this.state.showDeletedTable == "0" ? "Product categories data not found" : "Product categories not found for restore"} </div> : ""
        }
        {

          this.state.showDeletedTable == "0" ?
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Product Category</th>
                  <th scope="col">Created By</th>
                  <th scope="col">Created Date</th>
                  <th scope="col">Updated By</th>
                  <th scope="col">Updated Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.masterProductCategories.map((item, i) => {
                  return [
                    <tr key={item.id}>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.productName}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.createdBy}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{moment(moment.utc(item.createdDate).toDate()).local().format('MMMM Do YYYY, HH:mm A')}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.modifiedBy}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.modifiedDate != null ? moment(moment.utc(item.modifiedDate).toDate()).local().format('MMMM Do YYYY, HH:mm A') : ""}</td>
                      <td> <button className="edit" onClick={this.onEditClick} id={item.id}>
                        <FontAwesomeIcon onClick={this.onEditClick} id={item.id} icon={faEdit} /> </button>
                        <button className="delete" onClick={this.onDeleteClick} id={item.id}>
                          <FontAwesomeIcon onClick={this.onDeleteClick} id={item.id} icon={faTrashAlt} /> </button> </td>
                    </tr>
                  ]
                })}
              </tbody>
            </table> :
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Product Category</th>
                  <th scope="col">Deleted By</th>
                  <th scope="col">Deleted Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.masterProductCategories.map((item, i) => {
                  return [
                    <tr key={item.id}>
                      <td>{item.productName}</td>
                      <td>{item.deletedBy}</td>
                      <td>{moment(moment.utc(item.deletedDate).toDate()).local().format('MMMM Do YYYY, HH:mm A')}</td>
                      <td>  <button className="delete" onClick={this.onRestoreClick} id={item.id}>
                        <FontAwesomeIcon onClick={this.onRestoreClick} id={item.id} icon={faUndo} /> </button> </td>
                    </tr>
                  ]
                })}
              </tbody>
            </table>
        }
      </div>

      {
        this.state.isConsentOpen ?
          <DeleteConsent isConsentOpen={this.state.isConsentOpen} onCloseModel={this.onCloseModel} onDelete={this.onDeleteConfirm} errorMessage={this.state.errorMessage} type={"Location"} />
          : ""
      }


      {
        this.state.isPopupOpen ?
          <AddProductCategory errorMessage={this.state.errorMessage} selectedProductCategory={this.state.selectedProductCategory} isPopupOpen={this.state.isPopupOpen} onCloseModel={this.onCloseModel} onConfirm={this.onConfirm} isEditClick={this.state.isEditClick} type={"Location"} />
          : ""
      }

      {
        this.state.isRestoreOpen ?
          <RestoreConsent isRestoreOpen={this.state.isRestoreOpen} onCloseModel={this.onCloseModel} onRestore={this.onRestoreConfirm} errorMessage={this.state.errorMessage} type={"Location"} />
          : ""
      }

      {
        this.state.isWarningOpen ?
          <WarningConsent infoMessage="Please select location to add product categories" isWarningOpen={this.state.isWarningOpen} onCloseModel={this.onCloseModel} />
          : ""
      }
    </>


  }
}


const mapStateToProps = (state) => ({
  AdminAuthentication: state.AdminAuthentication,
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(MasterProductCategories);