import { Component } from "react";
import ReactFormInputValidation from "react-form-input-validation";
import { editCustomersList } from '../../Redux/Actions/CustomersListAction'
import { connect } from "react-redux";
import { addtranslation } from "../../Redux/Actions/TranslationAction";
import { loginUserInfoAdmin } from "../../Redux/Actions/AdminAuthenticationActions";
import { adminCurrentPage } from "../../Redux/Actions/AdminCurrentPageAction";
import { saveEditBookingInfo } from "../../Redux/Actions/AdminEditBookingAction";

class AddCustomerInfoInEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            custnum: this.props.serialNo,
            fields: {
                id : this.props.currentState.id > 0 ? this.props.currentState.id : 0,
                name: this.props.currentState.customerName,
                role: this.props.currentState.customerRole,
                email: this.props.currentState.customerEmail
            },
            errors: {},
        }
        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
            name: "required",
            role: "required",
            email: "required|email"
        });

        this.form.onformsubmit = (fields) => {

            if (Object.keys(this.state.errors).length === 0) {
                let customer = {
                    id: this.state.fields.id,
                    customerName: this.state.fields.name,
                    customerEmail: this.state.fields.email,
                    customerRole: this.state.fields.role,
                    index: this.state.custnum,
                    isValidated: true
                };

                 this.props.handleNext(this.props.serialNo);

                let bookingDetails = this.props.AdminEditBooking.bookingDetails;

                let customersList = bookingDetails.customersEdited.filter((obj => obj.index != this.state.custnum));
                customersList.splice( this.state.custnum,0, customer,);
                bookingDetails.customersEdited = customersList;
                
                this.props.saveEditBookingInfo({ bookingDetails: bookingDetails });
            }
        }

        let message = ReactFormInputValidation.getMessages('en');
        message.required = 'This field is required.'
        ReactFormInputValidation.setMessages('en', message);
    }

    exceptThisSymbols(key) {
        return !!((key.keyCode > 64 && key.keyCode < 91) || (key.keyCode > 96 && key.keyCode < 123) || key.keyCode == 8 || key.keyCode == 32 || key.keyCode == 9 || key.keyCode == 37 || key.keyCode == 39);
    }

    getTranslations(key) {

        if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
            return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
            return "";
    }


    handlePrevious = (e) => {
        e.preventDefault();
        this.props.handlePrev(this.props.serialNo)
    }
    render() {



        return (<form onSubmit={this.form.handleSubmit} className="w-100">
            <fieldset>
                <h6 className="text-center mb-0 pb-2 bg-white"> {this.getTranslations("User_VisitingDelegationPage_Heading")} ({this.props.serialNo + 1} out of {this.props.customersNumber})</h6>
                <div className="slider-content__item image-1">
                    <div className="col-md-12 w-100">
                        <div className="mb-2">
                            <label className="form-label">{this.getTranslations("User_VisitingDelegationPage_Name")}</label>
                            <input style={{ textTransform: 'capitalize' }} value={this.state.fields.name} name="name" onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} className={this.state.errors.name ? "form-control is-invalid" : "form-control"} aria-describedby="emailHelp" onKeyDown={e => !this.exceptThisSymbols(e) && e.preventDefault()} maxLength={40} />
                            <label className="invalid-feedback">
                                {this.state.errors.name ? this.state.errors.name : ""}
                            </label>
                        </div>
                        <div className="mb-2">
                            <label className="form-label">{this.getTranslations("User_VisitingDelegationPage_Role")}</label>
                            <input value={this.state.fields.role} name="role" onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} className={this.state.errors.role ? "form-control is-invalid" : "form-control"} aria-describedby="emailHelp" maxLength={25} />
                            <label className="invalid-feedback">
                                {this.state.errors.role ? this.state.errors.role : ""}
                            </label>
                        </div>
                        <div className="mb-2">
                            <label className="form-label">{this.getTranslations("User_VisitingDelegationPage_Email")}</label>
                            <input value={this.state.fields.email} name="email" onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="text" className={this.state.errors.email ? "form-control is-invalid" : "form-control"} aria-describedby="emailHelp" />
                            <label className="invalid-feedback">
                                {this.state.errors.email ? this.state.errors.email : ""}
                            </label>
                        </div>
                        <br />
                        {this.props.serialNo < this.props.customersNumber && <button className="carousel-control-next crnext" type="submit" >Continue</button>}
                        {this.props.serialNo > 0 && <button onClick={(e) => this.handlePrevious(e)} className="carousel-control-prev crprev mx-2">Back</button>}

                    </div>
                </div>
            </fieldset>
        </form>
        )

    }
}

const mapStateToProps = (state) => ({
    CustomersList: state.CustomersList,
    Translations: state.Translations,
    AdminAuthentication: state.AdminAuthentication,
    AdminEditBooking: state.AdminEditBooking,
    AdminCurrentPage: state.AdminCurrentPage2,
    AdminInitialData: state.AdminInitialData
})

export default connect(mapStateToProps, { editCustomersList, addtranslation, loginUserInfoAdmin, adminCurrentPage, saveEditBookingInfo })(AddCustomerInfoInEdit);
