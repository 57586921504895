import { faEdit, faPlus, faTrashAlt, faUndo } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import API from "../../../Api";
import AddCustomerType from "../ConfigurationsPopups/AddCustomerType";
import DeleteConsent from "../DeleteConsent";
import { loginUserInfoAdmin } from '../../../Redux/Actions/AdminAuthenticationActions'
import moment from "moment";
import { connect } from "react-redux";
import RestoreConsent from "../RestoreConsent";

class MasterCustomerTypes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      customerTypes: [],
      isConsentOpen: false,
      selectedCustomerType: {},
      errorMessage: '',
      isPopupOpen: false,
      isEditClick: false,
      showToast: false,
      toastMessage: '',
      toastHeader: '',
      isDeletedToast: false,
      showDeletedTable: 0,
      isRestoreOpen: false,
      totalActiveRecords: 0
    };
  }

  async componentDidMount() {
    new API().getMasterCustomerType().then(result => {
      this.setState({ totalActiveRecords: result.data.totalActiveRecords, customerTypes: result.data.masterTypes, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: false })
    })
  }

  onDeleteClick = (e) => {
    let id = e.target.id;
    if (e.target.nodeName == "path") {
      id = e.target.parentNode.id;
    }
    this.setState({ isConsentOpen: true, isRestoreOpen: false, isPopupOpen: false, errorMessage: '', selectedCustomerType: this.state.customerTypes.filter(p => p.id == id)[0] });
  }

  onEditClick = (e) => {
    let id = e.target.id;
    if (e.target.nodeName == "path") {
      id = e.target.parentNode.id;
    }
    this.setState({ isEditClick: true, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: true, errorMessage: '', selectedCustomerType: this.state.customerTypes.filter(p => p.id == id)[0] });
  }

  onAddClick = (e) => {
    this.setState({ isEditClick: false, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: true, errorMessage: '', selectedCustomerType: null });
  }

  onCloseModel = (e) => {
    this.setState({ isEditClick: false, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: false });
  }

  onConfirm = (e) => {
    let toastMessage = '';
    let customerToAdd = {};
    if (this.state.selectedCustomerType == null) {
      toastMessage = 'Customer Type added successfully.';
      customerToAdd = {
        CustomerType: e,
        createdBy: this.props.AdminAuthentication.Username,
        isDisplay: true
      };
    }
    else {
      toastMessage = 'Customer Type updated successfully.';
      customerToAdd = {
        id: this.state.selectedCustomerType.id,
        CustomerType: e,
        ModifiedBy: this.props.AdminAuthentication.Username,
      };
    }

    if (this.state.selectedCustomerType ==null || (this.state.selectedCustomerType!=null && this.state.selectedCustomerType.customerType != e.trim())) {
      new API().addUpdateMasterCustomerTypes(customerToAdd).then(result => {
        if (result.data.isSuccess) {
          this.setState({ showDeletedTable: 0, showToast: true, isDeletedToast: false, toastHeader: 'Success', toastMessage: toastMessage, isPopupOpen: false, isEditClick: false });
          this.componentDidMount();
        }
        else {
          if (result.errorMessage != "") {
            this.setState({ errorMessage: result.data.errorMessage });
          }
        }
      });
    }
    else
    {
      this.setState({ showDeletedTable: 0, isDeletedToast: false, isPopupOpen: false, isEditClick: false });
         
    }

    

  }

  OnCloseToast = (e) => {
    this.setState({ showToast: !this.state.showToast });
  }

  onDeleteConfirm = (e) => {

    let deleteRequest = {
      id: this.state.selectedCustomerType.id,
      DeletedBy: this.props.AdminAuthentication.Username
    }

    new API().deleteCustomerType(deleteRequest).then(result => {

      if (result.data.isSuccess) {
        this.setState({ isRestoreOpen: false, showToast: true, isDeletedToast: true, toastHeader: 'Deleted', toastMessage: 'Customer Type deleted successfully.', isPopupOpen: false, isEditClick: false });

        this.componentDidMount();
      }
      else {
        if (result.errorMessage != "") {
          this.setState({ errorMessage: result.data.errorMessage });
        }

      }
    })

  }

  onRestoreConfirm = (e) => {

    let restoreRequest = {
      id: this.state.selectedCustomerType.id,
      ModifiedBy: this.props.AdminAuthentication.Username
    }

    new API().restoreCustomerType(restoreRequest).then(result => {
      if (result.data.isSuccess) {
        this.setState({ showToast: true, isDeletedToast: false, toastHeader: 'Restored', toastMessage: 'Customer Type restored successfully.', isPopupOpen: false, isEditClick: false });

        if (this.state.showDeletedTable == "0") {
          this.componentDidMount();
        }
        else {
          new API().getDeletedMasterCustomerType().then(result => {
            this.setState({ totalActiveRecords: result.data.totalActiveRecords, customerTypes: result.data.masterTypes, isConsentOpen: false, isPopupOpen: false, isRestoreOpen: false })
          })
        }
      }
      else {
        if (result.errorMessage != "") {
          this.setState({ errorMessage: result.data.errorMessage });
        }

      }
    })

  }

  OnRadioChanged = (e) => {
    this.setState({ showDeletedTable: e.target.value });
    if (e.target.value == 0) {
      new API().getMasterCustomerType().then(result => {
        this.setState({ totalActiveRecords: result.data.totalActiveRecords, customerTypes: result.data.masterTypes, isConsentOpen: false, isPopupOpen: false })
      })
    }
    else {
      new API().getDeletedMasterCustomerType().then(result => {
        this.setState({ totalActiveRecords: result.data.totalActiveRecords, customerTypes: result.data.masterTypes, isConsentOpen: false, isPopupOpen: false })
      })
    }

  }

  onRestoreClick = (e) => {
    let id = e.target.id;
    if (e.target.nodeName == "path") {
      id = e.target.parentNode.id;
    }
    this.setState({ isConsentOpen: false, isRestoreOpen: true, isPopupOpen: false, errorMessage: '', selectedCustomerType: this.state.customerTypes.filter(p => p.id == id)[0] });
  }

  render() {

    return <>

      <ToastContainer position="bottom-end">

        <Toast delay={10000} autohide show={this.state.showToast} onClose={this.OnCloseToast}>
          <Toast.Header>
            <strong className="me-auto"> {this.state.toastHeader}</strong>
          </Toast.Header>
          <Toast.Body className={this.state.isDeletedToast ? "bg-danger text-white" : "bg-success text-white"}> {this.state.toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <div className="d-flex justify-content-between align-items-center mob-colls">
        <h2> Master Customer Types </h2>
        <div className="d-flex">
          <div className="form-check form-check-cust">
            <input type="radio" className="form-check-input" id="active" checked={this.state.showDeletedTable == "0" ? true : false} value="0" onChange={this.OnRadioChanged} name="radio1" /> <label htmlFor="active" className="form-check-label"> Active</label>
          </div>
          <div className="form-check form-check-cust">
            <input type="radio" className="form-check-input" id="delete" checked={this.state.showDeletedTable == "1" ? true : false} value="1" onChange={this.OnRadioChanged} name="radio1" /> <label htmlFor="delete" className="form-check-label"> Archive</label>
          </div>
          {this.state.totalActiveRecords == 3 ? "" :
            <button onClick={this.onAddClick} className="add-new me-2"><FontAwesomeIcon icon={faPlus} />  </button>
          }
        </div>
      </div>

      <div className="table-responsive bg-white">

        {
          this.state.customerTypes.length == 0 ?

            <div className="nobookingtext text-center"> {this.state.showDeletedTable == "0" ? "Customer type data not found" : "Customer type not found for restore"} </div> : ""
        }
        {

          this.state.showDeletedTable == "0" ?
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">Customer Type</th>
                  <th scope="col">Created By</th>
                  <th scope="col">Created Date</th>
                  <th scope="col">Updated By</th>
                  <th scope="col">Updated Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.customerTypes.map((item, i) => {
                  return [
                    <tr key={item.id}>
                      <td>{item.customerType}</td>
                      <td>{item.createdBy}</td>
                      <td>{moment(moment.utc(item.createdDate).toDate()).local().format('MMMM Do YYYY, HH:mm A')}</td>
                      <td>{item.modifiedBy}</td>
                      <td>{item.modifiedDate != null ? moment(moment.utc(item.modifiedDate).toDate()).local().format('MMMM Do YYYY, HH:mm A') : ""}</td>
                      <td> <button className="edit" onClick={this.onEditClick} id={item.id}> <FontAwesomeIcon onClick={this.onEditClick} id={item.id} icon={faEdit} />  </button>
                        <button className="delete" onClick={this.onDeleteClick} id={item.id}> <FontAwesomeIcon onClick={this.onDeleteClick} id={item.id} icon={faTrashAlt} />  </button> </td>
                    </tr>
                  ]
                })}
              </tbody>
            </table> :

            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Customer Type</th>
                  <th scope="col">Deleted By</th>
                  <th scope="col">Deleted Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.customerTypes.map((item, i) => {
                  return [
                    <tr key={item.id}>
                      <td>{item.customerType}</td>
                      <td>{item.deletedBy}</td>
                      <td>{moment(moment.utc(item.deletedDate).toDate()).local().format('MMMM Do YYYY, HH:mm A')}</td>
                      <td>  <button className="delete" onClick={this.onRestoreClick} id={item.id}> <FontAwesomeIcon onClick={this.onRestoreClick} id={item.id} icon={faUndo} /> </button> </td>
                    </tr>
                  ]
                })}
              </tbody>
            </table>
        }

      </div>


      {
        this.state.isConsentOpen ?
          <DeleteConsent isConsentOpen={this.state.isConsentOpen} onCloseModel={this.onCloseModel} onDelete={this.onDeleteConfirm} errorMessage={this.state.errorMessage} />
          : ""
      }

      {
        this.state.isPopupOpen ?
          <AddCustomerType errorMessage={this.state.errorMessage} selectedCustomerType={this.state.selectedCustomerType} isPopupOpen={this.state.isPopupOpen} onCloseModel={this.onCloseModel} onConfirm={this.onConfirm} isEditClick={this.state.isEditClick} />
          : ""
      }

      {
        this.state.isRestoreOpen ?
          <RestoreConsent isRestoreOpen={this.state.isRestoreOpen} onCloseModel={this.onCloseModel} onRestore={this.onRestoreConfirm} errorMessage={this.state.errorMessage} type={"Location"} />
          : ""
      }
    </>


  }
}


const mapStateToProps = (state) => ({
  AdminAuthentication: state.AdminAuthentication,
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(MasterCustomerTypes);
