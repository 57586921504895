import BookingInfoReducer from './BookingInfoReducer'
import BookingInfoNextReducer from './BookingInfoNextReducer'
import CurrentPageReducer from './CurrentPageReducer'
import BookingProductsReducer from './BookingProductsReducer'
import CustomerLunchReducer from './CustomerLunchReducer'
import CustomersListReducer from './CustomersListReducer'
import AuthenticationReducer from './AuthenticationReducer'
import { combineReducers } from 'redux'
import AdminAuthenticationReducer from './AdminAuthenticationReducer'
import AdminLeftMenuReducer from './AdminLeftMenuReducer'
import TranslationReducer from './TranslationReducer'
import AdminEditBookingReducer from './AdminEditBookingReducer'
import AdminCurrentPageReducer from './AdminCurrentPageReducer'
import AdminInitialDataReducer from './AdminInitialDataReducer'


export default combineReducers({
    BookingInfo : BookingInfoReducer,
    BookingInfoNext : BookingInfoNextReducer,
    CurrentPage : CurrentPageReducer,
    BookingProducts : BookingProductsReducer,
    CustomerLunch : CustomerLunchReducer,
    CustomersList : CustomersListReducer,
    Authentication : AuthenticationReducer,
    AdminAuthentication : AdminAuthenticationReducer,
    AdminLeftMenu : AdminLeftMenuReducer,
    Translations : TranslationReducer,
    AdminEditBooking : AdminEditBookingReducer,
    AdminCurrentPage : AdminCurrentPageReducer,
    AdminInitialData : AdminInitialDataReducer
})