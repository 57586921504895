import { faEdit, faPlus, faTrashAlt, faUndo } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Component } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { connect } from "react-redux";
import API from "../../../Api";
import DeleteConsent from "../DeleteConsent";
import { loginUserInfoAdmin } from '../../../Redux/Actions/AdminAuthenticationActions'
import AddVertical from "../ConfigurationsPopups/AddVertical";
import RestoreConsent from "../RestoreConsent";

class MasterVerticals extends Component {

  constructor(props) {
    super(props);
    this.state = {
      masterVerticals: [],
      isConsentOpen: false,
      selectedVertical: {},
      errorMessage: '',
      isPopupOpen: false,
      isEditClick: false,
      showToast: false,
      toastMessage: '',
      toastHeader: '',
      isDeletedToast: false,
      showDeletedTable: 0,
      isRestoreOpen: false
    };
  }

  async componentDidMount() {
    new API().getMasterVerticals().then(result => {
      this.setState({ masterVerticals: result.data, isRestoreOpen: false, isConsentOpen: false })
    })
  }

  onDeleteClick = (e) => {
    let id = e.target.id;
    if (e.target.nodeName == "path") {
      id = e.target.parentNode.id;
    }
    this.setState({ isConsentOpen: true, isRestoreOpen: false, isPopupOpen: false, errorMessage: '', selectedVertical: this.state.masterVerticals.filter(p => p.id == id)[0] });
  }

  onEditClick = (e) => {
    let id = e.target.id;
    if (e.target.nodeName == "path") {
      id = e.target.parentNode.id;
    }
    this.setState({ isEditClick: true, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: true, errorMessage: '', selectedVertical: this.state.masterVerticals.filter(p => p.id == id)[0] });
  }

  onAddClick = (e) => {
    this.setState({ isEditClick: false, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: true, errorMessage: '', selectedVertical: null });
  }

  onCloseModel = (e) => {
    this.setState({ isEditClick: false, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: false });
  }


  onConfirm = (e) => {
    let toastMessage = '';
    let verticalToAdd = {};
    if (this.state.selectedVertical == null) {
      toastMessage = 'Vertical added successfully.';
      verticalToAdd = {
        VerticalName: e,
        createdBy: this.props.AdminAuthentication.Username,
        isDisplay: true

      };
    }
    else {

      toastMessage = 'Vertical updated successfully.';
      verticalToAdd = {
        id: this.state.selectedVertical.id,
        VerticalName: e,
        ModifiedBy: this.props.AdminAuthentication.Username,
      };

    }

    if(this.state.selectedVertical==null || (this.state.selectedVertical !=null && this.state.selectedVertical.verticalName != e.trim()))
    {
      new API().addUpdateMasterVertical(verticalToAdd).then(result => {
        if (result.data.isSuccess) {
          this.setState({ showDeletedTable: 0, showToast: true, isRestoreOpen: false, isDeletedToast: false, toastHeader: 'Success', toastMessage: toastMessage, isPopupOpen: false, isEditClick: false });
          this.componentDidMount();
        }
        else {
          if (result.errorMessage != "") {
            this.setState({ errorMessage: result.data.errorMessage });
          }
        } 
      });
    }
    else
    {
      this.setState({ showDeletedTable: 0,  isRestoreOpen: false, isDeletedToast: false, isPopupOpen: false, isEditClick: false });
       
    }

    

  }

  onDeleteConfirm = (e) => {

    let deleteRequest = {
      id: this.state.selectedVertical.id,
      DeletedBy: this.props.AdminAuthentication.Username
    }


    new API().deleteMasterVertical(deleteRequest).then(result => {

      if (result.data.isSuccess) {
        this.setState({ showToast: true, isRestoreOpen: false, isDeletedToast: true, toastHeader: 'Deleted', toastMessage: 'Vertical deleted successfully.', isPopupOpen: false, isEditClick: false });

        this.componentDidMount();
      }
      else {
        if (result.errorMessage != "") {
          this.setState({ errorMessage: result.data.errorMessage });
        }

      }
    })

  }

  OnCloseToast = (e) => {
    this.setState({ showToast: !this.state.showToast });
  }

  onRestoreConfirm = (e) => {

    let restoreRequest = {
      id: this.state.selectedVertical.id,
      ModifiedBy: this.props.AdminAuthentication.Username
    }

    new API().restoreMasterVertical(restoreRequest).then(result => {
      if (result.data.isSuccess) {
        this.setState({ showToast: true, isDeletedToast: false, toastHeader: 'Restored', toastMessage: 'Vertical restored successfully.', isPopupOpen: false, isEditClick: false });

        if (this.state.showDeletedTable == "0") {
          this.componentDidMount();
        }
        else {
          new API().getDeletedMasterVerticals().then(result => {
            this.setState({ masterVerticals: result.data, isConsentOpen: false, isPopupOpen: false, isRestoreOpen: false })
          })
        }
      }
      else {
        if (result.errorMessage != "") {
          this.setState({ errorMessage: result.data.errorMessage });
        }

      }
    })

  }

  OnRadioChanged = (e) => {
    this.setState({ showDeletedTable: e.target.value });
    if (e.target.value == 0) {
      this.componentDidMount();
    }
    else {
      new API().getDeletedMasterVerticals().then(result => {
        this.setState({ masterVerticals: result.data, isConsentOpen: false, isPopupOpen: false })
      })
    }

  }

  onRestoreClick = (e) => {
    let id = e.target.id;
    if (e.target.nodeName == "path") {
      id = e.target.parentNode.id;
    }
    this.setState({ isConsentOpen: false, isRestoreOpen: true, isPopupOpen: false, errorMessage: '', selectedVertical: this.state.masterVerticals.filter(p => p.id == id)[0] });
  }

  render() {

    return <>
      <ToastContainer position="bottom-end">

        <Toast delay={10000} autohide show={this.state.showToast} onClose={this.OnCloseToast}>
          <Toast.Header>
            <strong className="me-auto"> {this.state.toastHeader}</strong>
          </Toast.Header>
          <Toast.Body className={this.state.isDeletedToast ? "bg-danger text-white" : "bg-success text-white"}> {this.state.toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <div className="d-flex justify-content-between align-items-center mob-colls">
        <h2> Master Verticals </h2>
        <div className="d-flex">
          <div className="form-check form-check-cust">
            <input type="radio" className="form-check-input" id="active" checked={this.state.showDeletedTable == "0" ? true : false} value="0" onChange={this.OnRadioChanged} name="radio1" /> <label htmlFor="active" className="form-check-label"> Active</label>
          </div>
          <div className="form-check form-check-cust">
            <input type="radio" className="form-check-input" id="delete" checked={this.state.showDeletedTable == "1" ? true : false} value="1" onChange={this.OnRadioChanged} name="radio1" /> <label htmlFor="delete" className="form-check-label"> Archive</label>
          </div>
          <button onClick={this.onAddClick} className="add-new me-2"><FontAwesomeIcon icon={faPlus} />  </button>
        </div> </div>

      <div className="table-responsive bg-white">
        {
          this.state.masterVerticals.length == 0 ?
            <div className="nobookingtext text-center"> {this.state.showDeletedTable == "0" ? "Vertical data not found" : "Vertical data not found for restore"} </div> : ""
        }
        {
          this.state.showDeletedTable == "0" ?
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Vertical</th>
                  <th scope="col">Created By</th>
                  <th scope="col">Created Date</th>
                  <th scope="col">Updated By</th>
                  <th scope="col">Updated Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.masterVerticals.map((item, i) => {
                  return [
                    <tr key={item.id}>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.verticalName}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.createdBy}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{moment(moment.utc(item.createdDate).toDate()).local().format('MMMM Do YYYY, HH:mm A')}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.modifiedBy}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.modifiedDate != null ? moment(moment.utc(item.modifiedDate).toDate()).local().format('MMMM Do YYYY, HH:mm A') : ""}</td>
                      <td> <button className="edit" onClick={this.onEditClick} id={item.id}> <FontAwesomeIcon onClick={this.onEditClick} id={item.id} icon={faEdit} /> </button>
                        <button className="delete" onClick={this.onDeleteClick} id={item.id}> <FontAwesomeIcon onClick={this.onDeleteClick} id={item.id} icon={faTrashAlt} /> </button> </td>
                    </tr>
                  ]
                })}
              </tbody>
            </table> :
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Vertical</th>
                  <th scope="col">Deleted By</th>
                  <th scope="col">Deleted Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.masterVerticals.map((item, i) => {
                  return [
                    <tr key={item.id}>
                      <td>{item.verticalName}</td>
                      <td>{item.deletedBy}</td>
                      <td>{moment(moment.utc(item.deletedDate).toDate()).local().format('MMMM Do YYYY, HH:mm A')}</td>
                      <td>  <button className="delete" onClick={this.onRestoreClick} id={item.id}> <FontAwesomeIcon onClick={this.onRestoreClick} id={item.id} icon={faUndo} /> </button> </td>
                    </tr>
                  ]
                })}
              </tbody>
            </table>
        }
      </div>

      {
        this.state.isConsentOpen ?
          <DeleteConsent isConsentOpen={this.state.isConsentOpen} onCloseModel={this.onCloseModel} onDelete={this.onDeleteConfirm} errorMessage={this.state.errorMessage} type={"Location"} />
          : ""
      }
      {
        this.state.isPopupOpen ?
          <AddVertical errorMessage={this.state.errorMessage} selectedVertical={this.state.selectedVertical} isPopupOpen={this.state.isPopupOpen} onCloseModel={this.onCloseModel} onConfirm={this.onConfirm} isEditClick={this.state.isEditClick} type={"Location"} />
          : ""
      }

      {
        this.state.isRestoreOpen ?
          <RestoreConsent isRestoreOpen={this.state.isRestoreOpen} onCloseModel={this.onCloseModel} onRestore={this.onRestoreConfirm} errorMessage={this.state.errorMessage} type={"Location"} />
          : ""
      }
    </>


  }
}

const mapStateToProps = (state) => ({
  AdminAuthentication: state.AdminAuthentication,
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(MasterVerticals);