import { Component } from "react";
import { Modal } from 'react-bootstrap'
class GuestDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    getTranslations(key) {   

        if(this.props.Translations.translations.filter(p => p.appKey == key)[0] !=null )
        return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
        return "";
      }

    render() {
        return (
            <div className="modal show" style={{ display: 'block', position: 'initial' }}>
                <Modal
                    show={this.props.isGuestPopupOpen}
                    onHide={this.props.onCloseModel}                    
                >
                    <Modal.Header>
                        <h5 id="exampleModalLabel"> {this.getTranslations("Admin_GuestPage_Heading")} </h5>
                    </Modal.Header>
                    <Modal.Body className="p-2">
                        <div className="col-md-12 w-100">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>{this.getTranslations("Admin_GuestPage_Name")}</th>                                        
                                        <th>{this.getTranslations("Admin_GuestPage_Role")}</th>
                                        <th>{this.getTranslations("Admin_GuestPage_Email")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.customers.map((item, i) => {
                                        return [
                                            <tr key={item.id}>
                                                <td>{item.customerName}</td>
                                                <td>{item.customerRole}</td>
                                                <td>{item.customerEmail}</td>
                                                
                                            </tr>
                                        ]
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                            <button type="button" onClick={this.props.onCloseModel} className="btn outline-button btn-small" data-bs-dismiss="modal">Close</button>
                        </div>
                    </Modal.Body>
                    
                </Modal>
            </div>
        );
    }

}

export default GuestDetails;