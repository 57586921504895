import { Component } from "react";
import { Modal } from 'react-bootstrap'

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedUser: this.props.selectedUser,
            username: this.props.selectedUser.username,
            password: '',
            ispasswordError: false,
            confirmpassword: '',
            confirmPasswordErrorMsg: '',
            isconfirmpasswordError: false
        }
    }

    onPasswordChange = (e) => {
        this.setState({ password: e.target.value })
        if (e.target.value.length >= 5)
            this.setState({ ispasswordError: false })
    }

    onConfirmPasswordChange = (e) => {
        this.setState({ confirmpassword: e.target.value })
        if (e.target.value.length >= 5)
            this.setState({ isconfirmpasswordError: false })
    }

    onYesClick = (e) => {

        if (this.state.password.length >= 5)
            this.setState({ ispasswordError: false })
        else
            this.setState({ ispasswordError: true })

        if (this.state.confirmpassword.length >= 5) {
            if (this.state.password != this.state.confirmpassword) {
                this.setState({ isconfirmpasswordError: true, confirmPasswordErrorMsg: 'Password and confirm password should be same' })
            }
            else {
                this.setState({ isconfirmpasswordError: false });
            }
        }
        else {
            this.setState({ isconfirmpasswordError: true, confirmPasswordErrorMsg: 'Confirm password should contain minimum 5 characters' })

        }

        if (this.state.password.length >= 5 && this.state.confirmpassword.length >= 5 && this.state.password == this.state.confirmpassword) {
            let userInfo = {
                id: this.state.selectedUser.id,
                username: this.state.username,
                newpassword: this.state.password
            }

            this.props.onChangePassword(userInfo);
        }
    }

    checkOnBlurOnPassword = () => {
        if (this.state.password.length >= 3)
            this.setState({ ispasswordError: false })
        else
            this.setState({ ispasswordError: true })
    }

    checkOnBlurOnConfirmPassword = () => {
        if (this.state.confirmpassword.length >= 3)
            this.setState({ isconfirmpasswordError: false })
        else
            this.setState({ isconfirmpasswordError: true })
    }

    render() {
        return (
            <div className="modal show" style={{ display: 'block', position: 'initial' }}>
                <Modal show={this.props.isChangeOpen} onHide={this.props.onCloseModel} >
                    <Modal.Header className="modal-footer justify-content-center">
                        <h5 id="exampleModalLabel">Reset Password </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-md-12 w-100">
                            <h6 > User Name </h6>
                            <div className="feedback-wrapper">
                                <div className="mt-1">
                                    <input disabled onChange={this.onUserChange} value={this.state.username} className={this.state.isusernameError ? "form-control cancelBox is-invalid" : "form-control cancelBox"}
                                        id="username" name="username" onBlur={() => { this.checkOnBlurUserName() }}></input>
                                </div>
                            </div>
                            {
                                this.state.isusernameError ?
                                    <div style={{ color: 'red' }}> Please provide atleast 4 characters.</div> : ''
                            }

                        </div>

                        <div className="col-md-12 w-100">
                            <h6 >New Password </h6>
                            <div className="feedback-wrapper">
                                <div className="mt-1">
                                    <input type="password" onChange={this.onPasswordChange} value={this.state.password} className={this.state.ispasswordError ? "form-control cancelBox is-invalid" : "form-control cancelBox"}
                                        id="password" autocomplete="new-password" name="password" onBlur={() => { this.checkOnBlurOnPassword() }}></input>
                                </div>
                            </div>
                            {
                                this.state.ispasswordError ?
                                    <div style={{ color: 'red' }}> Please provide atleast 5 characters.</div> : ''
                            }

                        </div>

                        <div className="col-md-12 w-100">
                            <h6 >Confirm Password </h6>
                            <div className="feedback-wrapper">
                                <div className="mt-1">
                                    <input type="password" onChange={this.onConfirmPasswordChange} value={this.state.confirmpassword} className={this.state.isconfirmpasswordError ? "form-control cancelBox is-invalid" : "form-control cancelBox"}
                                        id="confirmpassword" autocomplete="new-password" name="confirmpassword" onBlur={() => { this.checkOnBlurOnConfirmPassword() }}></input>
                                </div>
                            </div>
                            {
                                this.state.isconfirmpasswordError ?
                                    <div style={{ color: 'red' }}> {this.state.confirmPasswordErrorMsg} </div> : ''
                            }

                        </div>



                        {this.props.errorMessage != "" ? <h6 className="text-center err-msg-used">{this.props.errorMessage}</h6> : ""}



                        <div className="d-flex justify-content-center mt-4">
                            <button type="button" onClick={this.props.onCloseModel} className="btn outline-button btn-small" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" onClick={(e) => this.onYesClick(e)} className="btn fill-button btn-small">{this.props.isEditClick ? "Update" : "Save"}</button>
                        </div>
                    </Modal.Body>



                </Modal>

            </div>
        );
    }

}

export default ResetPassword;