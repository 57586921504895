import { Component } from "react";
import { faChevronLeft, faChevronRight, faQuestionCircle } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bookingInfoNext } from '../../Redux/Actions/BookingActionNext'
import { connect } from "react-redux";
import { currentPage } from '../../Redux/Actions/CurrentPageAction'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import 'moment-timezone'
import API from "../../Api";
import '../../Css/slider-number.css'
import { Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactFormInputValidation from "react-form-input-validation";
import { customersList, removeItemCustomersList } from '../../Redux/Actions/CustomersListAction'
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import WarningConsent from "../AdminDashboard/WarningConsent";
import { endOfSecond, getDay } from "date-fns";
import morningImage from "../../Images/morning.svg"
import eveningImage from "../../Images/evening.svg"

class Page2 extends Component {

  constructor(props) {
    super(props);
    let currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + this.props.minimumBookingTimeFromCurrentDateInMinutes)
    this.BlockBookedDates(this.props.BookingInfoNext.page2.timeSlotId);
    this.state = {
      dateRangeSelectionInMonths: localStorage.getItem("dateRangeSelectionInMonths"),
      timezone: this.props.BookingInfo.page1.timezone,
      customers: this.props.CustomersList.customers,
      isWarningOpen: false,
      bookedDates: [],
      bookedSlots: [],
      excludedTimes: [],
      showAlternateSlotText: '',
      showAlternateSlotForSameDate: false,
      showAlternateSlotSelectionMessage: false,
      isAlternateButtonClick: false,
      showDateInvalidMessage: false,
      fields:
      {
        sizeOfVisitingParty: this.props.BookingInfoNext.page2.sizeOfVisitingParty,
        hclParticipants: this.props.BookingInfoNext.page2.hclParticipants,
        selectedTime: this.props.BookingInfoNext.page2.selectedTime
      },
      timeSlotId: this.props.BookingInfoNext.page2.timeSlotId,
      selectedDate: this.props.BookingInfoNext.page2.selectedDate ? this.fillSelectedDate(moment(this.props.BookingInfoNext.page2.selectedDate).toDate()) : currentDate,
      selectedEndDate: this.props.BookingInfoNext.page2.selectedEndDate ? this.fillSelectedDate(moment(this.props.BookingInfoNext.page2.selectedEndDate).toDate()) : currentDate,
      isDateValid: true,
      isTimeSlotValid: true,
      timeSlotValidMessage: 'This field is required.',
      errors: {},
      index: 1,  //index which u want to display first
      direction: 'null', //direction of the carousel..u need to set it to either 'next' or 'prev' based on user click
      nextIcon: <span> <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon></span>,
      prevIcon: <span><FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon></span>
    };

    this.form = new ReactFormInputValidation(this);
    this.form.useRules({
      sizeOfVisitingParty: "required",
      hclParticipants: "required",
      //timeSlotId: "required",
      selectedTime: "required",
    });
    this.form.onformsubmit = (fields) => {

      if (this.state.timeSlotId == "" || this.state.timeSlotId == undefined) {
        this.setState({ isTimeSlotValid: false })
        return;
      }

      var selectedSlot = this.props.timeSlots.filter(p => p.id == this.state.timeSlotId);
      var startDateFormatted = moment(this.state.selectedDate, 'ddd MMM D YYYY HH:mm:ss ZZ');
      startDateFormatted.set({ h: selectedSlot[0].startRange, m: 0, s: 0 });

      var endDateFormatted = moment(this.state.selectedDate, 'ddd MMM D YYYY HH:mm:ss ZZ');
      endDateFormatted.set({ h: selectedSlot[0].startRange + selectedSlot[0].endRange, m: 0, s: 0 });

      if (!this.showMessageForAvailableSlots(this.state.selectedDate, this.state.timeSlotId)) {
        if (this.state.timeSlotId == 3) {
          return;
        }
        else {
          if (!this.state.showAlternateSlotForSameDate) {
            this.setState({ showAlternateSlotSelectionMessage: false, showDateInvalidMessage: true });
            return;
          }
          else {
            this.setState({ showAlternateSlotSelectionMessage: true, showDateInvalidMessage: false });
            return;
          }
        }

      }

      if (this.state.isAlternateButtonClick) {
        this.setState({ isAlternateButtonClick: false });
        return;
      }

      if (Object.keys(this.state.errors).length === 0 && this.state.isDateValid && this.state.isTimeSlotValid) {

        if (localStorage.getItem("isValidationRequired") == "true") {
          new API().IsValidBookingSlot(moment(this.state.selectedDate).format('YYYY-MM-DD hh:mm:ss a'), this.state.timeSlotId).then(result => {
            if (result) {
              if (this.state.customers && this.state.customers.length > this.state.fields.sizeOfVisitingParty) {
                //handle if someone change and selected less size of visiting party. remove other items from the customer list        
                for (let i = this.state.customers.length; i > (this.state.customers.length - this.state.fields.sizeOfVisitingParty); i--) {
                  this.props.removeItemCustomersList(i - 1);
                }
              }

              for (let i = 0; i < this.state.fields.sizeOfVisitingParty; i++) {
                //handle previous button on page3 and retain already filled value.
                let customer = {
                  customerName: this.state.customers[i] ? this.state.customers[i].customerName : '',
                  customerEmail: this.state.customers[i] ? this.state.customers[i].customerEmail : '',
                  customerRole: this.state.customers[i] ? this.state.customers[i].customerRole : '',
                  isValidated: this.state.customers[i] ? this.state.customers[i].isValidated : false,
                  index: i
                };
                this.props.customersList(customer);
              }
              this.props.bookingInfoNext({
                page2: {
                  sizeOfVisitingParty: this.state.fields.sizeOfVisitingParty,
                  hclParticipants: this.state.fields.hclParticipants,
                  timeSlotId: this.state.timeSlotId,
                  selectedDate: moment(startDateFormatted),
                  selectedEndDate: moment(endDateFormatted),
                  //selectedDateTimeZone: moment.tz(moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm:ss'),this.state.timeZone),
                  selectedDateTimeZone: moment.tz(moment(startDateFormatted).format('YYYY-MM-DD HH:mm:ss'), this.state.timezone).format(),
                  selectedEndDateTimeZone: moment.tz(moment(endDateFormatted).format('YYYY-MM-DD HH:mm:ss'), this.state.timezone).format(),
                  selectedTime: moment(startDateFormatted),
                  //selectedEndDateTimeZone:moment.tz(moment(this.state.selectedEndDate).format('YYYY-MM-DD HH:mm:ss'),this.state.timeZone),
                }
              });
              this.props.currentPage(3);
              this.props.sendData(3);
            }
            else {
              this.setState({ isDateValid: false, showDateInvalidMessage: true });
            }
          });
        }
        else {
          if (this.state.customers && this.state.customers.length > this.state.fields.sizeOfVisitingParty) {
            //handle if someone change and selected less size of visiting party. remove other items from the customer list        
            for (let i = this.state.customers.length; i > (this.state.customers.length - this.state.fields.sizeOfVisitingParty); i--) {
              this.props.removeItemCustomersList(i - 1);
            }
          }

          for (let i = 0; i < this.state.fields.sizeOfVisitingParty; i++) {
            //handle previous button on page3 and retain already filled value.
            let customer = {
              customerName: this.state.customers[i] ? this.state.customers[i].customerName : '',
              customerEmail: this.state.customers[i] ? this.state.customers[i].customerEmail : '',
              customerRole: this.state.customers[i] ? this.state.customers[i].customerRole : '',
              isValidated: this.state.customers[i] ? this.state.customers[i].isValidated : false,
              index: i
            };
            this.props.customersList(customer);
          }
          this.props.bookingInfoNext({
            page2: {
              sizeOfVisitingParty: this.state.fields.sizeOfVisitingParty,
              hclParticipants: this.state.fields.hclParticipants,
              timeSlotId: this.state.timeSlotId,
              selectedDate: moment(startDateFormatted),
              selectedEndDate: moment(endDateFormatted),
              selectedDateTimeZone: moment.tz(moment(startDateFormatted).format('YYYY-MM-DD HH:mm:ss'), this.state.timezone).format(),
              selectedEndDateTimeZone: moment.tz(moment(endDateFormatted).format('YYYY-MM-DD HH:mm:ss'), this.state.timezone).format(),
              //selectedDateTimeZone: moment.tz(moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm:ss'),this.state.timeZone),
              //selectedEndDateTimeZone: moment.tz(moment(this.state.selectedEndDate).format('YYYY-MM-DD HH:mm:ss'),this.state.timeZone) ,
              selectedTime: moment(startDateFormatted),
            }
          });
          this.props.currentPage(3);
          this.props.sendData(3);
        }
      }
    }
    let message = ReactFormInputValidation.getMessages('en');
    message.required = 'This field is required.'
    ReactFormInputValidation.setMessages('en', message);
  }

  fillSelectedDate(date) {
    let currentTime = new Date();
    if (currentTime > date) {
      currentTime.setMinutes(currentTime.getMinutes() + this.props.minimumBookingTimeFromCurrentDateInMinutes)
      return currentTime;
    }
    else {
      return date;
    }

  }

  PreviousButtonClick = () => {
    this.props.bookingInfoNext({
      page2: {
        sizeOfVisitingParty: this.state.fields.sizeOfVisitingParty,
        hclParticipants: this.state.fields.hclParticipants,
        timeSlotId: this.state.timeSlotId,
        selectedDate: moment(this.state.selectedDate),
        selectedEndDate: moment(this.state.selectedEndDate),
        selectedTime: moment(this.state.selectedDate),
      }
    });
    this.props.currentPage(1);
    this.props.sendData(1)
  }

  OnDateChange = (value) => {

    var selectedSlot = this.props.timeSlots.filter(p => p.id == this.state.timeSlotId);
    if (selectedSlot.length > 0) {
      var startDateFormatted = moment(value, 'ddd MMM D YYYY HH:mm:ss ZZ');
      startDateFormatted.set({ h: selectedSlot[0].startRange, m: 0, s: 0 });

      var endDateFormatted = moment(value, 'ddd MMM D YYYY HH:mm:ss ZZ');
      endDateFormatted.set({ h: selectedSlot[0].startRange + selectedSlot[0].endRange, m: 0, s: 0 });

      this.setState({ showAlternateSlotSelectionMessage: false, selectedDate: startDateFormatted.toDate(), selectedEndDate: endDateFormatted.toDate() });
      this.showMessageForAvailableSlots(value, this.state.timeSlotId);
    }
    else {
      this.setState({ selectedDate: value, selectedEndDate: value, showAlternateSlotSelectionMessage: false });

    }


    if (localStorage.getItem("isValidationRequired") == "true") {
      new API().IsValidBookingSlot(moment(value).format("YYYY-MM-DD hh:mm:ss a"), this.state.timeSlotId).then(result => {
        this.setState({ isDateValid: result.data })
      });
    }
  }

  showMessageForAvailableSlots(date, selectedSlotId) {
    if (selectedSlotId != '') {
      var isDateValid = true;
      var selectedDateForBooking = moment.utc(date).format('YYYY-MM-DD');

      var morningAndFull = this.state.bookedSlots.filter(p => (p.slotId == 1 || p.slotId == 3) && moment.utc(p.date).format('YYYY-MM-DD') == selectedDateForBooking);
      var eveningAndFull = this.state.bookedSlots.filter(p => (p.slotId == 2 || p.slotId == 3) && moment.utc(p.date).format('YYYY-MM-DD') == selectedDateForBooking);
      //var fullDayBooking = this.state.bookedSlots.filter(p => p.slotId == 3 && moment.utc(p.date).format('YYYY-MM-DD') == selectedDateForBooking);


      if (selectedSlotId == 1) {
        if (morningAndFull.length > 0) {
          //console.log("Morning slot is not available on selected date " + date);
          if (eveningAndFull.length > 0) {
            isDateValid = false;
            this.setState({ showAlternateSlotForSameDate: false, isDateValid: false, showDateInvalidMessage: true });
            //console.log("Evening slot is not available on selected date " + date);
          }
          else {
            isDateValid = false;
            var msgForSlot = this.props.timeSlots.filter(p => p.id == 2)[0].slotType;
            this.setState({ showAlternateSlotForSameDate: true, isDateValid: false, showDateInvalidMessage: true, showAlternateSlotText: msgForSlot });
            //console.log("Evening slot is available on selected date " + date);
          }
        }
        else {
          isDateValid = true;
          this.setState({ showAlternateSlotForSameDate: false, isDateValid: true, showDateInvalidMessage: false });
          //console.log("Morning slot is available on selected date " + date);
        }

      }
      if (selectedSlotId == 2) {
        if (eveningAndFull.length > 0) {
          //console.log("Evening slot is not available on selected date " + date);
          if (morningAndFull.length > 0) {
            isDateValid = false;
            this.setState({ showAlternateSlotForSameDate: false, isDateValid: false, showDateInvalidMessage: true });
            //console.log("Morning slot is not available on selected date " + date);
          }
          else {
            var msgForSlot = this.props.timeSlots.filter(p => p.id == 1)[0].slotType;
            isDateValid = false;
            this.setState({ showAlternateSlotForSameDate: true, isDateValid: false, showDateInvalidMessage: true, showAlternateSlotText: msgForSlot });
            //console.log("Morning slot is available on selected date " + date);
          }
        }
        else {
          isDateValid = true;
          this.setState({ showAlternateSlotForSameDate: false, isDateValid: true, showDateInvalidMessage: false });
          //console.log("Evening slot is available on selected date " + date);
        }
      }
      if (selectedSlotId == 3) {
        if (eveningAndFull.length > 0 || morningAndFull.length > 0) {
          isDateValid = false;
          this.setState({ isDateValid: false, showDateInvalidMessage: true });
          //console.log("Full day slot is not available on selected date " + date);
        }
        else {
          isDateValid = true;
          this.setState({ isDateValid: true, showDateInvalidMessage: false });
          //console.log("Full day slot is available on selected date " + date);
        }
      }

      return isDateValid;
    }
  }

  onCloseWarningModel = (e) => {
    this.setState({ isWarningOpen: false })
  }

  BlockBookedDates(timeslotId) {
    if (timeslotId != null && timeslotId != '') {
      new API().GetBookedSlots(this.props.BookingInfo.page1.locationId).then(result => {
        let Dates = [];
        let Slots = [];
        result.data.map((item, i) => {
          Slots.push({ date: moment.utc(item.bookingDate).toDate(), slotId: item.slotId });
          if (timeslotId == 3 || (item.slotId == timeslotId || item.slotId == 3)) {
            Dates.push(moment.utc(item.bookingDate).toDate());
          }
        });
        this.setState({ bookedDates: Dates, bookedSlots: Slots });
      });
    }
  }

  OnTimeSlotChange = (e) => {
    if (e.target.value != "") {
      this.setState({ showAlternateSlotForSameDate: false });
      this.BlockBookedDates(e.target.value);

      this.setState({ showAlternateSlotSelectionMessage: false, timeSlotId: e.target.value, isTimeSlotValid: true, timeSlotValidMessage: '' });
      if (this.state.selectedDate != null) {
        this.showMessageForAvailableSlots(this.state.selectedDate, e.target.value);
      }

      var selectedSlot = this.props.timeSlots.filter(p => p.id == e.target.value);
      if (selectedSlot.length > 0) {
        var startDateFormatted = moment(this.state.selectedDate, 'ddd MMM D YYYY HH:mm:ss ZZ');
        startDateFormatted.set({ h: selectedSlot[0].startRange, m: 0, s: 0 });

        var endDateFormatted = moment(this.state.selectedDate, 'ddd MMM D YYYY HH:mm:ss ZZ');
        endDateFormatted.set({ h: selectedSlot[0].startRange + selectedSlot[0].endRange, m: 0, s: 0 });

        this.setState({ selectedDate: startDateFormatted.toDate(), selectedEndDate: endDateFormatted.toDate() });
      }
    }
    else {
      this.setState({ showAlternateSlotSelectionMessage: false, timeSlotId: e.target.value, isTimeSlotValid: false, timeSlotValidMessage: 'This field is required.' })
    }
  }


  renderTooltip = (title) => (
    <Tooltip placement='right'>{title}</Tooltip>
  );


  changeTimeSlot = () => {

    if (this.state.timeSlotId == 1) {

      var selectedSlot = this.props.timeSlots.filter(p => p.id == 2);
      if (selectedSlot.length > 0) {
        var startDateFormatted = moment(this.state.selectedDate, 'ddd MMM D YYYY HH:mm:ss ZZ');
        startDateFormatted.set({ h: selectedSlot[0].startRange, m: 0, s: 0 });

        var endDateFormatted = moment(this.state.selectedDate, 'ddd MMM D YYYY HH:mm:ss ZZ');
        endDateFormatted.set({ h: selectedSlot[0].startRange + selectedSlot[0].endRange, m: 0, s: 0 });

        this.setState({ showAlternateSlotSelectionMessage: false, isDateValid: true, isAlternateButtonClick: true, timeSlotId: 2, selectedDate: startDateFormatted.toDate(), selectedEndDate: endDateFormatted.toDate() });
        this.BlockBookedDates(2);
      }
    }
    else {
      var selectedSlot = this.props.timeSlots.filter(p => p.id == 1);
      if (selectedSlot.length > 0) {
        var startDateFormatted = moment(this.state.selectedDate, 'ddd MMM D YYYY HH:mm:ss ZZ');
        startDateFormatted.set({ h: selectedSlot[0].startRange, m: 0, s: 0 });

        var endDateFormatted = moment(this.state.selectedDate, 'ddd MMM D YYYY HH:mm:ss ZZ');
        endDateFormatted.set({ h: selectedSlot[0].startRange + selectedSlot[0].endRange, m: 0, s: 0 });

        this.setState({ showAlternateSlotSelectionMessage: false, isDateValid: true, isAlternateButtonClick: true, timeSlotId: 1, selectedDate: startDateFormatted.toDate(), selectedEndDate: endDateFormatted.toDate() });
        this.BlockBookedDates(1);
      }
    }

  }


  getTranslations(key) {

    if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
      return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
      return "";
  }


  render() {

    const isWeekday = (date) => {
      const day = getDay(date);
      return day !== 0 && day !== 6;
    };

    const { nextIcon, prevIcon } = this.state;
    return <> <form onSubmit={this.form.handleSubmit}><fieldset className="wizard-fieldset show">
      <div className="col-md-12 right-section">
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label"> {this.getTranslations("User_BookingNextPage_VisitingDelegation")} </label>
          <OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingNextPage_VisitingDelegationTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
          <br />
          <div className={this.state.errors.sizeOfVisitingParty ? "stv-radio-buttons-wrapper participants is-invalid customslider" : "stv-radio-buttons-wrapper participants customslider"}>

            <Carousel interval={null} indicators={false} style={{ background: 'gray' }} prevIcon={prevIcon} nextIcon={nextIcon} nextLabel={""} prevLabel={""}>
              <Carousel.Item key="svm1">
                {/* <input onChange={this.OnChangeHandler} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="1" id="sizestart" />
                <label htmlFor="size1"><FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon><i className="fa-solid fa-chevron-left"></i></label>  */}
                <input checked={this.state.fields.sizeOfVisitingParty == 1 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="1" id="size1" />
                <label htmlFor="size1">1</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 2 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="2" id="size2" />
                <label htmlFor="size2">2</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 3 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="3" id="size3" />
                <label htmlFor="size3">3</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 4 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="4" id="size4" />
                <label htmlFor="size4">4</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 5 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="5" id="size5" />
                <label htmlFor="size5">5</label>
                {/* <input onChange={this.OnChangeHandler} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="5" id="size5" />
                <label htmlFor="size5"><FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon><i className="fa-solid fa-chevron-right"></i></label> */}
              </Carousel.Item>
              <Carousel.Item key="svm2">
                <input checked={this.state.fields.sizeOfVisitingParty == 6 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="6" id="size6" />
                <label htmlFor="size6">6</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 7 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="7" id="size7" />
                <label htmlFor="size7">7</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 8 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="8" id="size8" />
                <label htmlFor="size8">8</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 9 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="9" id="size9" />
                <label htmlFor="size9">9</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 10 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="10" id="size10" />
                <label htmlFor="size10">10</label>
              </Carousel.Item>
              <Carousel.Item key="svm3">
                <input checked={this.state.fields.sizeOfVisitingParty == 11 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="11" id="size11" />
                <label htmlFor="size11">11</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 12 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="12" id="size12" />
                <label htmlFor="size12">12</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 13 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="13" id="size13" />
                <label htmlFor="size13">13</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 14 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="14" id="size14" />
                <label htmlFor="size14">14</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 15 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="15" id="size15" />
                <label htmlFor="size15">15</label>

              </Carousel.Item>
            </Carousel>
            {/* <input onChange={this.OnChangeHandler} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="15" id="size15" /> */}
            {/* <label htmlFor="size15"><FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon><i className="fa-solid fa-chevron-right"></i></label> */}
          </div>
          <label className="invalid-feedback">
            {this.state.errors.sizeOfVisitingParty ? this.state.errors.sizeOfVisitingParty : ""}
          </label>
        </div>


        <div className="mb-3">
          <label htmlFor="" className="form-label">{this.getTranslations("User_BookingNextPage_HostingParty")}</label>
          <OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingNextPage_HostingPartyTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
          <br />
          <div className={this.state.errors.hclParticipants ? "stv-radio-buttons-wrapper participants is-invalid customslider" : "stv-radio-buttons-wrapper participants customslider"}>
            <Carousel interval={null} indicators={false} style={{ background: 'gray' }} prevIcon={prevIcon} nextIcon={nextIcon} nextLabel={""} prevLabel={""}>
              <Carousel.Item key="ht1">
                {/* <input onChange={this.OnChangeHandler} type="radio" className="stv-radio-button" name="hclParticipants" value="1" id="partbuttonstart" />
            <label htmlFor="part1"><FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon><i className="fa-solid fa-chevron-left"></i></label> */}

                <input checked={this.state.fields.hclParticipants == 1 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="1" id="part1" />
                <label htmlFor="part1">1</label>

                <input checked={this.state.fields.hclParticipants == 2 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="2" id="part2" />
                <label htmlFor="part2">2</label>

                <input checked={this.state.fields.hclParticipants == 3 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="3" id="part3" />
                <label htmlFor="part3">3</label>

                <input checked={this.state.fields.hclParticipants == 4 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="4" id="part4" />
                <label htmlFor="part4">4</label>

                <input checked={this.state.fields.hclParticipants == 5 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="5" id="part5" />
                <label htmlFor="part5">5</label>
                {/* <input onChange={this.OnChangeHandler} type="radio" className="stv-radio-button" name="hclParticipants" value="10" id="part10" />
            <label htmlFor="part5"><FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon><i className="fa-solid fa-chevron-right"></i></label> */}

              </Carousel.Item>
              <Carousel.Item key="ht2">
                <input checked={this.state.fields.hclParticipants == 6 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="6" id="part6" />
                <label htmlFor="part6">6</label>

                <input checked={this.state.fields.hclParticipants == 7 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="7" id="part7" />
                <label htmlFor="part7">7</label>

                <input checked={this.state.fields.hclParticipants == 8 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="8" id="part8" />
                <label htmlFor="part8">8</label>

                <input checked={this.state.fields.hclParticipants == 9 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="9" id="part9" />
                <label htmlFor="part9">9</label>

                <input checked={this.state.fields.hclParticipants == 10 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="10" id="part10" />
                <label htmlFor="part10">10</label>

              </Carousel.Item>
              <Carousel.Item key="ht3">
                <input checked={this.state.fields.hclParticipants == 11 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="11" id="part11" />
                <label htmlFor="part11">11</label>

                <input checked={this.state.fields.hclParticipants == 12 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="12" id="part12" />
                <label htmlFor="part12">12</label>

                <input checked={this.state.fields.hclParticipants == 13 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="13" id="part13" />
                <label htmlFor="part13">13</label>

                <input checked={this.state.fields.hclParticipants == 14 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="14" id="part14" />
                <label htmlFor="part14">14</label>

                <input checked={this.state.fields.hclParticipants == 15 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="15" id="part15" />
                <label htmlFor="part15">15</label>
              </Carousel.Item>
            </Carousel>

          </div>
          <label className="invalid-feedback">
            {this.state.errors.hclParticipants ? this.state.errors.hclParticipants : ""}
          </label>
        </div>


        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">{this.getTranslations("User_BookingNextPage_VisitDuration")}</label>
          <OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingNextPage_VisitDurationTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
          <br />
          <div className={!this.state.isTimeSlotValid ? "stv-radio-buttons-wrapper is-invalid" : "stv-radio-buttons-wrapper"}>

            {this.props.timeSlots.map(time => time.id == this.state.timeSlotId ?
              <>
                <input onChange={(e) => this.OnTimeSlotChange(e)}
                  onBlur={this.form.handleBlurEvent}
                  key={time.id + 'slot'} type="radio"
                  className="stv-radio-button en-nn"
                  name="timeSlotId"
                  value={time.id} id={'button' + time.id} checked />
                <label key={'slotlabel' + time.id} htmlFor={'button' + time.id}>  {time.slotType}   </label>
              </> :
              <>
                <input onChange={(e) => this.OnTimeSlotChange(e)}
                  onBlur={this.form.handleBlurEvent} key={time.id + 'slot'} type="radio"
                  className="stv-radio-button en-nn" name="timeSlotId"
                  value={time.id} id={'button' + time.id} />
                <label key={'slotlabel' + time.id} htmlFor={'button' + time.id}>  {time.slotType}   </label>
              </>
            )}

          </div>
          <label className={this.state.isTimeSlotValid ? "disable" : "errorMessage"}>
            {this.state.isTimeSlotValid ? "" : this.state.timeSlotValidMessage}
          </label>
        </div>

        <div className="mb-3 row gx-2" >

          <div className="col-lg-5 col-md-12">

            <label htmlFor="" className="form-label">{this.getTranslations("User_BookingNextPage_StartDate")} </label>

            <OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingNextPage_StartDateTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
            <br />
            <div className={!this.state.isDateValid ? "form-group position-relative invaliddate" : "form-group position-relative"}>

              <DatePicker
                name="selectedDate"
                filterDate={isWeekday}
                selected={this.state.selectedDate}
                onChange={this.OnDateChange}
                highlightDates={this.state.bookedDates}
                className="form-control"
                minDate={moment(new Date()).toDate()}
                maxDate={moment(new Date()).add(this.state.dateRangeSelectionInMonths, 'M').toDate()}
                onChangeRaw={(e) => e.preventDefault()}
              />
              <FontAwesomeIcon className="calendar-icon" icon={faCalendarAlt} />

            </div>

            {
              this.state.showDateInvalidMessage ?
                <div style={{ color: 'red' }}>{this.getTranslations("User_BookingNextPage_DateNote")}</div> : ''
            }

          </div>



          <div className="col-lg-7 col-md-12 d-flex flex-column">

            <label htmlFor="" className="form-label"> &nbsp; </label>

            {
              this.state.showAlternateSlotForSameDate ? <>
                <button onClick={this.changeTimeSlot} className={this.state.timeSlotId == 1 ? (this.state.showAlternateSlotSelectionMessage ? "evening-slot form-group position-relative available-slot-invalid" : "evening-slot form-group position-relative") :
                  (this.state.showAlternateSlotSelectionMessage ? "morning-slot form-group position-relative available-slot-invalid" : "morning-slot form-group position-relative")} >
                  <div className="d-flex align-items-center">
                    {
                      this.state.timeSlotId == 1 ?
                        <img src={eveningImage} alt="" className="mx-2" />
                        :
                        <img src={morningImage} alt="" className="mx-2" />
                    }

                    {this.getTranslations("User_BookingNextPage_AlternateSlotMessage")}
                  </div>
                  <div class="slot-btn mx-2"> {this.state.showAlternateSlotText} </div>
                </button></> : ""
            }

            {
              this.state.showAlternateSlotSelectionMessage ?
                <div style={{ color: 'red' }}>{this.getTranslations("User_BookingNextPage_AlternateSlotSelection")}</div> : ''
            }
          </div>






          <label className="invalid-feedback">
            {this.state.errors.selectedDate ? this.state.errors.selectedDate : ""}

          </label>

        </div>

      </div>


      <div className="form-group clearfix row btn-fix-bottom">
        <div className="text-center main-btn-fix">
          <button onClick={this.PreviousButtonClick} className="form-wizard-previous-btn ">Previous</button>
          <input type="submit" value="Continue" className="form-wizard-next-btn " />
        </div>
      </div>
    </fieldset>
    </form>
      {
        this.state.isWarningOpen ?
          <WarningConsent infoMessage="End date should be greater than start date." isWarningOpen={this.state.isWarningOpen} onCloseModel={this.onCloseWarningModel} />
          : ""
      }

    </>

  }
}

const mapStateToProps = (state) => ({
  BookingInfo: state.BookingInfo,
  BookingInfoNext: state.BookingInfoNext,
  CurrentPage: state.CurrentPage,
  CustomersList: state.CustomersList,
  removeItemCustomersList: state.removeItemCustomersList,
  Translations: state.Translations
})

export default connect(mapStateToProps, { bookingInfoNext, currentPage, customersList, removeItemCustomersList })(Page2);
