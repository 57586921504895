import { Component } from "react";
import { Modal } from 'react-bootstrap'

class WarningConsent extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="modal show" style={{ display: 'block', position: 'initial' }}>
        <Modal show={this.props.isWarningOpen} onHide={this.props.onCloseModel} >
          <Modal.Header className="modal-footer justify-content-center">
            <h5 id="exampleModalLabel"> { this.props.infoHeader == undefined ? "Warning" : this.props.infoHeader  } </h5>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-12 w-100">
              <div className="mb-3">
                <h6 className="text-center"> {this.props.infoMessage} </h6>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-4">
              <button type="button" onClick={this.props.onCloseModel} className="btn outline-button btn-small" data-bs-dismiss="modal">OK</button>
            </div>
            <h6 className="text-center">{this.props.errorMessage}</h6>
          </Modal.Body>

        </Modal>

      </div>
    );
  }

}

export default WarningConsent;