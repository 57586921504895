import { Component } from "react";
import { Modal } from 'react-bootstrap'

class DownloadFile extends Component {
  constructor(props) {
    super(props);    
  }
  
  onDownload = (e) => {
      this.props.onDownload(e)
  }

    render() {
    return (
      <div className="modal show" style={{ display: 'none', position: 'initial' }}>
        <Modal show={this.props.isDownloadOpen} onHide={this.props.onCloseModel} >
          <Modal.Header className="modal-footer justify-content-center">
            <h5 id="exampleModalLabel">Download Confirmation</h5>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-12 w-100">
              <div className="mb-3">
                <h6 className="text-center"> Are you sure you want to download logs ?</h6>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-4">
              <button type="button" onClick={this.props.onCloseModel} className="btn outline-button btn-small" data-bs-dismiss="modal">No</button>
              <button type="button" onClick={this.onDownload} className="btn outline-button btn-small">Yes</button>
            </div>
            <h6 className="text-center">{this.props.errorMessage}</h6>
          </Modal.Body>

        </Modal>

      </div>
    );
  }

}

export default DownloadFile;