import { ADMINCURRENTPAGE } from './../Actions/Type'

let initialState = {
    currentPage: '1'
};

function AdminCurrentPageReducer(state = initialState, action) {
    if (action.type == ADMINCURRENTPAGE)
        return { ...state, currentPage: action.currentPage }
    else
        return state;
}


export default AdminCurrentPageReducer;