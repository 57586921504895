import { Component } from "react";
import { Modal } from 'react-bootstrap'

class CancelBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: '',
      isCommentError: false,
      isButtonDisabled : false
    }
  }

  oncommentschange = (e) => {
    this.setState({ comments: e.target.value })
    if (e.target.value.length >= 3)
      this.setState({ isCommentError: false })
  }

  cancelBooking = (e) => {
    if (this.state.comments.length >= 3)
    {
      this.setState({isButtonDisabled : true})
      this.props.onCancelConfirm(e, this.state.comments);      
    }      
    else
      this.setState({ isCommentError: true })
  }

  checkOnBlur = () => {
    if (this.state.comments.length >= 3)
      this.setState({ isCommentError: false })
    else
      this.setState({ isCommentError: true })
  }

  getTranslations(key) {   

    if(this.props.Translations.translations.filter(p => p.appKey == key)[0] !=null )
    return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
    return "";
  }

  render() {
    return (
      <div className="modal show" style={{ display: 'block', position: 'initial' }}>
        <Modal show={this.props.isCancelOpen} onHide={this.props.onCloseModel} >
          <Modal.Header className="modal-footer justify-content-center">
            <h5 id="exampleModalLabel"> {this.getTranslations("Admin_CancelPage_Heading")} </h5>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-12 w-100">
              <div className="mb-3">
                <h6 className="text-center"> {this.getTranslations("Admin_CancelPage_Message")} </h6>
              </div>
            </div>
           
            <div className="feedback-wrapper">
              <div className="mt-3">
                <textarea onChange={this.oncommentschange} value={this.state.comments}  className={this.state.isCommentError ? "form-control cancelBox is-invalid" : "form-control cancelBox"} placeholder={this.getTranslations("Admin_CancelPage_PlaceHolder")}
                  id="exampleFormControlTextarea1" name="comments" rows="3" onBlur={() =>this.checkOnBlur()} maxLength={150}></textarea>
              </div>
            </div>
            {
              this.state.isCommentError ?
                <div style={{ color: 'red' }}>Please provide atleast 3 characters.</div> : ''
            }


            <div className="d-flex justify-content-center mt-4">
              <button type="button" onClick={this.props.onCloseModel} className="btn outline-button btn-small" data-bs-dismiss="modal">{this.getTranslations("Admin_CancelPage_ButtonNo")}</button>
              <button type="button" disabled={this.state.isButtonDisabled} onClick={(e) => this.cancelBooking(e)} className="btn fill-button btn-small">{this.getTranslations("Admin_CancelPage_ButtonYes")}</button>
            </div>
          </Modal.Body>



        </Modal>

      </div>
    );
  }

}

export default CancelBooking;