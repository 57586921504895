import { Component } from "react";
import API from "../../Api";
import { connect } from "react-redux";
import ReactFormInputValidation from "react-form-input-validation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from "@fortawesome/fontawesome-free-solid";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Navigate } from "react-router-dom";
import { bookingInfo } from '../../Redux/Actions/BookingAction'
import { bookingInfoNext } from '../../Redux/Actions/BookingActionNext'
import { bookingProducts, clearBookingProducts } from '../../Redux/Actions/BookingProductsAction'
import { currentPage } from '../../Redux/Actions/CurrentPageAction'
import { customerLunch } from '../../Redux/Actions/CustomerLunchAction'
import { clearCustomersList } from '../../Redux/Actions/CustomersListAction'

class Page1 extends Component {

  constructor(props) {
    super(props);
    if (this.props.BookingInfo.page1.regionId != "") {
      this.getsubRegionData(this.props.BookingInfo.page1.regionId)
    }

    this.state = {
      redirect: '',
      subregions: [],
      regionId: this.props.BookingInfo.page1.regionId,
      opportunityId: this.props.BookingInfo.page1.opportunityId,
      isOpportunityIDValid: true,
      opportunityIdErrorMsg: "This field is required.",
      isRegionValid: true,
      customerTypeId: this.props.BookingInfo.page1.customerTypeId,
      visitTypeId: this.props.BookingInfo.page1.visitTypeId,
      isCustomerTypeValid: true,
      isOppotunitySizeValid: true,
      isVisitTypeValid: true,
      oppotunitySizeId: this.props.BookingInfo.page1.oppotunitySizeId,
      isDisabledOpportuntyId: this.props.BookingInfo.page1.customerTypeId && this.props.BookingInfo.page1.customerTypeId == 3 ? true : false,
      isDisableFields: this.props.BookingInfo.page1.disableFields,
      fields:
      {
        locationId: this.props.BookingInfo.page1.locationId,
        bookieName: this.props.BookingInfo.page1.bookieName,
        bookieEmail: this.props.BookingInfo.page1.bookieEmail,
        customerName: this.props.BookingInfo.page1.customerName,
        subregionId: this.props.BookingInfo.page1.subregionId,
        verticalId: this.props.BookingInfo.page1.verticalId
      },
      errors: {}
    };
    this.form = new ReactFormInputValidation(this);
    this.form.useRules({
      locationId: "required",
      //visitTypeId: "required",
      customerName: "required",
      subregionId: "required",
      verticalId: "required",
      //oppotunitySizeId: "required",
      //opportunityId: "required|validate_opportunity",
      //customerTypeId: "required"      
    });

    this.form.onformsubmit = (fields) => {
      if (this.state.regionId == "" || this.state.regionId == undefined) {
        this.setState({ isRegionValid: false })
      }
      if (!this.state.isDisableFields && (this.state.customerTypeId == "" || this.state.customerTypeId == undefined)) {
        this.setState({ isCustomerTypeValid: false })
      }
      if (this.state.visitTypeId == "" || this.state.visitTypeId == undefined) {
        this.setState({ isVisitTypeValid: false })
      }
      if (!this.state.isDisableFields && (this.state.oppotunitySizeId == "" || this.state.oppotunitySizeId == undefined)) {
        this.setState({ isOppotunitySizeValid: false })
      }
      if (!this.state.isDisableFields && !this.state.isDisabledOpportuntyId) {
        if (this.state.opportunityId == "" || this.state.opportunityId == undefined) {
          this.setState({ isOpportunityIDValid: false })
          return;
        }
      }

      if (Object.keys(this.state.errors).length === 0 &&
        this.state.fields.locationId != "" &&
        this.state.fields.verticalId != "" &&
        this.state.isRegionValid &&
        this.state.fields.subregionId != "" &&
        this.state.isVisitTypeValid != "" &&
        (!this.props.visitType.filter(p => p.id == this.state.visitTypeId)[0].disableFields ||
          (this.state.oppotunitySizeId != "" && this.state.isCustomerTypeValid && this.state.isOpportunityIDValid))) {
        this.props.bookingInfo({
          page1: {
            isvalidated : true,
            locationId: this.state.fields.locationId,
            visitTypeId: this.state.visitTypeId,
            bookieName: this.state.fields.bookieName,
            bookieEmail: this.state.fields.bookieEmail,
            customerName: this.state.fields.customerName,
            oppotunitySizeId: this.state.oppotunitySizeId,
            opportunityId: this.state.opportunityId,
            customerTypeId: this.state.customerTypeId,
            regionId: this.state.regionId,
            subregionId: this.state.fields.subregionId,
            verticalId: this.state.fields.verticalId,
            disableFields : this.state.isDisableFields,
            timezone: this.props.locations.filter(p => p.id == this.state.fields.locationId)[0].timeZone
          }
        });
        this.props.currentPage(2);
        this.props.sendData(2)
      }
      // Do you ajax calls here.
      // if (Object.keys(this.state.errors).length === 0 &&  this.state.fields.locationId != "" &&
      //   this.state.oppotunitySizeId != "" && this.state.visitTypeId != "" && 
      //   this.state.isCustomerTypeValid && this.state.isVisitTypeValid &&
      //   this.state.fields.subregionId != "" && this.state.isRegionValid &&
      //   this.state.fields.verticalId != "" && this.state.isOpportunityIDValid) {

      // }
    }
    ReactFormInputValidation.registerAsync('validate_email', function (useremail, attribute, req, passes) {
      setTimeout(() => {
        const regex = /[a-zA-Z]@(hcl|hcltech)\.com\b$/g;
        if (regex.test(useremail.toLowerCase()) === false) {
          passes(false, 'Invalid email. Please use HCL email id.');
        }
        else
          passes();

      }, 1000);

    });

    ReactFormInputValidation.registerAsync('validate_opportunity', function (opportunityId, attribute, req, passes) {
      setTimeout(() => {
        const regex = /^[a-zA-Z]{5}\d{6}\b$/g
        const regexwithhifen = /^[a-zA-Z]{5}\d{6}-\d{1,2}\b$/g;
        if (!opportunityId.includes('-') && regex.test(opportunityId) === false) {
          passes(false, 'Invalid opportunity Id. Please enter valid Id.');
        }
        else if (opportunityId.includes('-') && regexwithhifen.test(opportunityId) === false) {
          passes(false, 'Invalid opportunity Id. Please enter valid Id.');
        }
        else
          passes();
      }, 1000);

    });

    let message = ReactFormInputValidation.getMessages('en');
    message.required = 'This field is required.'
    ReactFormInputValidation.setMessages('en', message);

  }

  getTranslations(key) {

    if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
      return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
      return "";
  }

  renderTooltip = (title) => (
    <Tooltip placement='right'>{title}</Tooltip>
  );

  //handle Opportunity Type /Customer Type. Disable Opportunity ID for Prospective
  handleCustomerType = (e) => {
    if (e.target.value == 3)
      this.setState({
        customerTypeId: e.target.value,
        opportunityId: '',
        isOpportunityIDValid: true,
        isDisabledOpportuntyId: true,
        isCustomerTypeValid: true
      });
    else
      this.setState({
        customerTypeId: e.target.value,
        isDisabledOpportuntyId: false,
        isCustomerTypeValid: true
      })
  }

  handleVisitType = (e) => {

    if (e.target.value != '') {
      if (!this.props.visitType.filter(p => p.id == e.target.value)[0].disableFields) {
        this.setState({
          isDisableFields: true,
          isOppotunitySizeValid: true,
          isCustomerTypeValid: true,
          isOpportunityIDValid: true,
          customerTypeId: '',
          opportunityId: '',
          oppotunitySizeId: '',
          isVisitTypeValid: true,
          visitTypeId: e.target.value
        })

      }
      else {
        this.setState({
          isDisableFields: false,
          isVisitTypeValid: true,
          visitTypeId: e.target.value
        });
      }
    }
    else {
      this.setState({
        visitTypeId: e.target.value,
        isVisitTypeValid: false,
        isDisableFields: false
      })
    }

  }

  handleOpportunitySizeId = (e) => {
    if (e.target.value != "") {
      this.setState({
        oppotunitySizeId: e.target.value,
        isOppotunitySizeValid: true
      })
    }
    else {
      this.setState({
        oppotunitySizeId: e.target.value,
        isOppotunitySizeValid: false
      })
    }

  }

  handleOpportunityId = (e) => {
    if (e.target.value != "") {
      const regex = /^[a-zA-Z]{5}\d{6}\b$/g
      const regexwithhifen = /^[a-zA-Z]{5}\d{6}-\d{1,2}\b$/g;
      if (!e.target.value.includes('-') && regex.test(e.target.value) === false) {
        this.setState({ opportunityId: e.target.value, isOpportunityIDValid: false, opportunityIdErrorMsg: 'Invalid opportunity Id. Please enter valid Id.' })
      }
      else if (e.target.value.includes('-') && regexwithhifen.test(e.target.value) === false) {
        this.setState({ opportunityId: e.target.value, isOpportunityIDValid: false, opportunityIdErrorMsg: 'Invalid opportunity Id. Please enter valid Id.' })
      }
      else
        this.setState({ opportunityId: e.target.value, isOpportunityIDValid: true })
    }
    else
      this.setState({ opportunityId: e.target.value, isOpportunityIDValid: false, opportunityIdErrorMsg: 'This field is required.' })
  }

  getSubRegions = (e) => {
    let setFieldsSubRegionId = { ...this.state.fields }
    setFieldsSubRegionId.subregionId = "";
    if (e.target.value != "") {
      this.setState({
        isRegionValid: true,
        regionId: e.target.value,
        subregions: [],
        fields: { ...this.state.fields, subregionId: '' }
      });

      this.getsubRegionData(e.target.value);
    }
    else {
      this.setState({ regionId: e.target.value, isRegionValid: false, subregions: [], fields: { ...this.state.fields, subregionId: '' } });
    }
  }

  getsubRegionData(regionId) {
    if (regionId != null) {
      new API().getMasterSubRegions(regionId).then(result => {
        this.setState({ subregions: result.data });

      })
    }
  }

  getclassForcustomerType = (e) => {

    if (this.state.isDisableFields) {
      return "stv-radio-buttons-wrapper disabled"
    }
    else {
      if (this.state.isCustomerTypeValid) {
        return "stv-radio-buttons-wrapper"
      }
      else {
        return "stv-radio-buttons-wrapper is-invalid"
      }
    }
  }

  render() {

    if (!this.props.BookingInfo.page1.isvalidated) {
      return <Navigate to="/" />
    }
    if (this.props.BookingInfo.page1.bookieName == "" && this.props.BookingInfo.page1.bookieEmail == "") {
      return <Navigate to="/" />
    }
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <>

        <form onSubmit={this.form.handleSubmit}> <fieldset className="wizard-fieldset show" >

          <div className="form-group row">
            <div className="col-md-6 mob-margin">
              <label>{this.getTranslations("User_BookingPage_Location")}</label><OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingPage_LocationToolTip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
              <select name="locationId" value={this.state.fields.locationId} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} className={this.state.errors.locationId ? "form-control form-select is-invalid" : "form-control form-select"} aria-label="Default select example" data-attribute-name="Location" tabIndex={0}>
                <option id={0} key="optionloc" value="" defaultValue=""> Click to Select</option>
                {this.props.locations.map(loc =>
                  (<option value={loc.id} key={loc.id + 'loc'} id={loc.id} selected={loc.id == this.state.fields.locationId ? true : false}> {loc.locationName} </option>)
                )}
              </select>
              <label className="invalid-feedback">
                {this.state.errors.locationId ? this.state.errors.locationId : ""}
              </label>
            </div>

            <div className="col-md-6">
              <label> {this.getTranslations("User_BookingPage_VisitType")} </label><OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingPage_VisitTypeTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
              <select name="visitTypeId" value={this.state.visitTypeId} onChange={(e) => this.handleVisitType(e)} onBlur={this.form.handleBlurEvent} className={!this.state.isVisitTypeValid ? "form-control form-select is-invalid" : "form-control form-select"} aria-label="Default select example" data-attribute-name="VisitType" tabIndex={1}>
                <option id={0} key="optionvt" value="" defaultValue=""> Click to Select</option>
                {this.props.visitType.map(vt =>
                  (<option value={vt.id} key={vt.id + 'vt'} id={vt.id} selected={vt.id == this.state.visitTypeId ? true : false}> {vt.visitType} </option>)
                )}
              </select>
              <label className={this.state.isVisitTypeValid ? "disable" : "errorMessage"}>
                This field is required.
              </label>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-6 mob-margin">
              <label>{this.getTranslations("User_BookingPage_Customer")}</label><OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingPage_CustomerTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
              <input style={{ textTransform: 'capitalize' }} type="text" onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} placeholder="Enter customer name​" name="customerName" value={this.state.fields.customerName} className={this.state.errors.customerName ? "form-control is-invalid" : "form-control"} tabIndex={3} data-attribute-name="Customer Name" maxLength={40} />
              <label className="invalid-feedback">
                {this.state.errors.customerName ? this.state.errors.customerName : ""}
              </label>
            </div>
            <div className="col-md-6">
              <label>{this.getTranslations("User_BookingPage_Vertical")}</label><OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingPage_VerticalTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
              <select name="verticalId" onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} className={this.state.errors.verticalId ? "form-control form-select is-invalid" : "form-control form-select"} aria-label="Default select example" data-attribute-name="Vertical" tabIndex={0}>
                <option id={0} key="optionvertical" value=""> Click to Select</option>
                {this.props.verticals.map(vert => vert.id == this.state.fields.verticalId ?
                  (<option value={vert.id} key={vert.id + 'vertical'} id={vert.id} selected> {vert.verticalName} </option>) :
                  (<option value={vert.id} key={vert.id + 'vertical'} id={vert.id}> {vert.verticalName} </option>)
                )}

              </select>
              <label className="invalid-feedback">
                {this.state.errors.verticalId ? this.state.errors.verticalId : ""}
              </label>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-6 mob-margin">
              <label>{this.getTranslations("User_BookingPage_Geo")}</label><OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingPage_GeoTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
              <select name="regionId" value={this.state.regionId} onChange={this.getSubRegions} className={this.state.isRegionValid ? "form-control form-select" : "form-control form-select is-invalid"} aria-label="Default select example" data-attribute-name="Region" tabIndex={0}>
                <option id={0} key="optionregion" defaultValue value=""> Click to Select</option>
                {this.props.regions.map(reg =>
                  (<option value={reg.id} key={reg.id + 'region'} id={reg.id} selected={reg.id == this.state.regionId ? true : false}> {reg.regionName} </option>)
                )}

              </select>
              <label className={this.state.isRegionValid ? "disable" : "errorMessage"}>
                This field is required.
              </label>
            </div>
            <div className="col-md-6 ">
              <label>{this.getTranslations("User_BookingPage_SubRegion")}</label><OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingPage_SubRegionTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
              <select name="subregionId" value={this.state.fields.subregionId} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} className={this.state.errors.subregionId ? "form-control form-select is-invalid" : "form-control form-select"} aria-label="Default select example" data-attribute-name="SubRegion" tabIndex={0}>
                <option id={0} value="" key="optionsub" defaultValue > Click to Select</option>
                {this.state.subregions.map(subreg =>
                  (<option value={subreg.id} key={subreg.id + 'subregion'} id={subreg.id} selected={subreg.id == this.state.fields.subregionId ? true : false}> {subreg.subRegion} </option>)
                )}

              </select>
              <label className="invalid-feedback">
                {this.state.errors.subregionId ? this.state.errors.subregionId : ""}
              </label>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-12">
              <label>{this.getTranslations("User_BookingPage_OpportunityType")}</label><OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingPage_OpportunityTypeTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
            </div>

            <div className="col-md-12">
              <div className={this.getclassForcustomerType()}>

                {this.props.customerTypes.map(type =>
                  <>
                    <input disabled={this.state.isDisableFields} name="customerTypeId"
                      type="radio" key={type.id + 'customertype'}
                      checked={type.id == this.state.customerTypeId ? true : false}
                      onChange={(e) => this.handleCustomerType(e)}
                      className="stv-radio-button en-nn"
                      value={type.id}
                      id={'button' + type.id}
                      data-attribute-name="CustomerTypeId"
                    />
                    <label key={type.id + 'labelCustomerType'} htmlFor={'button' + type.id}> {type.customerType} </label></>
                )}

              </div>

              <label className={this.state.isCustomerTypeValid ? "disable" : "errorMessage"}>
                This field is required.
              </label>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-6 mob-margin">
              <label> {this.getTranslations("User_BookingPage_OpportunitySize")} </label> <OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingPage_OpportunitySizeTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
              <select disabled={this.state.isDisableFields}
                onChange={(e) => this.handleOpportunitySizeId(e)}
                onBlur={this.form.handleBlurEvent}
                name="oppotunitySizeId"
                className={!this.state.isOppotunitySizeValid ? "form-control form-select is-invalid" : "form-control form-select"}
                aria-label="Default select example" tabIndex={4} data-attribute-name="Opportunity Size">
                <option id={0} key="optionusd" value=""> Click to Select​</option>
                {this.props.OpportunitySizes.map(size => size.id == this.state.oppotunitySizeId ?
                  <option key={size.id + 'Opportunity'} value={size.id} id={size.id} selected> {size.opportunity} </option> :
                  <option key={size.id + 'Opportunity'} value={size.id} id={size.id}> {size.opportunity} </option>

                )}
              </select>

              <label className={this.state.isOppotunitySizeValid ? "disable" : "errorMessage"}>
                This field is required.
              </label>
            </div>

            <div className="col-md-6 ">
              <label>{this.getTranslations("User_BookingPage_OpportunityId")}</label> <OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingPage_OpportunityIdTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
              <input style={{ textTransform: 'uppercase' }}
                type="text"
                onChange={(e) => this.handleOpportunityId(e)}
                name="opportunityId"
                placeholder="e.g. OHCLC123456-7​"
                value={this.state.opportunityId}
                disabled={this.state.isDisableFields || this.state.isDisabledOpportuntyId}
                className={this.state.isOpportunityIDValid ? "form-control" : "form-control is-invalid"}
                tabIndex={5}
                data-attribute-name="Opportunity ID"
                maxLength={14}
              />
              <label className={this.state.isOpportunityIDValid ? "disable" : "errorMessage"}>
                {this.state.isOpportunityIDValid ? "" : this.state.opportunityIdErrorMsg}
              </label>
            </div>

          </div>



          <div className="form-group clearfix row btn-fix-bottom">
            <div className="text-center main-btn-fix">
              <input type="submit" value="Continue" className="form-wizard-next-btn float-right " tabIndex={7} />
              {/* <button onClick={this.OnContinueButtonClick} className="form-wizard-next-btn float-right">Continue <i className="fa fa-angle-right"></i></button> */}
            </div>
          </div>
        </fieldset>
        </form> </>)
  }
}

const mapStateToProps = (state) => ({
  BookingInfo: state.BookingInfo,
  BookingInfoNext: state.BookingInfoNext,
  CurrentPage: state.CurrentPage,
  BookingProducts: state.BookingProducts,
  CustomerLunch: state.CustomerLunch,
  CustomersList: state.CustomersList,
  Translations: state.Translations
})

export default connect(mapStateToProps, { bookingInfo, clearBookingProducts, bookingInfoNext, currentPage, clearCustomersList, customerLunch, bookingProducts })(Page1);