import { ADMINLEFTMENU } from './../Actions/Type'

let initialState = {
    selection: '',
    selectionLogs: '',
    selectedSubMenu: ''
};

function AdminLeftMenuReducer(state = initialState, action) {
    if (action.type == ADMINLEFTMENU)
        return { ...state, selection: action.selection.selection, selectedSubMenu: action.selection.selectedSubMenu, selectionLogs: action.selection.selectionLogs }
    else
        return state;
}


export default AdminLeftMenuReducer;