import { Component } from "react";
import API from "../../Api";
import FluidLogo from '../../Images/logo-fluid-studio.png'
import BackgroundImage from '../../Images/bg.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/fontawesome-free-solid";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import ReactPaginate from 'react-paginate'
import GuestDetails from '../Admin/GuestDetails';
import Loader from "../Loader";
import { connect } from "react-redux";
import { bookingInfo } from '../../Redux/Actions/BookingAction'
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import { DateRangePicker } from 'rsuite';
class BookingsListUser extends Component {

  constructor(props) {
    super(props);

    this.state = {
      bookings: [],
      regions: [],
      isLoading: true,
      selectedFilter: localStorage.getItem("SelectedFilterUser") == null ? 'Confirmed booking' : localStorage.getItem("SelectedFilterUser"),
      selectedFilterId: localStorage.getItem("SelectedFilterIdUser") == null ? 2 : localStorage.getItem("SelectedFilterIdUser"),
      selectedId: '',
      selectedName: '',
      isOpen: false,
      isCancelOpen: false,
      isGuestPopupOpen: false,
      selectedRegion: '',
      vertical: '',
      lunchRequired: '',
      isDateValid: true,
      selectedDate: new Date(),
      value: [],
      startDateFilterValue: null,
      endDateFilterValue: null,
      selectedDetails: {
      },
      activePage: 1,
      TotalRecords: 0,
      RecordPerPage: 0
    };
  }

  onGuestActionClick = (e) => {
    this.setState({
      selectedId: e.target.id,
      selectedDetails: this.state.bookings.filter(p => p.id == e.target.id)[0],
      isOpen: false,
      isCancelOpen: false,
      isGuestPopupOpen: true
    });

  }

  onCloseModel = (e) => {
    this.setState({ isOpen: false, isCancelOpen: false, isGuestPopupOpen: false });
  }

  OnDropdownChange = (e) => {
    localStorage.setItem("SelectedFilterUser", e.target.name);
    localStorage.setItem("SelectedFilterIdUser", e.target.id);
    this.setState({ startDateFilterValue: null, endDateFilterValue: null, selectedFilter: e.target.name, selectedFilterId: e.target.id, activePage: 1, value: [] });
    this.getBookings(e.target.id, 1, this.props.BookingInfo.page1.bookieEmail);

  }

  OnPageClick = (e) => {
    this.setState({ activePage: e.selected + 1 });
    if (this.state.startDateFilterValue == null && this.state.endDateFilterValue == null) {
      this.getBookings(this.state.selectedFilterId, e.selected + 1, this.props.BookingInfo.page1.bookieEmail);
    }
    else {
      this.getDateFilteredBookings(this.state.selectedFilterId, e.selected + 1, this.state.startDateFilterValue, this.state.endDateFilterValue, this.props.BookingInfo.page1.bookieEmail);
    }
  }

  async getBookings(filterId, pageno, email) {
    await new API().getFilterBookingsForUser(filterId, pageno, email).then(result => {
      this.setState({
        bookings: result.data.bookings,
        isLoading: false,
        regions: result.data.regions,
        TotalRecords: result.data.totalCount,
        RecordPerPage: result.data.recordPerPage,
        isOpen: false,
        isCancelOpen: false,
        isGuestPopupOpen: false
      });
    });
  }

  async componentDidMount() {

    if (localStorage.getItem("SelectedFilterIdUser") == null) {
      await new API().getFilterBookingsForUser(2, this.state.activePage, this.props.BookingInfo.page1.bookieEmail).then(result => {

        this.setState({
          bookings: result.data.bookings,
          isLoading: false,
          regions: result.data.regions,
          TotalRecords: result.data.totalCount,
          RecordPerPage: result.data.recordPerPage
        });
      });
    }
    else {
      this.getBookings(this.state.selectedFilterId, 1, this.props.BookingInfo.page1.bookieEmail);
    }

  }



  OnDateChange = (value) => {
    this.setState({ startDate: value != null ? value[0] : null, endDate: value != null ? value[1] : null, value: value });

    if (value == null) {
      this.getBookings(this.state.selectedFilterId, 1, this.props.BookingInfo.page1.bookieEmail);
    }
  }

  handleSubmitClick = (value) => {
    let startDateValue = moment(value[0]).format('YYYY-MM-DD')
    let endDateValue = moment(value[1]).format('YYYY-MM-DD hh:mm')
    this.setState({ startDateFilterValue: startDateValue, endDateFilterValue: endDateValue })
    this.getDateFilteredBookings(this.state.selectedFilterId, 1, startDateValue, endDateValue, this.props.BookingInfo.page1.bookieEmail);
  }

  async getDateFilteredBookings(filterId, pageno, startDateVal, endDateVal, email) {
    await new API().getDateFilteredBookingsForUser(filterId, pageno, startDateVal, endDateVal, email).then(result => {
      this.setState({
        bookings: result.data.bookings,
        isLoading: false,
        regions: result.data.regions,
        TotalRecords: result.data.totalCount,
        RecordPerPage: result.data.recordPerPage,
        isOpen: false,
        isCancelOpen: false,
        isGuestPopupOpen: false
      });
    });
  }

  getTranslations(key) {

    if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
      return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
      return "";
  }

  render() {

    return <>
      <div id="wrapper">

        <div id="sidebar">
          <img src={BackgroundImage} width="100%" alt="" />
        </div>

        <div className="content-area">
          <section className="wizard-section booking-area px-1">
            <h2 className="text-center"><img src={FluidLogo} width="200" alt="Fluid Studio" /></h2>


            <div className="row no-gutters">

            </div>

            <div className="row">

              <a href="/fluidbooking" value="My Bookings" className="form-wizard-next-btn float-right mybookingbackbutton"  >
                <FontAwesomeIcon size="lg" icon={faArrowLeftLong} ></FontAwesomeIcon>
              </a>


              <div className="col-md-12">
                <h6 > {this.getTranslations("User_BookingListPage_Heading")}  </h6>
              </div>

              <div className="col-md-12 mb-3 d-flex justify-content-between align-items-center customFilter">

                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa fa-filter" aria-hidden="true">
                      <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
                    </i>
                    <span className="px-2"> {this.state.selectedFilter} </span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    {/* <li><button id="0" onClick={this.OnDropdownChange} className="dropdown-item" name="All bookings" >ALL</button></li> */}
                    <li><button id="1" onClick={this.OnDropdownChange} className="dropdown-item" name="Pending confirmation" >Pending confirmation</button></li>
                    <li><button id="2" onClick={this.OnDropdownChange} className="dropdown-item" name="Confirmed booking">Confirmed booking</button></li>
                    <li><button id="3" onClick={this.OnDropdownChange} className="dropdown-item" name="Cancelled booking">Cancelled booking</button></li>
                    {/* <li><button id="4" onClick={this.OnDropdownChange} className="dropdown-item" name="Concluded">Concluded</button></li> */}
                  </ul>
                </div>
                <div className="d-flex w-100">
                  <DateRangePicker
                    size="sm"
                    name="selectedDate"
                    placeholder="Select Booking Date Range"
                    onChange={this.OnDateChange}
                    // onChangeRaw={(e) => e.preventDefault()}
                    value={this.state.value}
                    showOneCalendar={true}
                    onOk={this.handleSubmitClick}
                    ranges={[]}
                    character=' - '
                    format='dd/MMM/yyyy'
                  />
                </div>
                {
                  this.state.bookings.length > 0 ?

                    <ReactPaginate
                      forcePage={this.state.activePage-1}
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={this.OnPageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      pageCount={(this.state.TotalRecords > this.state.RecordPerPage) ? Math.ceil(this.state.TotalRecords / this.state.RecordPerPage) : 1}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      className="cust-pagination mx-2"
                    /> : ""
                }


                {/* <ul className="notation">
                    <li> <span className="confirmed"> </span> Confirmed booking </li>
                    <li> <span className="cancel"> </span> Cancelled booking </li>
                    <li> <span className="tb-confirm"> </span> Pending confirmation </li>
                    <li> <span className="concluded"> </span> Concluded </li>
                </ul> */}
              </div>
              {
                this.state.isLoading ? <Loader /> :

                  <div className="col-md-12 text-center table-responsive min-h">

                    {
                      this.state.bookings.length == 0 ? <div className="nobookingtext30">No Booking Found</div> : ""
                    }

                    <Table id="example"  >

                      <Tbody>
                        {this.state.bookings.map((item, i) => {
                          return [
                            <Tr key={item.id}>
                              <Td>{this.getTranslations("User_BookingListPage_BookingId")} : <span className="date-bold"> {item.displayId} </span>
                                <div> {this.getTranslations("User_BookingListPage_BookieName")} : <span> {item.bookieName} </span></div>
                              </Td>
                              <Td>
                                <div> {this.getTranslations("User_BookingListPage_OrgName")} : <span> {item.customerName} </span></div>
                                <div> {this.getTranslations("User_BookingListPage_Email")} : <span> {item.bookieEmail}  </span> </div>
                               
                              </Td>
                              <Td>
                              <div> {this.getTranslations("User_BookingListPage_StartDate")} : <span className="date-bold"> {moment.tz(moment.utc(item.bookingDate),item.timezone).format('MMM Do YYYY, HH:mm A')}   </span></div>

                                <div> {this.getTranslations("User_BookingListPage_EndDate")} : <span className="date-bold"> {moment.tz(moment.utc(item.bookingEndDate),item.timezone).format('MMM Do YYYY, HH:mm A')}    </span></div>

                              </Td>

                              <Td>
                              <div> {this.getTranslations("User_BookingListPage_VisitType")} : <span> {item.visitType} </span> </div>
                               
                                <div> {this.getTranslations("User_BookingListPage_SizeOfVisitParty")} : <a onClick={this.onGuestActionClick} id={item.id} className="edit date-bold" data-bs-toggle="modal" data-bs-target="#guestModal"><span id={item.id}> {item.visitingPartySize} </span> </a> </div>
                              </Td>

                              <Td>
                               <div> {this.getTranslations("User_BookingListPage_Lunch")} : <span> {item.lunchRequired ? 'Yes' : 'No'} </span></div>
                               <div> {this.getTranslations("User_BookingListPage_Location")} : <span className="date-bold"> {item.location} </span></div>
                             
                              </Td>

                              <Td>


                              </Td>
                            </Tr>
                          ]

                        })}

                      </Tbody>
                    </Table>

                  </div>}
            </div>

          </section>

        </div>
      </div>
      {this.state.isGuestPopupOpen && <GuestDetails Translations={this.props.Translations} isGuestPopupOpen={this.state.isGuestPopupOpen} onCloseModel={this.onCloseModel} customers={this.state.selectedDetails.customers} />}

    </>
  }
}
const mapStateToProps = (state) => ({
  BookingInfo: state.BookingInfo,
  Translations: state.Translations
})

export default connect(mapStateToProps, { bookingInfo })(BookingsListUser);