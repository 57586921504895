import {CUSTOMERS_LIST, CLEAR_CUSTOMERS_LIST,EDIT_CUSTOMERS_LIST,REMOVE_ITEM_CUSTOMERS_LIST } from './../Actions/Type'

let initialState = {
    customers: []
};

function CustomerListReducer(state = initialState, action) {
    switch (action.type) {
        case CUSTOMERS_LIST:
            let newCustomers = state.customers.filter(p => p.index != action.customers.index);
            newCustomers = newCustomers.concat(action.customers);
            return { ...state, customers: newCustomers }
        case CLEAR_CUSTOMERS_LIST:
            return { ...state, customers: [] }
        case EDIT_CUSTOMERS_LIST:                          
            return { ...state, customers:state.customers.map((customer)=>customer.index === action.customer.index ?{ ...customer, customerName: action.customer.customerName,customerRole: action.customer.customerRole,customerEmail: action.customer.customerEmail,isValidated: action.customer.isValidated}:customer)}
        case REMOVE_ITEM_CUSTOMERS_LIST:            
            return {...state,customers: state.customers.splice(action.index,1)}
        default:
            return state;
    }
}

export default CustomerListReducer;