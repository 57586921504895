import { Component } from "react";
import { Navigate } from "react-router-dom";
import API from "../../Api";
import withRouter from "../WithRouter";
import BackgroundImage from '../../Images/bg.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import ArrowImage from '../../Images/arrow.png'
import { connect } from "react-redux";
import { loginUserInfoAdmin } from '../../Redux/Actions/AdminAuthenticationActions'

class CustomerListFeedBack extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            redirect: ''

        };
    }

    OnFeedbackButtonClick = (e) => {
        if (e.target.id != '') {
            this.setState({ redirect: "/feedback/" + this.props.params.id + "/" + e.target.id });
        }
    }

    OnBackClick = (e) => {

        if (localStorage.getItem('feedbackFromList') == 'true') {
            localStorage.setItem('feedbackFromList', false);
            this.setState({
                redirect: "/bookingList"
            });

        }
        else {
            this.setState({ redirect: "/bookingdetails/" + this.props.params.id });
        }
    }

    async componentDidMount() {
        await new API().getBookingCustomers(this.props.params.id).then(result => {
            this.setState({ customers: result.data });
        });

    }

    getTranslations(key) {   

        if(this.props.Translations.translations.filter(p => p.appKey == key)[0] !=null )
        return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
        return "";
      }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        if (this.props.AdminAuthentication.isLogin) {
            return <>

                <div id="wrapper">

                    <div id="sidebar">
                        <img src={BackgroundImage} width="100%" alt="" />
                    </div>

                    <div className="content-area">
                        <section className="wizard-section booking-area">


                            <div className="row no-gutters mt-4">
                                <h6 className="pb-1"> {this.getTranslations("Admin_CustomerListPage_Heading")} </h6>
                            </div>

                            <div className="row">


                                {this.state.customers.map((customer, i) => {
                                    return [
                                        <div key={customer.id} className={customer.isFeedbackDone ? "col-md-12 vc-feedback-disable" : "col-md-12 vc-feedback"} >
                                            <button onClick={this.OnFeedbackButtonClick} key={customer.id}
                                                id={customer.id}> {customer.customerName}
                                                <i id={customer.id} className="fa fa-angle-right float-end">
                                                    {
                                                        customer.isFeedbackDone ?
                                                            <FontAwesomeIcon id={customer.id} icon={faCircleCheck}></FontAwesomeIcon>
                                                            : <img width={11} id={customer.id} src={ArrowImage}></img>
                                                    }

                                                </i><br />
                                                <span id={customer.id}> {customer.isFeedbackDone ? this.getTranslations("Admin_CustomerListPage_FeedbackDone") : this.getTranslations("Admin_CustomerListPage_Feedback")} </span>
                                            </button>
                                        </div>
                                    ]
                                })}




                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <button onClick={this.OnBackClick} className="outline-button" > Back</button>
                                </div>
                            </div>

                        </section>
                    </div>

                </div>

                <footer className="text-center">
                Copyright © {new Date().getFullYear()} HCL Technologies Limited
                </footer>


            </>
        }
        else {
            return <Navigate replace to="/admin" />;
        }
    }
}

const mapStateToProps = (state) => ({
    AdminAuthentication: state.AdminAuthentication,
    Translations : state.Translations
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(withRouter(CustomerListFeedBack));