import { Component } from "react";
import ReactFormInputValidation from "react-form-input-validation";
import { editCustomersList } from '../../Redux/Actions/CustomersListAction'
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

class EditCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            custnum: this.props.customer.index,
            fields: {
                name: this.props.customer.customerName,
                role: this.props.customer.customerRole,
                email: this.props.customer.customerEmail
            },
            errors: {},
        }
        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
            name: "required",
            role: "required",
            email: "required|email"
        });

        this.form.onformsubmit = (fields) => {

            if (Object.keys(this.state.errors).length === 0) {
                let customer = {
                    customerName: this.state.fields.name,
                    customerEmail: this.state.fields.email,
                    customerRole: this.state.fields.role,
                    index: this.state.custnum,
                    isValidated: true
                };
                this.props.editCustomersList(customer);
                this.props.cancel();
            }
        }

        let message = ReactFormInputValidation.getMessages('en');
        message.required = 'This field is required.'
        ReactFormInputValidation.setMessages('en', message);
    }

    handleCancel = (e) => {
        e.preventDefault();
        this.props.cancel();
    }

    exceptThisSymbols(key) {
        return !!((key.keyCode > 64 && key.keyCode < 91) || (key.keyCode > 96 && key.keyCode < 123) || key.keyCode == 8 || key.keyCode == 32 || key.keyCode == 9 || key.keyCode == 37 || key.keyCode == 39);
    }

    getTranslations(key) {   

        if(this.props.Translations.translations.filter(p => p.appKey == key)[0] !=null )
        return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
        return "";
      }

    render() {

        return (
            <div className="modal show" style={{ display: 'block', position: 'initial' }}>
                <Modal show={this.props.showPopup} onHide={this.props.cancel} 
                >

                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.getTranslations("User_EditVisitingDelegation_Heading")}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form className="form-wizard px-0" onSubmit={this.form.handleSubmit}>
                            <div className="col-md-12 w-100">
                                <div className="mb-3">
                                    <label className="form-label">{this.getTranslations("User_EditVisitingDelegation_Name")}</label>
                                    <input style={{ textTransform: 'capitalize' }} value={this.state.fields.name} name="name" onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} className={this.state.errors.name ? "form-control is-invalid" : "form-control"} aria-describedby="emailHelp" onKeyDown={e => !this.exceptThisSymbols(e) && e.preventDefault()} maxLength={40} />
                                    <label className="invalid-feedback">
                                        {this.state.errors.name ? this.state.errors.name : ""}
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">{this.getTranslations("User_EditVisitingDelegation_Role")}</label>
                                    <input value={this.state.fields.role} name="role" onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} className={this.state.errors.role ? "form-control is-invalid" : "form-control"} aria-describedby="emailHelp" maxLength={25} />
                                    <label className="invalid-feedback">
                                        {this.state.errors.role ? this.state.errors.role : ""}
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">{this.getTranslations("User_EditVisitingDelegation_Email")}</label>
                                    <input value={this.state.fields.email} name="email" onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="text" className={this.state.errors.email ? "form-control is-invalid" : "form-control"} aria-describedby="emailHelp" />
                                    <label className="invalid-feedback">
                                        {this.state.errors.email ? this.state.errors.email : ""}
                                    </label>
                                </div>
                            </div>
                            <br />
                            <div className="col-md-12 d-flex justify-content-center">
                            <button className="outline-button btn-small" onClick={(e) => this.handleCancel(e)}>Cancel</button>
                            <button className="fill-button btn-small" type="submit" >Save</button> &nbsp;
                            </div>
                            
                        </form>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <button className="carousel-control-next" type="submit" >Edit</button>
                        <button className="carousel-control-prev" onClick={(e) => this.handleCancel(e)}>Cancel</button>
                    </Modal.Footer> */}
                </Modal>
            </div>
        )

    }
}

const mapStateToProps = (state) => ({
    CustomersList: state.CustomersList,
    Translations : state.Translations
})

export default connect(mapStateToProps, { editCustomersList })(EditCustomer);
