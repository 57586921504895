import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import logoImg from "../../Images/logo-fluid-studio.png"
import ReactFormInputValidation from "react-form-input-validation";
import API from "../../Api";
import { loginUserInfoAdmin } from '../../Redux/Actions/AdminAuthenticationActions'
import { connect } from "react-redux";
import WarningConsent from "../AdminDashboard/WarningConsent";

class AdminLogin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: null,
            fields: {
                username: '',
                password: ''
            },
            isWarningOpen : false,
            warningMessage : '',
            errors: {},
        }
        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
            username: "required",
            password: "required"
        });

        this.form.onformsubmit = (fields) => {
            if (Object.keys(this.state.errors).length === 0) {
                this.OnLoginClick();
            }
        }
        let message = ReactFormInputValidation.getMessages('en');
        message.required = 'This field is required.'
        ReactFormInputValidation.setMessages('en', message);
    }
    onCloseWarningModel = (e) =>{
        this.setState({isWarningOpen : false})
    }
    OnLoginClick = () => {
        let loginInfo = {
            username: this.state.fields.username,
            Password: this.state.fields.password
        };

        new API().VerifyMasterUser(loginInfo).then(result => {
            if (result.data.result) {
                this.props.loginUserInfoAdmin({ Username: loginInfo.username, isLogin: true , role : result.data.role });
                this.setState({ redirect: "/bookingList" });
            }
            else {
                this.setState({isWarningOpen : true, warningMessage : result.data.errorMessage})
            }
        });
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return <> <div className="login-wrapper">
            <div className="login-wrap">
                <div className="login-logo">  <img src={logoImg} className="img-fluid" alt="" /> </div>
                <div className="login-header">

                    DWP Fluid Studio Admin Login</div>
                <form onSubmit={this.form.handleSubmit}>
                    <div className="form-outline mb-4">
                        <input type="text" name="username" id="username" value={this.state.fields.username} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} className={this.state.errors.username ? "form-control is-invalid" : "form-control"} placeholder="Username" />
                        <label className="invalid-feedback">
                            {this.state.errors.username ? this.state.errors.username : ""}
                        </label>
                    </div>

                    <div className="form-outline mb-4">
                        <input type="password" name="password" id="passwrod" value={this.state.fields.password} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} className={this.state.errors.password ? "form-control is-invalid" : "form-control"} placeholder="Password" />
                        <label className="invalid-feedback">
                            {this.state.errors.password ? this.state.errors.password : ""}
                        </label>
                    </div>

                    <div className="text-end">
                        <button type="submit" className="w-100 fill-button" >Sign in</button>
                    </div>
                </form>
            </div>
        </div>
        {
        this.state.isWarningOpen ?
          <WarningConsent infoMessage={this.state.warningMessage} isWarningOpen={this.state.isWarningOpen} onCloseModel={this.onCloseWarningModel} />
          : ""
      }
        </>
    }
}

const mapStateToProps = (state) => ({
    AdminAuthentication: state.AdminAuthentication,
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(AdminLogin);