import { faEdit, faPlus, faTrashAlt, faUndo } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Component } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { connect } from "react-redux";
import API from "../../../Api";
import DeleteConsent from "../DeleteConsent";
import { loginUserInfoAdmin } from '../../../Redux/Actions/AdminAuthenticationActions'
import AddFeedbackQuestion from "../ConfigurationsPopups/AddFeedbackQuestion";
import RestoreConsent from "../RestoreConsent";
import WarningConsent from "../WarningConsent";

class MasterFeedbackQuestions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      masterQuestion: [],
      selectedType: 0,
      isConsentOpen: false,
      selectedQuestion: {},
      errorMessage: '',
      isPopupOpen: false,
      isEditClick: false,
      showToast: false,
      toastMessage: '',
      toastHeader: '',
      isDeletedToast: false,
      showDeletedTable: 0,
      isRestoreOpen: false,
      isWarningOpen: false
    };
  }

  OnFeedbackTypeSelection = (e) => {

    this.setState({ selectedType: e.target.value });
    if (e.target.value != "") {
      if (this.state.showDeletedTable == "0") {
        new API().getMasterFeedbackQuestions(e.target.value).then(result => {
          this.setState({ masterQuestion: result.data })
        })
      }
      else {
        new API().getDeletedMasterFeedbackQuestions(e.target.value).then(result => {
          this.setState({ masterQuestion: result.data })
        })
      }
    }
    else {
      this.setState({ masterQuestion: [] })
    }



  }

  onDeleteClick = (e) => {
    let id = e.target.id;
    if (e.target.nodeName == "path") {
      id = e.target.parentNode.id;
    }
    this.setState({ isConsentOpen: true, isWarningOpen: false, isRestoreOpen: false, isPopupOpen: false, errorMessage: '', selectedQuestion: this.state.masterQuestion.filter(p => p.id == id)[0] });
  }

  onEditClick = (e) => {
    let id = e.target.id;
    if (e.target.nodeName == "path") {
      id = e.target.parentNode.id;
    }
    this.setState({ isEditClick: true, isWarningOpen: false, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: true, errorMessage: '', selectedQuestion: this.state.masterQuestion.filter(p => p.id == id)[0] });
  }

  onAddClick = (e) => {

    if (this.state.selectedType != 0) {
      this.setState({ isEditClick: false, isWarningOpen: false, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: true, errorMessage: '', selectedQuestion: null });
    }
    else {
      this.setState({ isWarningOpen: true });
    }
  }

  onCloseModel = (e) => {
    this.setState({ isEditClick: false, isWarningOpen: false, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: false });
  }

  onConfirm = (e) => {
    let toastMessage = '';
    let questionToAdd = {};
    if (this.state.selectedQuestion == null) {
      toastMessage = 'Feedback Question added successfully.';
      questionToAdd = {
        Question: e,
        FeedbackType: this.state.selectedType,
        createdBy: this.props.AdminAuthentication.Username,
        isDisplay: true

      };
    }
    else {

      toastMessage = 'Feedback Question updated successfully.';
      questionToAdd = {
        id: this.state.selectedQuestion.id,
        Question: e,
        FeedbackType: this.state.selectedType,
        ModifiedBy: this.props.AdminAuthentication.Username,
      };

    }

    if (this.state.selectedQuestion == null || (this.state.selectedQuestion !=null && this.state.selectedQuestion.question != e.trim())) {
      new API().addUpdateMasterFeedbackQuestions(questionToAdd).then(result => {
        if (result.data.isSuccess) {
          this.setState({ showDeletedTable: 0, showToast: true, isRestoreOpen: false, isDeletedToast: false, toastHeader: 'Success', toastMessage: toastMessage, isPopupOpen: false, isEditClick: false });
          new API().getMasterFeedbackQuestions(this.state.selectedType).then(result1 => {
            this.setState({ masterQuestion: result1.data, isWarningOpen: false, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: false })
          })
        }
        else {
          if (result.errorMessage != "") {
            this.setState({ errorMessage: result.data.errorMessage });
          }
        }
  
  
      });
    }
    else {
      this.setState({ showDeletedTable: 0, isRestoreOpen: false, isDeletedToast: false, isPopupOpen: false, isEditClick: false });
       
    }
    

  }

  onDeleteConfirm = (e) => {

    let deleteRequest = {
      id: this.state.selectedQuestion.id,
      DeletedBy: this.props.AdminAuthentication.Username
    }

    new API().deleteMasterFeedbackQuestionl(deleteRequest).then(result => {

      if (result.data.isSuccess) {
        this.setState({ showToast: true, isRestoreOpen: false, isWarningOpen: false, isDeletedToast: true, toastHeader: 'Deleted', toastMessage: 'Feedback Question deleted successfully.', isPopupOpen: false, isEditClick: false });

        new API().getMasterFeedbackQuestions(this.state.selectedType).then(result1 => {
          this.setState({ masterQuestion: result1.data, isWarningOpen: false, isRestoreOpen: false, isConsentOpen: false, isPopupOpen: false })
        })
      }
      else {
        if (result.errorMessage != "") {
          this.setState({ errorMessage: result.data.errorMessage });
        }

      }
    })

  }


  OnCloseToast = (e) => {
    this.setState({ showToast: !this.state.showToast });
  }

  onRestoreConfirm = (e) => {

    let restoreRequest = {
      id: this.state.selectedQuestion.id,
      ModifiedBy: this.props.AdminAuthentication.Username
    }

    new API().restoreMasterFeedbackQuestion(restoreRequest).then(result => {
      if (result.data.isSuccess) {
        this.setState({ showToast: true, isDeletedToast: false, isWarningOpen: false, toastHeader: 'Restored', toastMessage: 'Feedback Question restored successfully.', isPopupOpen: false, isEditClick: false });

        if (this.state.showDeletedTable == "0") {
          new API().getMasterFeedbackQuestions(this.state.selectedType).then(result1 => {
            this.setState({ masterQuestion: result1.data, isWarningOpen: false, isPopupOpen: false, isConsentOpen: false });
          });
        }
        else {
          new API().getDeletedMasterFeedbackQuestions(this.state.selectedType).then(result => {
            this.setState({ masterQuestion: result.data, isWarningOpen: false, isConsentOpen: false, isPopupOpen: false, isRestoreOpen: false })
          })
        }
      }
      else {
        if (result.errorMessage != "") {
          this.setState({ errorMessage: result.data.errorMessage });
        }

      }
    })

  }

  OnRadioChanged = (e) => {
    this.setState({ showDeletedTable: e.target.value });
    if (this.state.selectedType != 0) {
      if (e.target.value == 0) {
        new API().getMasterFeedbackQuestions(this.state.selectedType).then(result1 => {
          this.setState({ masterQuestion: result1.data, isWarningOpen: false, isPopupOpen: false, isConsentOpen: false });
        });
      }
      else {
        new API().getDeletedMasterFeedbackQuestions(this.state.selectedType).then(result => {
          this.setState({ masterQuestion: result.data, isWarningOpen: false, isConsentOpen: false, isPopupOpen: false })
        })
      }
    }

  }

  onRestoreClick = (e) => {
    let id = e.target.id;
    if (e.target.nodeName == "path") {
      id = e.target.parentNode.id;
    }
    this.setState({ isConsentOpen: false, isWarningOpen: false, isRestoreOpen: true, isPopupOpen: false, errorMessage: '', selectedQuestion: this.state.masterQuestion.filter(p => p.id == id)[0] });
  }

  render() {



    return <>

      <ToastContainer position="bottom-end">

        <Toast delay={10000} autohide show={this.state.showToast} onClose={this.OnCloseToast}>
          <Toast.Header>
            <strong className="me-auto"> {this.state.toastHeader}</strong>
          </Toast.Header>
          <Toast.Body className={this.state.isDeletedToast ? "bg-danger text-white" : "bg-success text-white"}> {this.state.toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <div className="d-flex justify-content-between align-items-center mob-colls">
        <h2> Master Feedback Questions </h2>
        <div className="d-flex">
          <div className="form-check form-check-cust">
            <input type="radio" className="form-check-input" id="active" checked={this.state.showDeletedTable == "0" ? true : false} value="0" onChange={this.OnRadioChanged} name="radio1" /> <label htmlFor="active" className="form-check-label"> Active</label>
          </div>
          <div className="form-check form-check-cust">
            <input type="radio" className="form-check-input" id="delete" checked={this.state.showDeletedTable == "1" ? true : false} value="1" onChange={this.OnRadioChanged} name="radio1" /> <label htmlFor="delete" className="form-check-label"> Archive</label>
          </div>
          <button onClick={this.onAddClick} className="add-new me-2"><FontAwesomeIcon icon={faPlus} />  </button>
        </div>
      </div>

      <div className="table-responsive bg-white">

        <div className="mb-2">
          <label>Select Question Type</label>
          <select className="form-control form-select" onChange={this.OnFeedbackTypeSelection}>
            <option id={0} defaultValue="" value=""> Click to Select</option>
            <option id={1} value="1"> Bookie</option>
            <option id={2} value="2"> Customer</option>

          </select>
        </div>

        {
          this.state.showDeletedTable == "0" ?
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Feedback Question</th>
                  <th scope="col">Created By</th>
                  <th scope="col">Created Date</th>
                  <th scope="col">Updated By</th>
                  <th scope="col">Updated Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.masterQuestion.map((item, i) => {
                  return [
                    <tr key={item.id}>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.question}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.createdBy}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{moment(moment.utc(item.createdDate).toDate()).local().format('MMMM Do YYYY, HH:mm A')}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.modifiedBy}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.modifiedDate != null ? moment(moment.utc(item.modifiedDate).toDate()).local().format('MMMM Do YYYY, HH:mm A') : ""}</td>
                      <td> <button className="edit" onClick={this.onEditClick} id={item.id}> <FontAwesomeIcon onClick={this.onEditClick} id={item.id} icon={faEdit} /> </button>
                        <button className="delete" onClick={this.onDeleteClick} id={item.id}> <FontAwesomeIcon onClick={this.onDeleteClick} id={item.id} icon={faTrashAlt} /> </button> </td>
                    </tr>
                  ]
                })}
              </tbody>
            </table> :
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Feedback Question</th>
                  <th scope="col">Deleted By</th>
                  <th scope="col">Deleted Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.masterQuestion.map((item, i) => {
                  return [
                    <tr key={item.id}>
                      <td>{item.question}</td>
                      <td>{item.deletedBy}</td>
                      <td>{moment(moment.utc(item.deletedDate).toDate()).local().format('MMMM Do YYYY, HH:mm A')}</td>
                      <td>  <button className="delete" onClick={this.onRestoreClick} id={item.id}> <FontAwesomeIcon onClick={this.onRestoreClick} id={item.id} icon={faUndo} /> </button> </td>
                    </tr>
                  ]
                })}
              </tbody>
            </table>
        }
      </div>

      {
        this.state.isConsentOpen ?
          <DeleteConsent isConsentOpen={this.state.isConsentOpen} onCloseModel={this.onCloseModel} onDelete={this.onDeleteConfirm} errorMessage={this.state.errorMessage} type={"Location"} />
          : ""
      }

      {
        this.state.isPopupOpen ?
          <AddFeedbackQuestion errorMessage={this.state.errorMessage} selectedQuestion={this.state.selectedQuestion} isPopupOpen={this.state.isPopupOpen} onCloseModel={this.onCloseModel} onConfirm={this.onConfirm} isEditClick={this.state.isEditClick} type={"Location"} />
          : ""
      }
      {
        this.state.isRestoreOpen ?
          <RestoreConsent isRestoreOpen={this.state.isRestoreOpen} onCloseModel={this.onCloseModel} onRestore={this.onRestoreConfirm} errorMessage={this.state.errorMessage} type={"Location"} />
          : ""
      }
      {
        this.state.isWarningOpen ?
          <WarningConsent infoMessage="Please select feedback type to add question" isWarningOpen={this.state.isWarningOpen} onCloseModel={this.onCloseModel} />
          : ""
      }
    </>


  }
}

const mapStateToProps = (state) => ({
  AdminAuthentication: state.AdminAuthentication,
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(MasterFeedbackQuestions);