import { BOOKING_PRODUCTS , REMOVE_PRODUCTS, CLEAR_BOOKING_PRODUCTS } from './../Actions/Type'

let initialState = {       
    products : []   
   };

   function BookingProductReducer(state = initialState , action){
    switch(action.type){
            case BOOKING_PRODUCTS:  
                return { ...state, products : state.products.concat(action.products)  }
            case REMOVE_PRODUCTS:
                return { ...state, products : state.products.filter(p=> p !== action.products)  }
            case CLEAR_BOOKING_PRODUCTS:  
                return { ...state, products : []  }
            default:
                 return state;
        }
   }

    export default BookingProductReducer;