import { Component } from "react";
import { Navigate } from "react-router";
import Chart from 'react-apexcharts'
import API from "../../../Api";
import { connect } from "react-redux";
import { loginUserInfoAdmin } from '../../../Redux/Actions/AdminAuthenticationActions'
import { ExportToCsv } from "export-to-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEllipsisV } from "@fortawesome/fontawesome-free-solid";
import WarningConsent from "../WarningConsent";


class BookingsByMonthGraph extends Component {

  constructor(props) {

    super(props);
    this.state = {
      series: [],
      monthlyLables: [],
      selectedDataType: 1,
      selectedGraphId: 0,
      yearList: Array.from({ length: 3 }, (_, i) => i + new Date().getFullYear() - 2),
      selectedYear: this.props.selectedYear,
      showWarning: false
    }
  }

  onCloseWarningModel = (e) => {
    this.setState({ showWarning: false })
  }

  async componentDidMount() {

    new API().GetMonthlyData(this.state.selectedYear, this.state.selectedGraphId).then(result => {
      let seriesTodisplay = [];
      result.data.map(p => {
        seriesTodisplay.push({ name: p.dataType, data: p.monthlyData });
        this.setState({ monthlyLables: p.monthlyLabels })
      });
      this.setState({ series: seriesTodisplay })

    })
  }

  updateMonthQuarter = (e) => {
    this.setState({ selectedDataType: e.target.value });

    if (e.target.value == 1) {
      new API().GetMonthlyData(this.state.selectedYear, this.state.selectedGraphId).then(result => {
        let seriesTodisplay = [];
        result.data.map(p => {
          seriesTodisplay.push({ name: p.dataType, data: p.monthlyData });
          this.setState({ monthlyLables: p.monthlyLabels });
        });
        this.setState({ series: seriesTodisplay })
      });
    }
    else {
      new API().GetQuarterlyData(this.state.selectedYear, this.state.selectedGraphId).then(result => {
        let seriesTodisplay = [];
        result.data.map(p => {
          seriesTodisplay.push({ name: p.dataType, data: p.monthlyData });
          this.setState({ monthlyLables: p.monthlyLabels });
        });
        this.setState({ series: seriesTodisplay })
      });
    }
  }

  updateGraphOnSelectionChange = (e) => {

    this.setState({ selectedGraphId: e.target.value })
    if (e.target.value == "") {
      this.setState({ series: [] })
    }
    else {

      if (this.state.selectedDataType == 1) {
        if (this.state.selectedYear > 0) {
          new API().GetMonthlyData(this.state.selectedYear, e.target.value).then(result => {
            let seriesTodisplay = [];
            result.data.map(p => {
              seriesTodisplay.push({ name: p.dataType, data: p.monthlyData });
              this.setState({ monthlyLables: p.monthlyLabels });
            });
            this.setState({ series: seriesTodisplay })
          });
        }
      }
      else {
        if (this.state.selectedYear > 0) {
          new API().GetQuarterlyData(this.state.selectedYear, e.target.value).then(result => {
            let seriesTodisplay = [];
            result.data.map(p => {
              seriesTodisplay.push({ name: p.dataType, data: p.monthlyData });
              this.setState({ monthlyLables: p.monthlyLabels });
            });
            this.setState({ series: seriesTodisplay })
          });
        }
      }
    }
  }

  getTranslations(key) {

    if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
      return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
      return "";
  }

  downloadData = (e) => {
    new API().DownloadGraphData(this.state.selectedYear).then(result => {
      if (result.data.length > 0)
        this.generateCSV(result.data, "BookingData")
      else
        this.setState({ showWarning: true })
    })
  }

  downloadFeedbackData = (e) => {
    new API().DownloadFeedbackGraphData(this.state.selectedYear).then(result => {
      if (result.data.length > 0)
        this.generateCSV(result.data, "FeedbackData")
      else
        this.setState({ showWarning: true })
    })
  }

  downloadFeedbackDataSales = (e) => {
    new API().DownloadFeedbackGraphDataSales(this.state.selectedYear).then(result => {
      if (result.data.length > 0)
        this.generateCSV(result.data, "FeedbackDataSales")
      else
        this.setState({ showWarning: true })
    })
  }

  generateCSV(data, filename) {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: filename,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }


  render() {
    if (this.props.AdminAuthentication.isLogin) {
      return <>
        <h6 className="p-2"> {this.getTranslations("Dashboard_NoOfBooking")} </h6>

        <div className="d-flex ymt">
          {/* <select hidden value={this.state.selectedYear} className="form-control form-select me-2" onChange={this.updateYear}>
            <option id="0" defaultValue="" value="0001"> Select Year</option>

            {
              this.state.yearList.map((p, i) => {
                return [
                  <option key={p} id={p} value={p}> {p} </option>
                ]
              })
            }

          </select> */}

          <select value={this.state.selectedDataType} className="form-control form-select me-2" onChange={this.updateMonthQuarter}>
            <option id="0" value="1"> Monthly</option>
            <option id="1" value="2"> Quarterly</option>
          </select>

          <select value={this.state.selectedGraphId} className="form-control form-select me-2" onChange={this.updateGraphOnSelectionChange}>
            {/* <option defaultValue="" value=""> Click to Select</option> */}
            <option id="0" value="0"> Total Visits</option>
            <option id="7" value="7"> By Visit Type</option>
            <option id="1" value="1"> By Location</option>
            <option id="2" value="2"> By Customer Type</option>
            <option id="3" value="3"> By Opportunity Size</option>
            <option id="4" value="4"> By GEO</option>
            <option id="5" value="5"> By Vertical</option>
            <option id="6" value="6"> By Bookie (Initiator)</option>
          </select>

          <div className="dropdown admin-cust-link">
            <button id="exportdata" className="dropdown-toggle downloadButton d-flex" type="button" data-bs-toggle="dropdown" aria-expanded="false"> <FontAwesomeIcon className="pe-2" icon={faDownload}></FontAwesomeIcon> <FontAwesomeIcon icon={faEllipsisV}></FontAwesomeIcon> </button>
            <ul className="dropdown-menu dropdown-menu-buttons" aria-labelledby="exportdata">
              <li> <a onClick={this.downloadData} className="edit" >{this.getTranslations("Dashboard_ExportBookingData")}</a></li>
              <li>  <a onClick={this.downloadFeedbackData} className="edit" >{this.getTranslations("Dashboard_ExportFeedbackData")}</a></li>
              <li>  <a onClick={this.downloadFeedbackDataSales} className="edit" >{this.getTranslations("Dashboard_ExportFeedbackDataSales")}</a></li>
            </ul>
          </div>
        </div>


        <Chart height={'100%'} options={{
          chart: {
            height: '100%',
            toolbar: false
          },
          xaxis: {
            categories: this.state.monthlyLables
          },
          stroke: {
            curve: 'smooth',
          },
          markers: {
            size: 5
          },
          dataLabels: {
            enabled: true,
          },
          yaxis: {
            labels: {
              formatter: (value) => {
                return value
              },
            }
          }
        }}
          series={this.state.series}
          type="line" />

        {
          this.state.showWarning ?
            <WarningConsent infoMessage={"Nothing to download"} isWarningOpen={this.state.showWarning} onCloseModel={this.onCloseWarningModel} />
            : ""
        }
      </>



    }
    else {
      return <Navigate replace to="/admin" />;
    }
  }
}

const mapStateToProps = (state) => ({
  AdminAuthentication: state.AdminAuthentication,
  Translations: state.Translations
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(BookingsByMonthGraph);