import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import BookingByOpportunityGraph from "./BookingByOpportunityGraph";
import FeedbackRatingGraph from "./FeedbackRatingGraph";
import QuarterlyBookingGraph from "./QuarterlyBookingGraph";
import API from "../../../Api";
import BookingsByMonthGraph from "./BookingsByMonthGraph";
import { faCalendarCheck, faMedal } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { addtranslation } from "../../../Redux/Actions/TranslationAction";

class DashboardContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalBookings: 0,
            totalVisitors: 0,
            totalConvertedDeals: 0,
            perceptionScoreBefore: 0,
            perceptionScoreAfter: 0,
            yearList: this.calculateFY(),
            selectedYear: this.currentFY()
        };
    }

    currentFY = (e) => {
        let month = new Date().getMonth();
        let year = new Date().getFullYear();
        if (month < 4) {
            return year - 1
        }
        else {
            return year
        }
    }

    calculateFY = (e) => {
        let month = new Date().getMonth();
        let year = new Date().getFullYear();
        if (month < 4) {
            return Array.from({ length: 3 }, (_, i) => i + year - 3)
        }
        else {
            return Array.from({ length: 3 }, (_, i) => i + year - 2)
        }

    }

    async componentDidMount() {

        new API().getMasterTranslations().then(result => {
            this.props.addtranslation(result.data)
        })

        new API().GetDashboardDataCount(this.state.selectedYear).then(result => {
            this.setState({
                perceptionScoreBefore: result.data.confidenceLevelBefore,
                perceptionScoreAfter: result.data.confidenceLevelAfter,
                completedVisits: result.data.completedVisits,
                pendingBookings: result.data.pendingBookings,
                totalConvertedDeals: result.data.totalConvertedDeals
            });
        })
    }

    getTranslations(key) {

        if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
            return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
            return "";
    }
    updateYear = (e) => {
        this.setState({ selectedYear: e.target.value });

        new API().GetDashboardDataCount(e.target.value).then(result => {
            this.setState({
                completedVisits: result.data.completedVisits,
                pendingBookings: result.data.pendingBookings,
                totalConvertedDeals: result.data.totalConvertedDeals
            });
        })
    }

    render() {
        return <>
            <div className="row mt-1 mx-0 ">
                <div className="col-md-12 d-flex showing-data">
                    <label className="pe-2 mb-0 pb-0">{this.getTranslations("Dashboard_FinancialYear")}</label>
                    <select value={this.state.selectedYear} className="form-control form-select  me-2 w-auto bg-transparent h-auto p-0 m-0 border-0" onChange={this.updateYear}>
                        <option id="0" defaultValue="" value="0001"> Select Financial Year</option>

                        {
                            this.state.yearList.map((p, i) => {
                                return [
                                    <option key={p} id={p} value={p}> {p} - {p + 1} </option>
                                ]
                            })
                        }

                    </select>
                </div>

            </div>

            <div className="row mx-0">
                <div className="col-md-4">
                    <div className="top-box blue-bg d-flex align-items-center">
                        <h2 className="text-white"> <FontAwesomeIcon icon={faCalendarCheck}></FontAwesomeIcon> <i className="fa fa-users"></i> {this.getTranslations("Dashboard_VisitCompleted")} <span> {this.state.completedVisits} </span></h2>
                    </div>
                </div>                

                <div className="col-md-4">
                    <div className="top-box purple-bg d-flex align-items-center">
                    <h2 className="text-white text-center">
                            <span title={this.getTranslations("Dashboard_PerceptionScoreBeforeTooltip")} className="leftvalue"> {this.state.perceptionScoreBefore} % </span>
                            {this.getTranslations("Dashboard_PerceptionScore")}
                            <span title={this.getTranslations("Dashboard_PerceptionScoreAfterTooltip")}>  {this.state.perceptionScoreAfter} %  </span></h2>
                   
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="top-box dark-blue-bg d-flex align-items-center">
                        <h2 className="text-white"> <FontAwesomeIcon icon={faMedal}></FontAwesomeIcon><i className="fa fa-users"></i> {this.getTranslations("Dashboard_DealsConverted")} <span> {this.state.totalConvertedDeals} </span></h2>
                    </div>
                </div>
            </div>

            <div className="row mx-0">
                <div className="col-md-12">
                    <div className="graph-box">
                        <BookingsByMonthGraph key={this.state.selectedYear} selectedYear={this.state.selectedYear} />
                    </div>
                </div>
            </div>

            <div className="row mx-0">
                <div className="col-md-4">
                    <div className="graph-box-2">
                        <QuarterlyBookingGraph key={this.state.selectedYear} selectedYear={this.state.selectedYear} />
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="graph-box-2">
                        <BookingByOpportunityGraph key={this.state.selectedYear} selectedYear={this.state.selectedYear} />
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="graph-box-2">
                        <FeedbackRatingGraph key={this.state.selectedYear} selectedYear={this.state.selectedYear} />
                    </div>
                </div>
            </div>


        </>;
    }
}

const mapStateToProps = (state) => ({
    Translations: state.Translations
})

export default connect(mapStateToProps, { addtranslation })(DashboardContent);