import { Component } from "react";
import { bookingInfo } from '../../Redux/Actions/BookingAction'
import { bookingInfoNext } from '../../Redux/Actions/BookingActionNext'
import { bookingProducts, clearBookingProducts, removeBookingProducts } from '../../Redux/Actions/BookingProductsAction'
import { currentPage } from '../../Redux/Actions/CurrentPageAction'
import { customerLunch } from '../../Redux/Actions/CustomerLunchAction'
import { customersList } from '../../Redux/Actions/CustomersListAction'
import "react-datepicker/dist/react-datepicker.css";
import API from "../../Api";
import { connect } from "react-redux";
import moment from "moment";
import 'moment-timezone'
import axios from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/fontawesome-free-solid";

class Page5 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      products: [],
      selectedProducts: this.props.BookingProducts.products,
      isProductSelected: true,
      isSubmitButtonDisabled: false
    };
  }

  async componentDidMount() {

    await new API().getMasterProducts(this.props.locationId).then(result => {
      this.setState({ products: result.data });
    });
  }

  PreviousButtonClick = () => {
    this.props.currentPage(4);
    this.props.sendData(4)
  }

  OnChangeHandler = (e) => {
    const selectedProductCount = this.props.BookingProducts.products.length
    if (e.target.checked) {
      this.setState({ isProductSelected: true });
      this.props.bookingProducts(e.target.id);
    }
    else {
      this.props.removeBookingProducts(e.target.id);
      if (selectedProductCount - 1 <= 0) {
        this.setState({ isProductSelected: false });
      }
    }
  }

  async saveData() {

    const products = [];
    this.props.BookingProducts.products.map(p => {
      products.push({ CategoryId: p });
    });

    let bookingInfo = {
      bookingDetails: {
        location: this.props.BookingInfo.page1.locationId,
        VisitType: this.props.BookingInfo.page1.visitTypeId,
        bookieName: this.props.BookingInfo.page1.bookieName,
        bookieEmail: this.props.BookingInfo.page1.bookieEmail,
        customerName: this.props.BookingInfo.page1.customerName,
        visitingPartySize: this.props.BookingInfoNext.page2.sizeOfVisitingParty,
        participantsSize: this.props.BookingInfoNext.page2.hclParticipants,
        customerType: this.props.BookingInfo.page1.customerTypeId != "" ? this.props.BookingInfo.page1.customerTypeId : 0,
        bookingSlot: this.props.BookingInfoNext.page2.timeSlotId,
        bookingDate: moment(this.props.BookingInfoNext.page2.selectedDate),
        bookingEndDate: moment(this.props.BookingInfoNext.page2.selectedEndDate),
        opportunityId: this.props.BookingInfo.page1.opportunityId,
        opportunitySize: this.props.BookingInfo.page1.oppotunitySizeId != "" ? this.props.BookingInfo.page1.oppotunitySizeId : 0,
        lunchRequired: this.props.CustomerLunch.lunch,
        bookingtime: '',
        regionId: this.props.BookingInfo.page1.regionId,
        vertical: this.props.BookingInfo.page1.verticalId,
        subregionId: this.props.BookingInfo.page1.subregionId,
      },
      customers: this.props.CustomersList.customers,
      products: products,
      StartTime : this.props.BookingInfoNext.page2.selectedDateTimeZone,
      EndTime : this.props.BookingInfoNext.page2.selectedEndDateTimeZone
    };


    await new API().addBookings(bookingInfo).then(result => {
      this.props.setBookingconfirmationId(result.data.id);

      let ccRecipients = result.data.adminEmails.map((adminMail) => `{
          "emailAddress": {
            "address": "${adminMail.trim()}"
          }
        }`)

      let addmore = result.data.adminEmails.length > 0 ? `
         "toRecipients": [
          ${ccRecipients}
         ]`: '';


      const headers = {
        'Authorization': `Bearer ${result.data.token}`,
        'Content-Type': 'application/json'
      };
      this.sendEmailtoBookie(headers, result, addmore);


      this.props.currentPage(6);
      this.props.sendData(6)
    });
  }

  sendEmailtoBookie(headers, result, addmore) {
    let bodyMsg =
      `<html><head></head><body><br />Dear ${result.data.bookingDetails.bookingDetail.bookieName},<br /> <br /> Your booking has been submitted, kindly wait for FLUID Experience Centre Team to confirm.<br />
          <span style=color:blue;>Booking Details</span> :
          <b>Booking No -</b> ${result.data.id}
          <b>Customer Name -</b> ${result.data.bookingDetails.bookingDetail.customerName}
          <b>Customer Type -</b> ${result.data.bookingDetails.bookingDetail.customerTypeName}
          <b>Location -</b> ${result.data.bookingDetails.bookingDetail.location}         
          <b>Booking Start Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingDate),result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')} 
          <b>Booking End Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingEndDate),result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')}  
          <b>Lunch -</b> ${result.data.bookingDetails.bookingDetail.lunchRequired ? 'Yes' : 'No'}
          <b>Visiting Party Size -</b> ${result.data.bookingDetails.bookingDetail.visitingPartySize}
          <b>Participant Size -</b> ${result.data.bookingDetails.bookingDetail.participantsSize} <br />
         
          <span style=color:blue;>Visiting delegation -</span> ${result.data.bookingDetails.customers.map((cutomer) => '<br />' + cutomer.customerName + '       (' + cutomer.customerRole + ')    , ' + cutomer.customerEmail)}
          <br />
          <b>Thanks</b>
          FLUID Experience Centre Team
         </body>
        </html>`
    bodyMsg = bodyMsg.replace(/(?:\r\n|\r|\n)/g, '<br>')
    let sendmessageBody = `{
        "message": {
        "subject": "${result.data.id} - Booking Submitted",
        "body": {
        "contentType": "Html",
        "content": "${bodyMsg}"
        },
        "toRecipients": [
        {
        "emailAddress": {
        "address": "${result.data.bookingDetails.bookingDetail.bookieEmail}"
        }
        }
        ]
        },
        "saveToSentItems": "true"
        }`
    const URL = `https://graph.microsoft.com/v1.0/users/fluidcentrebookingsystem@hcldwpdev.com/sendMail`;
    axios.post(URL, sendmessageBody, { headers })
      .then(response => {

        let reminder = {
          BookingId: result.data.bookingDetails.bookingDetail.id,
          NewBookingMailSentToBookie: true
        };

        new API().addBookingReminder(reminder).then(res => {
          this.sendEmailToAdmin(headers, result, addmore);
        });
        console.log('Mail sent successfully.');
      }).catch(error => {
        new API().logServiceErrorToDatabase(error);
        console.log("error while sending mail", error);
      });
  }

  sendEmailToAdmin(headers, result, addmore) {
    let bodyMsg =
      `<html><head></head><body><br />Dear Team,<br /> <br /> New booking has been received, kindly review and confirm.<br />
         <span style=color:blue;>Booking Details</span> :
         <b>Booking No -</b> ${result.data.id}
         <b>Customer Name -</b> ${result.data.bookingDetails.bookingDetail.customerName}
         <b>Customer Type -</b> ${result.data.bookingDetails.bookingDetail.customerTypeName}
         <b>Location -</b> ${result.data.bookingDetails.bookingDetail.location}        
         <b>Booking Start Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingDate),result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')} 
         <b>Booking End Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingEndDate),result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')}  
         <b>Lunch -</b> ${result.data.bookingDetails.bookingDetail.lunchRequired ? 'Yes' : 'No'}
         <b>Visiting Party Size -</b> ${result.data.bookingDetails.bookingDetail.visitingPartySize}
         <b>Participant Size -</b> ${result.data.bookingDetails.bookingDetail.participantsSize} <br />
        
        <span style=color:blue;>Visiting delegation -</span> ${result.data.bookingDetails.customers.map((cutomer) => '<br />' + cutomer.customerName + '       (' + cutomer.customerRole + ')    , ' + cutomer.customerEmail)}        
        <br /> ${process.env.REACT_APP_ADMIN_PORTAL_URL}         
        <br /> <b>Thanks</b>
        FLUID Experience Centre Team        
        </body>
       </html>`
    bodyMsg = bodyMsg.replace(/(?:\r\n|\r|\n)/g, '<br>')
    let sendmessageBody = `{
       "message": {
       "subject": "${result.data.id} - New Booking Received",
       "body": {
       "contentType": "Html",
       "content": "${bodyMsg}"
       }${addmore ? "," + addmore : ''}
       },
       "saveToSentItems": "true"
       }`
    const URL = `https://graph.microsoft.com/v1.0/users/fluidcentrebookingsystem@hcldwpdev.com/sendMail`;
    axios.post(URL, sendmessageBody, { headers })
      .then(response => {

        let reminder = {
          BookingId: result.data.bookingDetails.bookingDetail.id,
          NewBookingMailSentToAdmin: true,
          AdminEmails: result.data.adminEmails
        };

        new API().updateBookingReminder(reminder);

        console.log('Mail sent successfully admin.');
      }).catch(error => {
        new API().logServiceErrorToDatabase(error);
        console.log("error while sending mail", error);
      });
  }

  renderTooltip = (title) => (
    <Tooltip placement='right'>{title}</Tooltip>
  );

  NextButtonClick = () => {
    if (this.props.BookingProducts.products.length <= 0) {
      this.setState({ isProductSelected: false });
    }
    else {
      this.setState({ isSubmitButtonDisabled: true });
      this.saveData();
    }
    //this.saveData();
    //clear storage on save data in database
  }

  getTranslations(key) {

    if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
      return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
      return "";
  }


  render() {

    return <fieldset className="wizard-fieldset show">
      <div className="col-md-12 right-section">
        <h6 className="text-center pb-4 px-2"> {this.getTranslations("User_BookingProductsPage_Heading")}
          <OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingProductsPage_HeadingToottip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
        </h6>
        <div className="s-product-category">
          {this.state.products.map(product => this.state.selectedProducts.some(p => p == product.id) ?

            <div key={product.id} className="form-check form-switch">
              <input disabled={this.state.isSubmitButtonDisabled} defaultChecked={true} onChange={this.OnChangeHandler} key={product.id} className={this.state.isProductSelected ? "form-check-input" : "form-check-input is-invalid"} type="checkbox" id={product.id} />
              <label className="form-check-label" htmlFor={product.id}> {product.productName} </label>
            </div> :
            <div key={product.id} className="form-check form-switch">
              <input disabled={this.state.isSubmitButtonDisabled} onChange={this.OnChangeHandler} key={product.id} className={this.state.isProductSelected ? "form-check-input" : "form-check-input is-invalid"} type="checkbox" id={product.id} />
              <label className="form-check-label" htmlFor={product.id}> {product.productName} </label>
            </div>
          )}
          <label className={this.state.isProductSelected ? "disable" : "errorMessage"}>
            Please select atleast one product category.
          </label>
        </div>
      </div>
      <div className="form-group clearfix row btn-fix-bottom">
        <div className="text-center main-btn-fix">
          <button disabled={this.state.isSubmitButtonDisabled} onClick={this.PreviousButtonClick} className="form-wizard-previous-btn float-left">Previous</button>
          <button disabled={this.state.isSubmitButtonDisabled} onClick={this.NextButtonClick} className={this.state.isSubmitButtonDisabled ? "form-wizard-next-btn float-right disabled" : "form-wizard-next-btn float-right"}>Submit <i className="fa fa-angle-right"></i></button>
        </div>
      </div>
    </fieldset>

  }
}

const mapStateToProps = (state) => ({
  BookingInfo: state.BookingInfo,
  BookingInfoNext: state.BookingInfoNext,
  CurrentPage: state.CurrentPage,
  BookingProducts: state.BookingProducts,
  CustomerLunch: state.CustomerLunch,
  CustomersList: state.CustomersList,
  removeBookingProducts: state.removeBookingProducts,
  Translations: state.Translations
})

export default connect(mapStateToProps, { bookingInfo, clearBookingProducts, bookingInfoNext, currentPage, customersList, customerLunch, bookingProducts, removeBookingProducts })(Page5);
