import { Component } from "react";
import { customersList } from '../../Redux/Actions/CustomersListAction'
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/fontawesome-free-solid";
import EditCustomer from "./EditCustomer";
import { addtranslation } from "../../Redux/Actions/TranslationAction";

class CustomerDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customers: this.props.CustomersList.customers,
            isShowEdit: false,
            selectedCustomer: this.props.CustomersList.customers[0]
        }
    }

    editCustomer(customer) {
        this.setState({ isShowEdit: true, selectedCustomer: customer });
    };

    cancelEdit() {
        this.setState({ isShowEdit: false });
    }

    getTranslations(key) {   

        if(this.props.Translations.translations.filter(p => p.appKey == key)[0] !=null )
        return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
        return "";
      }

    //reload customer list


    render() {
        let customers = this.props.CustomersList.customers.map((customer) =>
            <tr key={customer.index}>               
                <td>{customer.index + 1}</td>
                <td style={{ textTransform: 'capitalize' }}>{customer.customerName}</td>
                <td>{customer.customerEmail}</td>
                <td>{customer.customerRole}</td>

                <td>
                    <div className="dropdown">
                        <a className="edit" style={{ cursor: 'pointer' }} onClick={() => this.editCustomer(customer)}><FontAwesomeIcon icon={faEdit}></FontAwesomeIcon></a>
                    </div>
                </td>
            </tr>
        )


        return (<div id="tablelist">
            <table className="table table-striped table-hover">
                <thead>
                    <tr>                       
                        <th>{this.getTranslations("User_VisitingDelegationPage_GridSno")}</th>
                        <th>{this.getTranslations("User_VisitingDelegationPage_GridName")}</th>
                        <th>{this.getTranslations("User_VisitingDelegationPage_GridEmail")}</th>
                        <th>{this.getTranslations("User_VisitingDelegationPage_GridRole")}</th>
                        <th>{this.getTranslations("User_VisitingDelegationPage_GridActions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {customers}
                </tbody>
            </table>
            {this.state.isShowEdit && <EditCustomer customer={this.state.selectedCustomer} showPopup={this.state.isShowEdit} cancel={() => this.cancelEdit()} />}
        </div>)
    }

}

const mapStateToProps = (state) => ({
    CustomersList: state.CustomersList,
    Translations : state.Translations
})

export default connect(mapStateToProps, { customersList, addtranslation })(CustomerDetails);