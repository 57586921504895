import { Component } from "react";
import { Navigate } from "react-router";
import Chart from 'react-apexcharts'
import API from "../../../Api";
import { connect } from "react-redux";
import { loginUserInfoAdmin } from '../../../Redux/Actions/AdminAuthenticationActions'


class BookingByOpportunityGraph extends Component {

  constructor(props) {
    super(props);
    this.state = {      
      series: [],
      opportunityLables : []
    }
  }

  async componentDidMount() {
    new API().GetNoOfBookingsByOpportunitySize(this.props.selectedYear).then(result => {
      this.setState({
        series: [{
          name: "No of bookings",
          data: result.data.bookingData
        }] , 
        opportunityLables : result.data.opportunityType
      });
    })
  }

  getTranslations(key) {   

    if(this.props.Translations.translations.filter(p => p.appKey == key)[0] !=null )
    return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
    return "";
  }

  render() {
    if (this.props.AdminAuthentication.isLogin) {
      return <>
        <h6> {this.getTranslations("Dashboard_BookingByOpportunity")} </h6>
        <Chart                
               series={this.state.series}
               type="bar" 
               options={{                
                chart:{
                  height:'auto',
                  toolbar : {
                    show : false                    
                  }
                },
                xaxis: {
                  categories: this.state.opportunityLables,
                  labels: {
                    formatter: (value) => {
                      return value 
                    },
                  }
                },
                dataLabels: {
                  enabled: false                 
                },
               
                plotOptions: {
                  bar: {
                    horizontal: true,
                    distributed: false,
                    dataLabels: {
                      position: 'top'
                    }
                  }
                },
                yaxis: {                 
                  labels: {
                    formatter: (value) => {
                      return value 
                    },
                  }
                }
              }}/>
      </>

    }
    else {
      return <Navigate replace to="/admin" />;
    }
  }
}

const mapStateToProps = (state) => ({
  AdminAuthentication: state.AdminAuthentication,
  Translations : state.Translations
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(BookingByOpportunityGraph);