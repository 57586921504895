import { ADMINEDITBOOKING } from './../Actions/Type'

let initialState = {
    bookingDetails: {
        id: '',
        initiallocationId :'',
        locationId: '',
        visitTypeId: '',
        bookieName: '',
        bookieEmail: '',
        customerName: '',
        oppotunitySizeId: '',
        opportunityId: '',
        customerTypeId: '',
        regionId: '',
        subregionId: '',
        verticalId: '',
        sizeOfVisitingParty: '',
        hclParticipants: '',
        timeSlotId: '',
        selectedDate: '',
        selectedTime: '',
        selectedEndDate: '',
        selectedDateTimeZone: '',
        selectedEndDateTimeZone: '',
        lunch: false,
        products: [],
        customers: [],
        customersEdited: [],
        productsEdited: [],
    }
};

function AdminEditBookingReducer(state = initialState, action) {
    if (action.type == ADMINEDITBOOKING)
        return { ...state, bookingDetails: action.bookingInfo.bookingDetails  }
    else
        return state;
}

export default AdminEditBookingReducer;