import { Component } from "react";
import API from "../../Api";
import FluidLogo from '../../Images/logo-fluid-studio.png'
import BackgroundImage from '../../Images/bg.png'
import $ from 'jquery';
import EditPage1 from "./EditPage1";
import EditPage2 from "./EditPage2";
import EditPage3 from "./EditPage3";
import EditPage4 from "./EditPage4";
import EditPage5 from "./EditPage5";
import { connect } from "react-redux";
import Page6 from "./EditPage6";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartPie, faTimes, faUserCircle } from "@fortawesome/fontawesome-free-solid";
import { addtranslation } from "../../Redux/Actions/TranslationAction";
import { Navigate } from "react-router-dom";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import withRouter from "../WithRouter";
import { loginUserInfoAdmin } from "../../Redux/Actions/AdminAuthenticationActions";
import { adminCurrentPage } from "../../Redux/Actions/AdminCurrentPageAction";
import { saveEditBookingInfo } from "../../Redux/Actions/AdminEditBookingAction";
import { saveEditBookingInitialInfo } from "../../Redux/Actions/AdminInitialDataAction";

class EditBooking extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentPage: 1,
      bookingConfirmationId: '',
      minimumBookingTimeFromCurrentDateInMinutes: 1,
      redirect: '',
      applicationVersion: '',
      isError: false,
      errorMessage: ''
    };
  }

  async componentDidMount() {

    await new API().getInitialDataForBookings().then(result1 => {
      
      localStorage.setItem("isValidationRequired", result1.data.isBookingSlotValidationRequired);
      localStorage.setItem("dateRangeSelectionInMonths", result1.data.dateRangeSelectionInMonths);
      localStorage.setItem("LunchImageColor", result1.data.lunchImageColor);
      this.setState({
        isLoading: false,
        applicationVersion: result1.data.applicationVersion,
        minimumBookingTimeFromCurrentDateInMinutes: result1.data.minimumBookingTimeFromCurrentDateInMinutes
      });

      this.props.adminCurrentPage(1);
      this.props.saveEditBookingInitialInfo({
        locations: result1.data.locations,
        customerTypes: result1.data.customerType,
        OpportunitySizes: result1.data.opportunitySize,
        timeSlots: result1.data.timeSlots,
        regions: result1.data.regions,
        verticals: result1.data.verticals,
        visitType: result1.data.visitType
      });

      let bookingDetails = {
        id: '',
        locationId: '',
        visitTypeId: '',
        bookieName: '',
        bookieEmail: '',
        customerName: '',
        oppotunitySizeId: '',
        opportunityId: '',
        customerTypeId: '',
        regionId: '',
        subregionId: '',
        verticalId: '',
        sizeOfVisitingParty: '',
        hclParticipants: '',
        timeSlotId: '',
        selectedDate: '',
        selectedTime: '',
        selectedEndDate: '',
        lunch: false,
        products: [],
        customers: [],
        customersEdited: [],
        productsEdited: [],
      }
      this.props.saveEditBookingInfo({
        bookingDetails: bookingDetails
      });

      new API().getBookingDetailsForAdmin(this.props.params.id).then(result => {
        if (result.data.isValid) {
          this.props.saveEditBookingInfo({
            bookingDetails: {
              id: result.data.bookingDetails.bookingDetails.id,
              locationId: result.data.bookingDetails.bookingDetails.location,
              initiallocationId : result.data.bookingDetails.bookingDetails.location,
              visitTypeId: result.data.bookingDetails.bookingDetails.visitType,
              bookieName: result.data.bookingDetails.bookingDetails.bookieName,
              bookieEmail: result.data.bookingDetails.bookingDetails.bookieEmail,
              customerName: result.data.bookingDetails.bookingDetails.customerName,
              oppotunitySizeId: result.data.bookingDetails.bookingDetails.opportunitySize,
              opportunityId: result.data.bookingDetails.bookingDetails.opportunityId,
              customerTypeId: result.data.bookingDetails.bookingDetails.customerType,
              regionId: result.data.bookingDetails.bookingDetails.regionId,
              subregionId: result.data.bookingDetails.bookingDetails.subRegionId,
              verticalId: result.data.bookingDetails.bookingDetails.vertical,
              sizeOfVisitingParty: result.data.bookingDetails.bookingDetails.visitingPartySize,
              hclParticipants: result.data.bookingDetails.bookingDetails.participantsSize,
              timeSlotId: result.data.bookingDetails.bookingDetails.bookingSlot,
              selectedDate: result.data.bookingDetails.bookingDetails.bookingDate,
              selectedTime: result.data.bookingDetails.bookingDetails.bookingTime,
              selectedEndDate: result.data.bookingDetails.bookingDetails.bookingEndDate,
              lunch: result.data.bookingDetails.bookingDetails.lunchRequired,
              products: result.data.bookingDetails.productsDemostrations,
              customers: result.data.bookingDetails.customers,
              customersEdited: [],
              productsEdited: result.data.bookingDetails.productsDemostrations
            }
          });
        }
        else {
          let bookingDetails = {
            id: '',
            locationId: '',
            visitTypeId: '',
            bookieName: '',
            bookieEmail: '',
            customerName: '',
            oppotunitySizeId: '',
            opportunityId: '',
            customerTypeId: '',
            regionId: '',
            subregionId: '',
            verticalId: '',
            sizeOfVisitingParty: '',
            hclParticipants: '',
            timeSlotId: '',
            selectedDate: '',
            selectedTime: '',
            selectedEndDate: '',
            lunch: false,
            products: [],
            customers: [],
            customersEdited: [],
            productsEdited: [],
          }
          this.props.saveEditBookingInfo({
            bookingDetails: bookingDetails
          });
          this.setState({ isError: true, errorMessage: 'This Booking Id ' + this.props.params.id + ' is not available for update' });
        }

      });

    });
  }

  navBarToggle = (e) => {
    $(".custom-nav").toggleClass("opensidebar");
  }

  getFirstLetterUpper(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  signOutfromApp = (e) => {
    this.props.loginUserInfoAdmin({ isLogin: false, Username: '' , role : '' });
    this.props.leftMenuSelection({ selection: false, selectionLogs: false, selectedSubMenu: 0 });
    this.setState({ redirect: '/admin' });

  }


  sendData = (data) => {
    this.setState({ currentPage: data })
  }


  handletoggleWindow = (e) => {
    let closeQuickActionWindow = true;
    if (e.target.getAttribute("class") && (e.target.getAttribute("class").includes('navbar-toggler') || e.target.getAttribute("class").includes('fa-user-circle')))
      closeQuickActionWindow = false;

    let nodes = document.getElementsByClassName("custom-nav");

    if (nodes.length > 0 && closeQuickActionWindow)
      nodes[0].setAttribute("class", "custom-nav")
  };
  render() {
    if (!this.props.AdminAuthentication.isLogin) {
      return <Navigate replace to="/admin" />;
    }

    return <>
      <div id="wrapper" onClick={(e) => this.handletoggleWindow(e)}>
        <div id="sidebar">
          <img src={BackgroundImage} width="100%" alt="Fluid Studio" />
        </div>

        <div className="content-area">
          <section className="wizard-section">
            <div className="row no-gutters">

              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                  <h2 className="welcome-user navbar-toggler" onClick={this.navBarToggle}> Welcome {this.getFirstLetterUpper(this.props.AdminAuthentication.Username)} <FontAwesomeIcon icon={faUserCircle} size="xl"></FontAwesomeIcon> </h2>
                  <button className="navbar-toggler hide-desk" onClick={this.navBarToggle} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="custom-nav" >
                    <div className="user-profile-detail" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                      <span className="user-circle"> <i className='fas fa-user-circle'><FontAwesomeIcon icon={faUserCircle}></FontAwesomeIcon></i></span>
                      <div className="ps-2 d-flex justify-content-center flex-column">
                        <div className="name-user"> {this.getFirstLetterUpper(this.props.AdminAuthentication.Username)} </div>
                      </div>
                    </div>

                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                        <a href={'/dashboard'} onClick={this.onNavigateToDashboard} target="_blank" className="nav-link" aria-current="page" > <i className='fas fa-ticket-alt'><FontAwesomeIcon icon={faChartPie}></FontAwesomeIcon></i> Dashboard</a>
                        <a onClick={this.signOutfromApp} className="nav-link for-mobile" aria-current="page" > <i className='fas fa-ticket-alt'><FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon></i> Sign out</a>

                      </li>

                    </ul>
                    <a onClick={this.signOutfromApp} className="closesidebar for-desktop"> Sign out </a>
                    <a onClick={this.navBarToggle} className="closesidebar for-mobile"> <i className='fas fa-times'><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon></i> </a>

                  </div>
                </div>
              </nav>

              <div className="col-lg-12 col-md-12">
                <div className="form-wizard">
                  <div className="form-wizard-header">
                    <h2><img src={FluidLogo} width="200" alt="Fluid Studio" /></h2>
                    <div className="mb-2 for-mobile welcome-user"> Welcome {this.getFirstLetterUpper(this.props.AdminAuthentication.Username)} </div>
                    <ul className="list-unstyled form-wizard-steps clearfix">
                      <li id="1" className={this.state.currentPage == 1 ? 'active' : 'activated'}><span></span></li>
                      <li id="2" className={this.state.currentPage == 2 ? 'active' : this.getActivatedClass(2)}><span></span></li>
                      <li id="3" className={this.state.currentPage == 3 ? 'active' : this.getActivatedClass(3)}><span></span></li>
                      <li id="4" className={this.state.currentPage == 4 ? 'active' : this.getActivatedClass(4)}><span></span></li>
                      <li id="5" className={this.state.currentPage == 5 ? 'active' : this.getActivatedClass(5)}><span></span></li>
                    </ul>
                  </div>
                  {
                    this.state.isError ?
                      <h6> {this.state.errorMessage} </h6> :
                      this.getPageToDisplay()
                  }
                </div>
              </div>
            </div>
          </section>
        </div>

      </div>
      <footer className="text-center footer-booking">
      Copyright © {new Date().getFullYear()} HCL Technologies Limited
      </footer>
    </>

  }

  setBookingconfirmationId = (data) => {
    this.setState({ bookingConfirmationId: data })
  }

  displayErrorMessage =(e)=>{

    this.setState({isError : true , errorMessage : e});
  }

  getPageToDisplay() {

    if (this.state.currentPage == 1) {
      return this.props.AdminEditBooking.bookingDetails.id > 0 ? <EditPage1 sendData={this.sendData} ></EditPage1> : "";
    }
    else if (this.state.currentPage == 2) {
      return <EditPage2 sendData={this.sendData} minimumBookingTimeFromCurrentDateInMinutes={this.state.minimumBookingTimeFromCurrentDateInMinutes}></EditPage2>;

    }
    else if (this.state.currentPage == 3) {
      return <EditPage3 sendData={this.sendData} ></EditPage3>;

    }
    else if (this.state.currentPage == 4) {
      return <EditPage4 setBookingconfirmationId={this.setBookingconfirmationId} sendData={this.sendData}></EditPage4>
    }
    // else if (this.state.currentPage == 5) {
    //   return <EditPage5 displayErrorMessage={this.displayErrorMessage} setBookingconfirmationId={this.setBookingconfirmationId} sendData={this.sendData} ></EditPage5>

    // }
    else if (this.state.currentPage == 5) {
      return <Page6 bookingId={this.state.bookingConfirmationId}></Page6>;
    }

  }

  getActivatedClass(number) {
    return this.state.currentPage > number ? 'activated' : '';
  }
}

const mapStateToProps = (state) => ({
  Translations: state.Translations,
  AdminAuthentication: state.AdminAuthentication,
  AdminEditBooking: state.AdminEditBooking,
  AdminCurrentPage: state.AdminCurrentPage,
  AdminInitialData: state.AdminInitialData
})

export default connect(mapStateToProps, { addtranslation, loginUserInfoAdmin, adminCurrentPage, saveEditBookingInfo, saveEditBookingInitialInfo })(withRouter(EditBooking));