import { CUSTOMERS_LIST, CLEAR_CUSTOMERS_LIST, EDIT_CUSTOMERS_LIST,REMOVE_ITEM_CUSTOMERS_LIST } from './Type'

export function customersList(customers) {
     return {
          type: CUSTOMERS_LIST,
          customers: customers
     }
}

export function clearCustomersList() {
     return {
          type: CLEAR_CUSTOMERS_LIST

     }
}

export function editCustomersList(customer) {
     return {
          type: EDIT_CUSTOMERS_LIST,
          customer: customer
     }
}

export function removeItemCustomersList(index) {
     return {
          type: REMOVE_ITEM_CUSTOMERS_LIST,
          index: index

     }
}