import { Component } from "react";

class Loader extends Component{

    constructor(props)
    {
        super(props);
    }

    render(){
        return <>
         
        <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="row">
                 <div className="loader" >
                </div>
             </div>
         <div className="row loadermessage">
            <strong>{ this.props.message!= null ? this.props.message : "Loading Data.." }</strong>
        </div>
</div>

        </>
    }
}

export default Loader;