import { Component } from "react";
import { Modal } from 'react-bootstrap'
import moment from "moment/moment";
import 'moment-timezone'

class TimeZoneList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timelist: moment.tz.names(),
            value : ''
        }
    }

    searchList = (e) =>{      
        this.setState( { value : e.target.value})       
    } 

    render() {



        return (
            <div className="modal show" style={{ display: 'block', position: 'initial' }}>
                <Modal show={this.props.isTimeZoneOpen} onHide={this.props.onCloseModel} >
                    <Modal.Header className="modal-footer justify-content-center">
                        <h5 id="exampleModalLabel"> TimeZones </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-md-12 w-100">

                        <input  value={this.state.value} type="text" onChange={this.searchList} 
                               className="form-control mb-2"
                               placeholder="Search for timezone"/>

                            <ul class="list-group timezoneheight">

                                {this.state.timelist.filter(p=> p.toLowerCase().includes(this.state.value.toLowerCase())).map((item, i) => {
                                    return [                                        
                                        <li key={item} class="list-group-item"> {item}  </li>                                         
                                    ]
                                })}                              

                            </ul>


                        </div>

                        <div className="d-flex justify-content-center mt-4">
                            <button type="button" onClick={this.props.onCloseModel} className="btn outline-button btn-small" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </Modal.Body>



                </Modal>

            </div>
        );
    }

}

export default TimeZoneList;