import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { DateRangePicker } from "rsuite";
import API from "../../../Api";
import DownloadFile from "../DownloadFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/fontawesome-free-solid";

class AuditLogs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            auditLogs: [],
            isConsentOpen: false,
            selectedLocation: {},
            errorMessage: '',
            activePage: 1,
            TotalRecords: 0,
            RecordPerPage: 0,
            startDate: null,
            endDate: null,
            dataTodownload: [],
            isDownloadOpen : false
        };

    }

    async componentDidMount() {
        new API().getAuditLogs(1).then(result => {
            this.setState({ auditLogs: result.data.auditLogs, RecordPerPage: result.data.recordPerPage, TotalRecords: result.data.totalCount })
        })
    }

    handleSubmitClick = (value) => {
        let startDateValue = moment(value[0]).format('YYYY-MM-DD')
        let endDateValue = moment(value[1]).format('YYYY-MM-DD')
        this.setState({ startDate: startDateValue, endDate: endDateValue });

        new API().getAuditLogsFiltered(1, startDateValue, endDateValue).then(result => {
            this.setState({ auditLogs: result.data.auditLogs, RecordPerPage: result.data.recordPerPage, TotalRecords: result.data.totalCount })

        });

    }

    OnDateChange = (value) => {
        this.setState({ startDate: value != null ? value[0] : null, endDate: value != null ? value[1] : null });
        if (value == null) {
            new API().getAuditLogs(1).then(result => {
                this.setState({ auditLogs: result.data.auditLogs, RecordPerPage: result.data.recordPerPage, TotalRecords: result.data.totalCount })
            })
        }
    }

    OnPageClick = (e) => {
        this.setState({ activePage: e.selected + 1 });
        if (this.state.startDate == null && this.state.endDate == null) {
            new API().getAuditLogs(e.selected + 1).then(result => {
                this.setState({ auditLogs: result.data.auditLogs, RecordPerPage: result.data.recordPerPage, TotalRecords: result.data.totalCount })
            })
        }
        else {
            let startDateValue = moment(this.state.startDate).format('YYYY-MM-DD')
            let endDateValue = moment(this.state.endDate).format('YYYY-MM-DD hh:mm')

            new API().getAuditLogsFiltered(e.selected + 1, startDateValue, endDateValue).then(result => {
                this.setState({ auditLogs: result.data.auditLogs, RecordPerPage: result.data.recordPerPage, TotalRecords: result.data.totalCount })

            });
        }

    }   

    downloadAuditLogs = () => {

        if(this.state.startDate!=null && this.state.endDate!=null)
        {
            new API().downloadAuditLogsFiltered(this.state.startDate,this.state.endDate).then(result => {
                let data = [];
                result.data.map(item => {
                    data.push({
                        Id: item.id,
                        ActionType: item.actionType,
                        UpdatedDate: moment(moment.utc(item.createdDate).toDate()).local().format('MMMM Do YYYY HH:mm A'),
                        ActionTable: item.actionTable,
                        oldValue: item.oldValue,
                        newValue: item.newValue,
                        MasterID: item.masterId
                    })
                });
    
                this.setState({ dataTodownload: data , isDownloadOpen : true }); 
            })
        }
        else
        {
            new API().downloadAuditLogs().then(result => {
                let data = [];
                result.data.map(item => {
                    data.push({
                        Id: item.id,
                        ActionType: item.actionType,
                        UpdatedDate: moment(moment.utc(item.createdDate).toDate()).local().format('MMMM Do YYYY HH:mm A'),
                        ActionTable: item.actionTable,
                        oldValue: item.oldValue,
                        newValue: item.newValue,
                        MasterID: item.masterId
                    })
                });
    
                this.setState({ dataTodownload: data , isDownloadOpen : true });
            })
        }        
    }

    onDownload=()=>{
        if(this.state.dataTodownload.length > 0)
        {
            this.generateCSV(this.state.dataTodownload);
        }
        
        this.setState({isDownloadOpen : false});
    }

    onCloseModel=()=>{
        this.setState({isDownloadOpen : false});
    }

    generateCSV(data)
    {
        const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: false,
            filename : 'AuditLogs',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
          };
         
        const csvExporter = new ExportToCsv(options);
         
        csvExporter.generateCsv(data);
    }

    render() {

        return <>
            <div className="d-flex justify-content-between align-items-center mb-2 mob-colls">
            <div className="d-flex align-items-center mob-colls">
                <h2 className="me-2"> Audit Logs </h2>

               

                <DateRangePicker
                    size="sm"
                    name="selectedDate"
                    placeholder="Select Audit Logs Date Range"
                    onChange={this.OnDateChange}
                    // onChangeRaw={(e) => e.preventDefault()}
                    value={this.state.value}
                    showOneCalendar={true}
                    onOk={this.handleSubmitClick}
                    ranges={[]}
                    character=' - '
                    format='dd/MMM/yyyy'
                />
                </div>
            <div className="d-flex">
            <button className={this.state.TotalRecords == 0 ? "fill-button btn-small disabled" : "fill-button btn-small"} disabled={this.state.TotalRecords == 0 ? true : false} onClick={this.downloadAuditLogs}> <FontAwesomeIcon className="me-2" icon={faDownload}></FontAwesomeIcon> <span> Download </span> </button>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={this.OnPageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={(this.state.TotalRecords > this.state.RecordPerPage) ? Math.ceil(this.state.TotalRecords / this.state.RecordPerPage) : 1}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    className="cust-pagination px-0"
                />
        </div>
            </div>

            <div className="table-responsive bg-white">


            <table className="table table-hover audit-log-table">
                    <thead>
                        <tr>
                            <th scope="col">Action Entity</th>
                            <th scope="col">Action Type</th>
                            <th scope="col">Action Date</th>
                            <th scope="col">Old Value</th>
                            <th scope="col">New Value</th>

                            <th scope="col">Action By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.auditLogs.map((item, i) => {
                            return [
                                <tr key={item.id}>
                                    <td style={{ whiteSpace: "pre-wrap" }}>{item.actionTable}</td>
                                    <td style={{ whiteSpace: "pre-wrap" }}>{item.actionType}</td>
                                    <td style={{ whiteSpace: "pre-wrap" }}>{moment(moment.utc(item.createdDate).toDate()).local().format('MMMM Do YYYY HH:mm A')}</td>
                                    <td style={{ whiteSpace: "pre-wrap" }}>{item.oldValue}</td>
                                    <td style={{ whiteSpace: "pre-wrap" }}>{item.newValue}</td>
                                    <td style={{ whiteSpace: "pre-wrap" }}>{item.username}</td>
                                </tr>
                            ]
                        })}
                    </tbody>
                </table>
            </div>

            <DownloadFile onCloseModel={this.onCloseModel} isDownloadOpen={this.state.isDownloadOpen} onDownload={this.onDownload}/>

        </>
    }
}

export default AuditLogs;