import { Component } from "react";
import { Navigate } from "react-router";
import Chart from 'react-apexcharts'
import API from "../../../Api";
import { connect } from "react-redux";
import { loginUserInfoAdmin } from '../../../Redux/Actions/AdminAuthenticationActions'


class QuarterlyBookingGraph extends Component {

  constructor(props) {
    super(props);
    this.state = {
      series: []
    }
  }

  getTranslations(key) {   

    if(this.props.Translations.translations.filter(p => p.appKey == key)[0] !=null )
    return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
    return "";
  }

  async componentDidMount() {
   
    new API().GetQuaterlyDataOfVisitsByCustomerType(this.props.selectedYear).then(result => {
      let seriesData = [];
      result.data.map(p => {
        seriesData.push({ name: p.customerType, data: p.quarterlyData });
      });
      this.setState({ series: seriesData });
    })
  }

  render() {
    if (this.props.AdminAuthentication.isLogin) {
      return <>
        <h6>  {this.getTranslations("Dashboard_QuarterlyBookingByType")}</h6>
        <Chart options={{
          chart: {
            height: 'auto',
            toolbar: {
              show: false
            }
          },         
          dataLabels: {
            enabled: false,           
          },
          xaxis: {
            categories: ['Jan-Mar', 'Apr-June', 'Jul-Sept', 'Oct-Dec']
          },
          stroke: {
            colors: ["transparent"],
            width: 3
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top'
              }
            }
          },
          yaxis: {
            labels: {
              formatter: (value) => {
                return value
              },
            }
          }
        }}
          series={this.state.series}
          type="bar" />
      </>

    }
    else {
      return <Navigate replace to="/admin" />;
    }
  }
}

const mapStateToProps = (state) => ({
  AdminAuthentication: state.AdminAuthentication,
  Translations : state.Translations
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(QuarterlyBookingGraph);