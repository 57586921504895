import { AUTHENTICATION } from './../Actions/Type'

let initialState = {
    accesstoken: '',
    tokenExpiry: ''
};

function AuthenticationReducer(state = initialState, action) {
    if (action.type == AUTHENTICATION)
        return { ...state, accesstoken: action.loginInfo.accesstoken, tokenExpiry: action.loginInfo.tokenExpiry }
    else
        return state;}


export default AuthenticationReducer;