import { CURRENTPAGE } from './../Actions/Type'

let initialState = {
    currentPage: '1'
};

function CurrentPageReducer(state = initialState, action) {
    if (action.type == CURRENTPAGE)
        return { ...state, currentPage: action.currentPage }
    else
        return state;
}


export default CurrentPageReducer;