import { CUSTOMERLUNCH } from './../Actions/Type'

let initialState = {
    lunch: false
};

function CustomerLunchReducer(state = initialState, action) {

    if (action.type == CUSTOMERLUNCH)
        return { ...state, lunch: action.lunch }
    else
        return state;
}

export default CustomerLunchReducer;