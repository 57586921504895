import { Component } from "react";
import { Modal } from 'react-bootstrap'
import { AllowOnlyAlphabatsWithBrackets } from "../Validation";

class UpdateTranslation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appKey: this.props.selectedTranslation != null ? this.props.selectedTranslation.appKey : '',
            fieldValue: this.props.selectedTranslation != null ? this.props.selectedTranslation.appValue : '',
            isfieldError: false
        }
    }

    onfieldchange = (e) => {
        this.setState({ fieldValue: e.target.value.replace(/ {2,}/g, ' ') })
        if (e.target.value.length >= 3)
            this.setState({ isfieldError: false })
    }

    onYesClick = (e) => {
        if (this.state.fieldValue.length >= 3)
            this.props.onConfirm(this.state.fieldValue)
        else
            this.setState({ isfieldError: true })
    }

    checkOnBlur = () => {
        if (this.state.fieldValue.length >= 3)
            this.setState({ isfieldError: false })
        else
            this.setState({ isfieldError: true })
    }

    render() {
        return (
            <div className="modal show" style={{ display: 'block', position: 'initial' }}>
                <Modal show={this.props.isPopupOpen} onHide={this.props.onCloseModel} >
                    <Modal.Header className="modal-footer justify-content-center">
                        <h5 id="exampleModalLabel">{this.props.isEditClick ? "Update" : "Add"} Translation </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-md-12 w-100">
                            <div className="mb-3">
                                <h6 > {this.state.appKey} </h6>
                            </div>
                        </div>

                        <div className="feedback-wrapper">
                            <div className="mt-3">
                                <textarea  onChange={this.onfieldchange} value={this.state.fieldValue} className={this.state.isfieldError ? "form-control cancelBox is-invalid" : "form-control cancelBox"} placeholder="Enter Location Name upto 25 characters"
                                    id="exampleFormControlTextarea1" name="fieldValue" rows="2" onBlur={() => this.checkOnBlur()}></textarea>
                            </div>
                        </div>
                        {
                            this.state.isfieldError ?
                                <div style={{ color: 'red' }}> Please provide atleast 3 characters.</div> : ''
                        }

                        {this.props.errorMessage != "" ? <h6 className="text-center err-msg-used">{this.props.errorMessage}</h6> : ""}



                        <div className="d-flex justify-content-center mt-4">
                            <button type="button" onClick={this.props.onCloseModel} className="btn outline-button btn-small" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" onClick={(e) => this.onYesClick(e)} className="btn fill-button btn-small">{this.props.isEditClick ? "Update" : "Save"}</button>
                        </div>
                    </Modal.Body>



                </Modal>

            </div>
        );
    }

}

export default UpdateTranslation;