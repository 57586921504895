import { faEdit } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { connect } from "react-redux";
import API from "../../../Api";
import UpdateConfiguration from "../ConfigurationsPopups/UpdateConfiguration";
import { loginUserInfoAdmin } from '../../../Redux/Actions/AdminAuthenticationActions'
import moment from "moment";
class MasterConfigurations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      MasterConfigurations: [],
      selectedConfiguration: {},
      isPopupOpen: false,
      showToast: false,
      toastMessage: '',
      toastHeader: ''
    };
  }

  async componentDidMount() {
    new API().getMasterConfigurations().then(result => {
      this.setState({ MasterConfigurations: result.data })
    })
  }

  onEditClick = (e) => {
    let id = e.target.id;
    if (e.target.nodeName == "path") {
      id = e.target.parentNode.id;
    }
    this.setState({ isPopupOpen: true, selectedConfiguration: this.state.MasterConfigurations.filter(p => p.id == id)[0] });
  }

  onCloseModel = (e) => {
    this.setState({ isPopupOpen: false });
  }

  onConfirm = (e) => {
    let toastMessage = '';
    if (this.state.selectedConfiguration != null && this.state.selectedConfiguration.id > 0) {

      toastMessage = 'Configuration updated successfully.';
      let configToAdd = {
        id: this.state.selectedConfiguration.id,
        ConfigurationValue: e,
        UpdatedBy: this.props.AdminAuthentication.Username,
        CreatedBy: 'System',
        ConfigurationId: this.state.selectedConfiguration.configurationId
      };


      new API().UpdateMasterConfigurations(configToAdd).then(result => {
        if (result.data) {
          this.setState({ showToast: true, toastHeader: 'Success', toastMessage: toastMessage, isPopupOpen: false });
          this.componentDidMount();
        }
        else {
          this.setState({ showToast: false, isPopupOpen: false });
        }


      });

    }
  }

  OnCloseToast = (e) => {
    this.setState({ showToast: !this.state.showToast });
  }


  render() {

    return <>

      <ToastContainer position="bottom-end">

        <Toast delay={10000} autohide show={this.state.showToast} onClose={this.OnCloseToast}>
          <Toast.Header>
            <strong className="me-auto"> {this.state.toastHeader}</strong>
          </Toast.Header>
          <Toast.Body className="bg-success text-white"> {this.state.toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <div className="d-flex justify-content-between align-items-center mob-colls">
        <h2> Master Configurations </h2>
      </div>

      <div className="table-responsive bg-white">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Configuration Value</th>
              <th scope="col">Created By</th>
              <th scope="col">Created Date</th>
              <th scope="col">Updated By</th>
              <th scope="col">Updated Date</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.MasterConfigurations.map((item, i) => {
              return [
                  <tr key={item.id}>
                  <td style={{ whiteSpace: "pre-wrap" }}>{item.displayName}</td>
                  <td style={{ whiteSpace: "pre-wrap" }}>{item.configurationValue}</td>
                  <td style={{ whiteSpace: "pre-wrap" }}>{item.createdBy}</td>
                  <td style={{ whiteSpace: "pre-wrap" }}>{moment(moment.utc(item.createdDate).toDate()).local().format('MMMM Do YYYY, HH:mm A')}</td>
                  <td style={{ whiteSpace: "pre-wrap" }}>{item.updatedBy}</td>
                  <td style={{ whiteSpace: "pre-wrap" }}>{item.updatedDate != null ? moment(moment.utc(item.modifiedDate).toDate()).local().format('MMMM Do YYYY, HH:mm A') : ""}</td>

                  <td> <button className="edit" onClick={this.onEditClick} id={item.id}> <FontAwesomeIcon onClick={this.onEditClick} id={item.id} icon={faEdit} /> </button>
                  </td>

                </tr>
              ]
            })}
          </tbody>
        </table>
      </div>

      {
        this.state.isPopupOpen ?
          <UpdateConfiguration selectedConfiguration={this.state.selectedConfiguration} isPopupOpen={this.state.isPopupOpen} onCloseModel={this.onCloseModel} onConfirm={this.onConfirm} isEditClick={this.state.isEditClick} type={"Location"} />
          : ""
      }

    </>
  }
}

const mapStateToProps = (state) => ({
  AdminAuthentication: state.AdminAuthentication,
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(MasterConfigurations);