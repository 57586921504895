import { Component } from "react";
import axios from "axios";
import API from "../../Api";
import { Navigate } from "react-router-dom";
import FluidIcon from '../../Images/fluid-ec.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faQuestionCircle } from "@fortawesome/fontawesome-free-solid";
import ReactFormInputValidation from "react-form-input-validation";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { connect } from "react-redux";
import { bookingInfo } from '../../Redux/Actions/BookingAction'
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonDisabled : false,
      otp: '',
      loginSubmitted: '',
      redirect: '/OTPValidation',
      fields:
      {
        name: this.props.BookingInfo.page1 && this.props.BookingInfo.page1.bookieName ? this.props.BookingInfo.page1.bookieName : '',
        email: this.props.BookingInfo.page1 && this.props.BookingInfo.page1.bookieEmail ? this.props.BookingInfo.page1.bookieEmail : ''
      },
      errors: {}
    }
    this.form = new ReactFormInputValidation(this);
    this.form.useRules({
      name: "required",
      email: "required|validate_email",
    });

    this.form.onformsubmit = (fields) => {
      // Do you ajax calls here.
      if (Object.keys(this.state.errors).length <= 0) {
        if(!this.state.isButtonDisabled)
        {
          this.setState({ isButtonDisabled : true } , ()=>{
            this.OnSubmitClick();
          });
        }     
      }
    }
    ReactFormInputValidation.registerAsync('validate_email', function (useremail, attribute, req, passes) {
      setTimeout(() => {
        const regex = /^[a-zA-Z0-9._:$!%-]+@(hcl|hcltech)\.com\b$/g;
        if (regex.test(useremail.toLowerCase()) === false) {
          passes(false, 'Invalid email. Please use HCL email id.');
        }
        else
          passes();
      }, 1000);
    });

    let message = ReactFormInputValidation.getMessages('en');
    message.required = 'This field is required.'
    ReactFormInputValidation.setMessages('en', message);
  }

  renderTooltip = (title) => (
    <Tooltip placement='right'>{title}</Tooltip>
  );

  OnSubmitClick = (e) => {
    let otp = (Math.floor(1000 + Math.random() * 9000));
    new API().getAccessToken().then(result => {
      const headers = {
        'Authorization': `Bearer ${result.data}`,
        'Content-Type': 'application/json'
      };      

      //insert login details with otp in DB         
      let loginInfo = {
        name: this.state.fields.name,
        email: this.state.fields.email,
        otp: otp,
        URL : process.env.REACT_APP_PORTAL_URL
      };
      new API().addLoginEntryOtp(loginInfo).then(result => {
        if(result.data.islogininserted)
        {
          this.sendOTPEmailToUser(headers, otp);
        this.props.bookingInfo({ page1: {
            isvalidated : false,
           bookieName: this.state.fields.name, 
           bookieEmail: this.state.fields.email } });
           localStorage.setItem("otptimer",result.data.timer); 
           this.setState({
            loginSubmitted: true                        
          })
        }       
      });

     
    }).catch(error =>{
      console.log(error)
      this.setState({ isButtonDisabled : false});
    });
  }

  exceptThisSymbols(key) {
    return !!((key.keyCode > 64 && key.keyCode < 91) || (key.keyCode > 96 && key.keyCode < 123) || key.keyCode == 8 || key.keyCode == 32 || key.keyCode == 9 || key.keyCode == 37 || key.keyCode == 39);
}

  sendOTPEmailToUser(headers, otp) {
    let bodyMsg =
      `<html><head></head><body><br />Dear ${this.state.fields.name}<br /><br /> Your OTP for login to DWP Fluid Booking Application is:<br />
             <span style=color:blue;>${otp}</span>              
             <br /> ${process.env.REACT_APP_PORTAL_URL}           
             <br /> <b>Thanks</b>
             Fluid Experience Centre Team      
            </body>
           </html>`
    bodyMsg = bodyMsg.replace(/(?:\r\n|\r|\n)/g, '<br>')
    let sendmessageBody = `{
           "message": {
           "subject": "OTP Confirmation Alert",
           "body": {
           "contentType": "Html",
           "content": "${bodyMsg}"
           },
           "toRecipients": [
           {
           "emailAddress": {
           "address": "${this.state.fields.email}"
           }
           }
           ]
           },
           "saveToSentItems": "true"
           }`
    const URL = `https://graph.microsoft.com/v1.0/users/fluidcentrebookingsystem@hcldwpdev.com/sendMail`;
    axios.post(URL, sendmessageBody, { headers })
      .then(response => {
      }).catch(error => console.log("error while sending mail", error));
  }


  render() {
    if (this.state.loginSubmitted) {
      return <Navigate to={this.state.redirect} />
    }
    return (<form onSubmit={this.form.handleSubmit} className="w-100">
      <div className="login-wrapper">
        <div className="login-box">
          <div className="row">
            <div className="col-md-12 banner text-center">
              <div className="w-50 mx-auto"> <img src={FluidIcon} className="img-fluid" alt="" /></div>
              <h6 className="mb-3"> Welcome to Fluid Experience Centre  </h6>
            </div>
          </div>
          <div className="row mx-0 login-form">
            <div className="col-md-12 mx-auto">
              <div className="form-group mb-3 position-relative">
                <label>Name</label> <OverlayTrigger overlay={this.renderTooltip('Enter the name of the Sales Lead / Account Manager or Client Partner driving this customer visit')}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
                {/* <FontAwesomeIcon icon={faUser}/> */}
                <input onKeyDown={e => !this.exceptThisSymbols(e) && e.preventDefault()} maxLength={40} style={{ textTransform: 'capitalize' }} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="text" name="name" value={this.state.fields.name} placeholder="Sales Lead / Account Manager or Client Partner" className={this.state.errors.name ? "form-control is-invalid" : "form-control"} />
                <label className="invalid-feedback">
                  {this.state.errors.name ? this.state.errors.name : ""}
                </label>
              </div>

              <div className="form-group mb-3 position-relative">
                <label>Email</label><OverlayTrigger overlay={this.renderTooltip('HCL Tech Email ID')}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
                {/* <FontAwesomeIcon icon={faEnvelope}/> */}
                <input type="text" name="email" onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} value={this.state.fields.email} placeholder="Enter your Email ID" className={this.state.errors.email ? "form-control is-invalid" : "form-control"} data-async />
                <label className="invalid-feedback">
                  {this.state.errors.email ? this.state.errors.email : ""}
                </label>
              </div>

              <div className="form-group mb-3 mt-4 text-center">
                <button disabled={this.state.isButtonDisabled} type="submit" className={this.state.isButtonDisabled ? "w-100 fill-button disabled" : "w-100 fill-button"}  data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal"> Generate OTP  <FontAwesomeIcon icon={faChevronRight} /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    )
  }
}

const mapStateToProps = (state) => ({ BookingInfo: state.BookingInfo })
export default connect(mapStateToProps, { bookingInfo })(Login);
