import { BOOKING_INFO1 } from './../Actions/Type'

let initialState = {
    page1: {
        isvalidated : false,
        locationId: '',
        visitTypeId: '',
        bookieName: '',
        bookieEmail: '',
        customerName: '',
        oppotunitySizeId: '',
        opportunityId: '',
        customerTypeId: '',
        regionId: '',
        subregionId: '',
        verticalId: '',
        timezone :'',
        disableFields : false
    }
};

function BookingInfoReducer(state = initialState, action) {
    if (action.type == BOOKING_INFO1)
        return { ...state, page1: action.bookingInfo.page1 }
    else
        return state;
}

export default BookingInfoReducer;