import { Component } from "react";
import { Navigate } from "react-router";
import Chart from 'react-apexcharts'
import API from "../../../Api";
import { connect } from "react-redux";
import { loginUserInfoAdmin } from '../../../Redux/Actions/AdminAuthenticationActions'


class FeedbackRatingGraph extends Component {

  constructor(props) {
    super(props);
    this.state = {
      options: {
        // chart: {
        //   events: {
        //     dataPointSelection: function(event, chartContext, config) {
        //       console.log(config)
        //       // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
        //     }
        //   }
        // },     
        // dataLabels: {
        //   formatter: function (val, opts) {
        //     return val + '%'
        //   }
        // },
        legend: {
          position: 'right'
        },
        labels: ["1 - 2", "3", "4 - 5"],
        colors: ['#ec4645', '#f6b532', '#1aab28'],
        fill:{
          colors: ['#ec4645', '#f6b532', '#1aab28']
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -25,
            },
          }
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return value + '%'
            },
          }
        }
      },
      series: [],
      rating: []

    }
  }

  async componentDidMount() {

    new API().GetCustomerFeedbackResults(this.props.selectedYear).then(result => {
      this.setState({
        series: result.data.percentage
      });
    })
  }

  getTranslations(key) {   

    if(this.props.Translations.translations.filter(p => p.appKey == key)[0] !=null )
    return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
    return "";
  }

  render() {
    if (this.props.AdminAuthentication.isLogin) {
      return <>
        <h6>
        {this.getTranslations("Dashboard_CustomerFeedback")}
        </h6>
        <Chart options={this.state.options}
          series={this.state.series}
          type="pie" />
      </>

    }
    else {
      return <Navigate replace to="/admin" />;
    }
  }
}

const mapStateToProps = (state) => ({
  AdminAuthentication: state.AdminAuthentication,
  Translations : state.Translations
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(FeedbackRatingGraph);