import { ADMINAUTHENTICATION } from './../Actions/Type'

let initialState = {
    Username: '',
    isLogin: false,
    role : ''
};

function AdminAuthenticationReducer(state = initialState, action) {
    if (action.type == ADMINAUTHENTICATION)
        return { ...state, Username: action.loginInfo.Username, isLogin: action.loginInfo.isLogin , role : action.loginInfo.role }
    else
        return state;

}

export default AdminAuthenticationReducer;