import { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import LunchOff from '../../Images/lunch-off.jpg'
import LunchOn from '../../Images/lunch-on.jpg'
import LunchOnPink from '../../Images/lunch-onPink.jpg'
import { loginUserInfoAdmin } from "../../Redux/Actions/AdminAuthenticationActions";
import { adminCurrentPage } from "../../Redux/Actions/AdminCurrentPageAction";
import { saveEditBookingInfo } from "../../Redux/Actions/AdminEditBookingAction";
import moment from "moment";
import API from "../../Api";
class EditPage4 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLunch: this.props.AdminEditBooking.bookingDetails.lunch,
      imagePath: LunchOff,
      isSubmitButtonDisabled: false
    };

  }

  PreviousButtonClick = () => {

    this.props.adminCurrentPage(3);
    this.props.sendData(3)
  }

  changeImage = (e) => {
    if (e.target.id == "yes") {
      this.setState({ imagePath: LunchOn, isLunch: true });
      let bookingDetails = this.props.AdminEditBooking.bookingDetails;
      bookingDetails.lunch = true;
      this.props.saveEditBookingInfo({ bookingDetails: bookingDetails });
    }
    else {
      this.setState({ imagePath: LunchOff, isLunch: false });
      let bookingDetails = this.props.AdminEditBooking.bookingDetails;
      bookingDetails.lunch = false;
      this.props.saveEditBookingInfo({ bookingDetails: bookingDetails });
    }
  }

  async saveData() {
    const products = [];
    // this.props.AdminEditBooking.bookingDetails.productsEdited.map(p => {
    //   products.push({ CategoryId: p.categoryId });
    // });

    const customers = [];
    this.props.AdminEditBooking.bookingDetails.customersEdited.map(p => {
      customers.push({ Id: p.id, Name: p.customerName, Role: p.customerRole, Email: p.customerEmail });
    });

    let bookingInfo = {
      BookingDetails: {
        id: this.props.AdminEditBooking.bookingDetails.id,
        location: this.props.AdminEditBooking.bookingDetails.locationId,
        VisitType: this.props.AdminEditBooking.bookingDetails.visitTypeId,
        bookieName: this.props.AdminEditBooking.bookingDetails.bookieName,
        bookieEmail: this.props.AdminEditBooking.bookingDetails.bookieEmail,
        customerName: this.props.AdminEditBooking.bookingDetails.customerName,
        visitingPartySize: this.props.AdminEditBooking.bookingDetails.sizeOfVisitingParty,
        participantsSize: this.props.AdminEditBooking.bookingDetails.hclParticipants,
        customerType: this.props.AdminEditBooking.bookingDetails.customerTypeId != "" ? this.props.AdminEditBooking.bookingDetails.customerTypeId : 0,
        bookingSlot: this.props.AdminEditBooking.bookingDetails.timeSlotId,
        bookingDate: moment(this.props.AdminEditBooking.bookingDetails.selectedDate),
        bookingEndDate: moment(this.props.AdminEditBooking.bookingDetails.selectedEndDate),
        opportunityId: this.props.AdminEditBooking.bookingDetails.opportunityId,
        opportunitySize: this.props.AdminEditBooking.bookingDetails.oppotunitySizeId != "" ? this.props.AdminEditBooking.bookingDetails.oppotunitySizeId : 0,
        lunchRequired: this.props.AdminEditBooking.bookingDetails.lunch,
        bookingtime: '',
        regionId: this.props.AdminEditBooking.bookingDetails.regionId,
        vertical: this.props.AdminEditBooking.bookingDetails.verticalId,
        subregionId: this.props.AdminEditBooking.bookingDetails.subregionId,
      },
      Customers: customers,
      Products: [],
      StartDate : this.props.AdminEditBooking.bookingDetails.selectedDateTimeZone,
      EndDate : this.props.AdminEditBooking.bookingDetails.selectedEndDateTimeZone
    };

    new API().updateBookingByAdmin(bookingInfo).then(result => {
      if (result.data.isError) {
        this.props.displayErrorMessage(result.data.errorMessage);
      }
      else {
        this.props.setBookingconfirmationId(result.data.id);
        this.props.adminCurrentPage(5);
        this.props.sendData(5)
      }
    });
  }


  NextButtonClick = () => {
    this.setState({ isSubmitButtonDisabled: true });
    this.saveData();
  }

  getTranslations(key) {

    if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
      return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
      return "";
  }


  render() {
    return <fieldset className="wizard-fieldset show">
      <div className="col-md-12 right-section text-center">
        <h6 className="text-center pb-1"> {this.getTranslations("User_BookingLunchPage_Heading")} </h6>
        <div className={this.state.isSubmitButtonDisabled ? "col-md-6 mt-4 mb-2 mx-auto stv-radio-buttons-wrapper disabled" : "col-md-6 mt-4 mb-2 mx-auto stv-radio-buttons-wrapper " }>
          <input defaultChecked={this.state.isLunch} type="radio" className="stv-radio-button en-nn" name="isLunch" value="1" id="yes" />
          <label id="yes" htmlFor="yes" onClick={this.changeImage} >YES</label>
          <input defaultChecked={!this.state.isLunch} type="radio" className="stv-radio-button en-nn" name="isLunch" value="3" id="no" />
          <label id="no" htmlFor="no" onClick={this.changeImage}>NO</label>
        </div>
        {!this.state.isLunch ? <img id="myImage" src={LunchOff} width="200" className="mb-2" />
          : <img id="myImage" src={localStorage.getItem("LunchImageColor") == 'pink' ? LunchOnPink : LunchOn} width="200" className="mb-2" />}

        <div className="col-md-6 mt-4 mb-2 mx-auto">

          <div className="wizard-form-error"></div>
        </div>

      </div>
    
      <div className="text-center LunchNote"> {this.getTranslations("User_BookingLunchPage_Note")} </div>

      <div className="form-group clearfix row btn-fix-bottom">
        <div className="text-center main-btn-fix">
          <button disabled={this.state.isSubmitButtonDisabled} onClick={this.PreviousButtonClick} className={this.state.isSubmitButtonDisabled ? "form-wizard-previous-btn float-left disabled" : "form-wizard-previous-btn float-left" }>Previous</button>
          <button disabled={this.state.isSubmitButtonDisabled} onClick={this.NextButtonClick} className={this.state.isSubmitButtonDisabled ? "form-wizard-next-btn float-right disabled" : "form-wizard-next-btn float-right" }>Save <i className="fa fa-angle-right"></i></button>
        </div>
      </div>

    </fieldset>


  }
}

const mapStateToProps = (state) => ({ 
  Translations: state.Translations,
  AdminAuthentication: state.AdminAuthentication,
  AdminEditBooking: state.AdminEditBooking,
  AdminCurrentPage: state.AdminCurrentPage,
  AdminInitialData: state.AdminInitialData
})

export default connect(mapStateToProps, { loginUserInfoAdmin, adminCurrentPage, saveEditBookingInfo })(EditPage4);
