import { BOOKING_INFO2 } from '../Actions/Type'

let initialState = {
    page2: {
        sizeOfVisitingParty: '',
        hclParticipants: '',
        timeSlotId: '',
        selectedDate: '',
        selectedTime: '',
        selectedEndDate: '',
        selectedDateTimeZone: '',
        selectedEndDateTimeZone: ''
    }
};

function BookingInfoNextReducer(state = initialState, action) {
    if (action.type == BOOKING_INFO2)
        return { ...state, page2: action.bookingInfo.page2 }
    else
        return state;

}

export default BookingInfoNextReducer;