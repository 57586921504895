import { Component } from "react";
import API from "../../Api";
import { Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/fontawesome-free-solid";
import ReactFormInputValidation from "react-form-input-validation";
import moment from "moment";
import WarningConsent from "../AdminDashboard/WarningConsent";


class EditDateOnly extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: this.props.selectedDetails.id,
            displayCustomerText: this.props.selectedDetails.visitingPartySize > 1 ? " Customers + " : " Customer + ",
            selectedDetails: this.props.selectedDetails,
            isDateValid: true,
            fields: {
                selectedDate: this.selectTimeZoneDate(this.props.selectedDate, this.props.selectedDetails.timezone),
                selectedEndDate: this.selectTimeZoneDate(this.props.selectedEndDate, this.props.selectedDetails.timezone),
                
            },
            oldSelectedDate: this.props.selectedDate,
            oldselectedEndDate: this.props.selectedEndDate,
            lunchRequired: this.props.selectedDetails.lunchRequired ? "Yes" : "No",
            errors: {},
            isButtonDisabled: false,
            isWarningOpen: false
        }

        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
            selectedDate: "required",
            selectedEndDate: 'required'
        });

        this.form.onformsubmit = (fields) => {
            if (Object.keys(this.state.errors).length === 0 && this.state.isDateValid) {
                this.setState({ isButtonDisabled: true });
                this.SaveData();
            }
        }
        let message = ReactFormInputValidation.getMessages('en');
        message.required = 'This field is required.'
        ReactFormInputValidation.setMessages('en', message);
    }

    selectTimeZoneDate(date, timezone) {
        const dateWithoutZone = moment.tz(date, timezone).format("YYYY-MM-DD HH:mm")
        const localZone = moment(dateWithoutZone).format("Z")
        const dateWithLocalZone = [dateWithoutZone, localZone].join("")
        return new Date(dateWithLocalZone);
    }

    onCloseWarningModel = (e) => {
        this.setState({ isWarningOpen: false })
    }

    SaveData = () => {
        let bookingInfo = {
            Id: this.state.selectedId,
            StartDate: moment.tz(moment(this.state.fields.selectedDate).format('YYYY-MM-DD HH:mm:ss'), this.state.selectedDetails.timezone).format(),
            EndDate: moment.tz(moment(this.state.fields.selectedEndDate).format('YYYY-MM-DD HH:mm:ss'), this.state.selectedDetails.timezone).format(),
            UpdatedBy: this.props.updatedBy
        };
        
        if ((this.state.oldSelectedDate != this.state.fields.selectedDate) ||
            (this.state.oldselectedEndDate != this.state.fields.selectedEndDate)) {
                               
                if(this.state.fields.selectedDate > this.state.fields.selectedEndDate)
                {
                    this.setState({ isWarningOpen: true , isButtonDisabled : false })
                }
                else
                {
                    new API().updateBookingDate(bookingInfo).then(result => {
                        this.props.onCloseModelAfterUpdate(true);
                   })
                }

                
        }
        else
        {
            this.props.onCloseModelAfterUpdate(false);
        }

        
    }

    OnEndDateChange = (value) => {
        if (this.state.fields.selectedDate < value)
            this.setState({ fields: { ...this.state.fields, selectedEndDate: value } });
        else
            this.setState({ isWarningOpen: true })
    }



    OnDateChange = (value) => {
        this.setState({ fields: { ...this.state.fields, selectedDate: value } });
    }

    getTranslations(key) {

        if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
            return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
            return "";
    }

    render() {

        return (
            <div className="modal show" style={{ display: 'block', position: 'initial' }}>

                <Modal
                    show={this.props.isOpen}
                    onHide={this.props.onCloseModel}
                    className="cust-modal"
                >

                    <Modal.Header>
                        <h5 id="exampleModalLabel">{this.getTranslations("Admin_ConfirmPage_Heading")}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.form.handleSubmit}>
                            <div className="form-wizard">
                                <div className="form-group row">
                                    <div className="col-md-6 mob-margin">
                                        <label> {this.getTranslations("Admin_ConfirmPage_Name")}</label>
                                        <input type="text" name="bookieName" value={this.state.selectedDetails.bookieName} disabled className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="col-md-6">
                                        <label> {this.getTranslations("Admin_ConfirmPage_Email")}</label>
                                        <input type="email" name="customerEmail" value={this.state.selectedDetails.bookieEmail} disabled className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6 mob-margin">
                                        <label> {this.getTranslations("Admin_ConfirmPage_VisitingCustomer")}</label>
                                        <input type="text" name="customerName" value={this.state.selectedDetails.customerName} disabled className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="col-md-6">
                                        <label> {this.getTranslations("Admin_ConfirmPage_Guests")}</label>
                                        <input type="text" name="customerGuest" disabled className="form-control" value={(this.state.selectedDetails.visitingPartySize + this.state.selectedDetails.participantsSize) + " Guests (" + this.state.selectedDetails.visitingPartySize + this.state.displayCustomerText + this.state.selectedDetails.participantsSize + " HCL)"} id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-md-6 mob-margin">
                                        <label> {this.getTranslations("Admin_ConfirmPage_Vertical")}</label>
                                        <input type="text" name="vertical" value={this.state.selectedDetails.verticalName} disabled className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="col-md-6">
                                        <label> {this.getTranslations("Admin_ConfirmPage_GEO")} </label>
                                        <input type="text" name="Region" disabled className="form-control" value={(this.state.selectedDetails.regionName + " - " + this.state.selectedDetails.subRegionName)} id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-md-6 mob-margin">
                                        <label> {this.getTranslations("Admin_ConfirmPage_VisitType")}</label>
                                        <input type="text" name="vertical" value={this.state.selectedDetails.visitType} disabled className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="col-md-6">
                                        <label> {this.getTranslations("Admin_ConfirmPage_Lunch")}</label>


                                        <select disabled name="lunchRequired" className="form-select" value={this.state.lunchRequired} >
                                            <option value="Yes" id="Yes"> Yes</option>
                                            <option value="No" id="No"> No</option>
                                        </select>


                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-md-6 mob-margin form-group position-relative">
                                        <label>{this.getTranslations("Admin_ConfirmPage_StartDate")}</label>
                                        <br />

                                        <DatePicker dateFormat="MM/dd/yyyy HH:mm a"
                                            name="selectedDate"
                                            selected={this.state.fields.selectedDate}
                                            onChange={(date) => this.OnDateChange(date)}
                                            className="form-control"

                                            showTimeSelect
                                            onChangeRaw={(e) => e.preventDefault()}
                                        />
                                        <FontAwesomeIcon className="calendar-icon2" icon={faCalendarAlt} />

                                        {
                                            !this.state.isDateValid ?
                                                <div style={{ color: 'red' }}>Selected slot is not available. Try again</div> : ''
                                        }



                                    </div>

                                    <div className="col-md-6 mob-margin form-group position-relative">
                                        <label>{this.getTranslations("Admin_ConfirmPage_EndDate")}</label>
                                        <br />


                                        <DatePicker dateFormat="MM/dd/yyyy HH:mm a"
                                            name="selectedDate"
                                            selected={this.state.fields.selectedEndDate}
                                            onChange={(date) => this.OnEndDateChange(date)}
                                            className="form-control"

                                            showTimeSelect
                                            onChangeRaw={(e) => e.preventDefault()}
                                        />
                                        <FontAwesomeIcon className="calendar-icon2" icon={faCalendarAlt} />

                                        {
                                            !this.state.isDateValid ?
                                                <div style={{ color: 'red' }}>Selected slot is not available. Try again</div> : ''
                                        }



                                    </div>



                                </div>


                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <button onClick={this.props.onCloseModel} type="button" className="btn outline-button btn-small" data-bs-dismiss="modal">{this.getTranslations("Admin_ConfirmPage_ButtonClose")}</button>
                                <button disabled={this.state.isButtonDisabled} className="btn fill-button btn-small" type="submit" >{this.getTranslations("Admin_ConfirmPage_ButtonConfirm")}</button> &nbsp;
                            </div>
                        </form>

                        {
                            this.state.isWarningOpen ?
                                <WarningConsent infoMessage="End date should be greater than start date." isWarningOpen={this.state.isWarningOpen} onCloseModel={this.onCloseWarningModel} />
                                : ""
                        }

                    </Modal.Body>


                </Modal></div>
        )
    }

}

export default EditDateOnly;