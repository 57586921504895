import { Component } from "react";
import { Navigate } from "react-router-dom";
import API from "../../Api";
import FluidLogo from '../../Images/logo-fluid-studio.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/fontawesome-free-solid";
import moment from "moment";
import { Toast, ToastContainer } from 'react-bootstrap'
import "react-datepicker/dist/react-datepicker.css";
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import ReactPaginate from 'react-paginate'
import GuestDetails from '../Admin/GuestDetails';
import Loader from "../Loader";
import { DateRangePicker } from 'rsuite';
import { loginUserInfoAdmin } from '../../Redux/Actions/AdminAuthenticationActions'
import { connect } from "react-redux";
import { leftMenuSelection } from '../../Redux/Actions/AdminLeftMenuAction'
import { addtranslation } from "../../Redux/Actions/TranslationAction";
import EditDateOnly from "./EditDateOnly";

class AdminBookingListForEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bookings: [],
            isLoading: true,
            selectedId: '',
            isGuestPopupOpen: false,
            isEditPopupOpen: false,
            startDate: new Date(),
            endDate: new Date(),
            startDateFilterValue: null,
            endDateFilterValue: null,
            value: [],
            selectedDetails: {},
            activePage: 1,
            TotalRecords: 0,
            RecordPerPage: 0,
            showToast: false,
            toastMessageHeader: 'Booking Status',
            toastMessage: '',
            isCancelToast: false
        };
    }

    onEditClick = (e) => {
        this.setState({
            selectedId: e.target.id,
            selectedDetails: this.state.bookings.filter(p => p.id == e.target.id)[0],
            isEditPopupOpen: true,
            selectedEndDate: moment.utc(this.state.bookings.filter(p => p.id == e.target.id)[0].bookingEndDate).toDate(),
            selectedDate: moment.utc(this.state.bookings.filter(p => p.id == e.target.id)[0].bookingDate).toDate()

        });
    }

    onGuestActionClick = (e) => {
        this.setState({
            selectedId: e.target.id,
            selectedDetails: this.state.bookings.filter(p => p.id == e.target.id)[0],
            isGuestPopupOpen: true
        });

    }

    onCloseModel = (e) => {
        this.setState({ isGuestPopupOpen: false, isEditPopupOpen: false });
    }

    onCloseModelAfterUpdate = (e) => {
       if(e)
       {
        this.setState({ isGuestPopupOpen: false, isEditPopupOpen: false, showToast: true, toastMessageHeader: 'Success', toastMessage: 'updated successfully.' });
        this.getBookings(0, this.state.activePage);
       }  
       else
       {
        this.setState({ isGuestPopupOpen: false, isEditPopupOpen: false });
       
       }
    }

    handleSubmitClick = (value) => {
        let startDateValue = moment(value[0]).format('YYYY-MM-DD')
        let endDateValue = moment(value[1]).format('YYYY-MM-DD hh:mm')
        this.setState({ startDateFilterValue: startDateValue, endDateFilterValue: endDateValue })
        this.getDateFilteredBookings(0, 1, startDateValue, endDateValue);
    }

    OnPageClick = (e) => {
        this.setState({ activePage: e.selected + 1 });
        if (this.state.startDateFilterValue == null && this.state.endDateFilterValue == null) {
            this.getBookings(0, e.selected + 1);
        }
        else {
            this.getDateFilteredBookings(0, e.selected + 1, this.state.startDateFilterValue, this.state.endDateFilterValue);
        }
    }

    async getBookings(filterId, pageno) {
        await new API().getFilterBookings(filterId, pageno).then(result => {
            this.setState({
                bookings: result.data.bookings,
                isLoading: false,
                TotalRecords: result.data.totalCount,
                RecordPerPage: result.data.recordPerPage,
                isGuestPopupOpen: false
            });
        });
    }

    async getDateFilteredBookings(filterId, pageno, startDateVal, endDateVal) {
        await new API().getDateFilteredBookings(filterId, pageno, startDateVal, endDateVal).then(result => {
            this.setState({
                bookings: result.data.bookings,
                isLoading: false,
                regions: result.data.regions,
                TotalRecords: result.data.totalCount,
                RecordPerPage: result.data.recordPerPage,
                isGuestPopupOpen: false
            });
        });
    }

    OnDateChange = (value) => {
        this.setState({ startDate: value != null ? value[0] : null, endDate: value != null ? value[1] : null, value: value });

        if (value == null) {
            this.getBookings(0, 1);
        }

    }

    async componentDidMount() {

        await new API().getFilterBookings(0, this.state.activePage).then(result => {
            this.setState({
                bookings: result.data.bookings,
                isLoading: false,
                TotalRecords: result.data.totalCount,
                RecordPerPage: result.data.recordPerPage
            });
        });

    }

    OnCloseToast = (e) => {
        this.setState({ showToast: !this.state.showToast });
    }

    getTranslations(key) {

        if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
            return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
            return "";
    }

    render() {

        if (this.props.AdminAuthentication.isLogin) {
            return <>

                <ToastContainer position="bottom-end">
                    <Toast delay={10000} autohide show={this.state.showToast} onClose={this.OnCloseToast}>
                        <Toast.Header>
                            <strong className="me-auto">{this.state.toastMessageHeader}</strong>
                        </Toast.Header>
                        <Toast.Body className={this.state.isCancelToast ? "bg-danger text-white" : "bg-success text-white"}> Booking ID {this.state.selectedDetails.displayId} {this.state.toastMessage}.</Toast.Body>
                    </Toast>
                </ToastContainer>

                <div id="wrapper-adminBookingList" >


                    <section className="wizard-section booking-area px-1">
                        <h2 className="text-center"><img src={FluidLogo} width="200" alt="Fluid Studio" /></h2>

                        <div className="row mb-3">

                            <div className="col-md-12 d-flex justify-content-between align-items-center customFilter">

                                <div className="d-flex w-100">

                                    <DateRangePicker
                                        size="sm"
                                        name="selectedDate"
                                        placeholder="Select Booking Date Range"
                                        onChange={this.OnDateChange}
                                        // onChangeRaw={(e) => e.preventDefault()}
                                        value={this.state.value}
                                        showOneCalendar={true}
                                        onOk={this.handleSubmitClick}
                                        ranges={[]}
                                        character=' - '
                                        format='dd/MMM/yyyy'
                                    />

                                </div>
                                {
                                    this.state.bookings.length > 0 ?

                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={this.OnPageClick}
                                            pageRangeDisplayed={1}
                                            marginPagesDisplayed={1}
                                            pageCount={(this.state.TotalRecords > this.state.RecordPerPage) ? Math.ceil(this.state.TotalRecords / this.state.RecordPerPage) : 1}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            className="cust-pagination mx-2"
                                        /> : ""
                                }
                            </div>
                        </div>
                        <div className="row">
                            {
                                this.state.isLoading ? <Loader /> :

                                    <div className="col-md-12 text-center table-responsive min-h">

                                        {
                                            this.state.bookings.length == 0 ? <div className="nobookingtext">No Booking Found</div> : ""
                                        }

                                        <Table id="example"  >

                                            <Tbody>
                                                {this.state.bookings.map((item, i) => {
                                                    return [
                                                        <Tr key={item.id}>
                                                            <Td> {this.getTranslations("Admin_BookingListPage_BookingId")} : <span className="date-bold"> {item.displayId} </span>
                                                                <div> {this.getTranslations("Admin_BookingListPage_BookieName")} : <span> {item.bookieName} </span></div>
                                                            </Td>
                                                            <Td>
                                                                <div> {this.getTranslations("Admin_BookingListPage_OrgName")} : <span> {item.customerName} </span></div>
                                                                <div> {this.getTranslations("Admin_BookingListPage_Email")} : <span> {item.bookieEmail}  </span> </div>


                                                            </Td>
                                                            <Td>
                                                                <div> {this.getTranslations("Admin_BookingListPage_StartDate")} : <span className="date-bold"> {moment.tz(moment.utc(item.bookingDate),item.timezone).format('MMM Do YYYY, HH:mm A')}   </span></div>

                                                                <div> {this.getTranslations("Admin_BookingListPage_EndDate")} : <span className="date-bold">  {moment.tz(moment.utc(item.bookingEndDate),item.timezone).format('MMM Do YYYY, HH:mm A')}   </span></div>

                                                            </Td>

                                                            <Td>
                                                                <div> {this.getTranslations("Admin_BookingListPage_VisitType")} : {item.visitType} </div>
                                                                <div> {this.getTranslations("Admin_BookingListPage_SizeOfVisitParty")} : <a onClick={this.onGuestActionClick} id={item.id} className="edit date-bold" data-bs-toggle="modal" data-bs-target="#guestModal"><span id={item.id}> {item.visitingPartySize} </span> </a> </div>
                                                            </Td>

                                                            <Td>
                                                                <div> {this.getTranslations("Admin_BookingListPage_Lunch")} : <span> {item.lunchRequired ? 'Yes' : 'No'} </span></div>
                                                                <div> {this.getTranslations("Admin_BookingListPage_Location")} : <span className="date-bold"> {item.location} </span></div>
                               
                                                            </Td>

                                                            <Td>
                                                                <div className="dropdown">
                                                                    <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-h">
                                                                            <FontAwesomeIcon icon={faEllipsisH}></FontAwesomeIcon>
                                                                        </i>
                                                                    </button>

                                                                    {
                                                                        <ul className="dropdown-menu dropdown-menu-buttons" aria-labelledby="dropdownMenuButton1">
                                                                            <li><a name="edit" onClick={this.onEditClick} id={item.id} className="edit">{this.getTranslations("Admin_BookingListPage_ButtonEdit")}</a></li>
                                                                        </ul>
                                                                    }

                                                                </div>

                                                            </Td>
                                                        </Tr>
                                                    ]

                                                })}

                                            </Tbody>
                                        </Table>

                                    </div>}
                        </div>

                    </section>


                </div>

                {this.state.isGuestPopupOpen && <GuestDetails Translations={this.props.Translations} isGuestPopupOpen={this.state.isGuestPopupOpen} onCloseModel={this.onCloseModel} customers={this.state.selectedDetails.customers} />}
                {this.state.isEditPopupOpen && <EditDateOnly
                   updatedBy ={this.props.AdminAuthentication.Username}
                    Translations={this.props.Translations} isOpen={this.state.isEditPopupOpen}
                    onCloseModel={this.onCloseModel}
                    onCloseModelAfterUpdate={this.onCloseModelAfterUpdate}
                    selectedDetails={this.state.selectedDetails}
                    selectedDate={this.state.selectedDate}
                    selectedEndDate={this.state.selectedEndDate}
                    selectedName={this.state.selectedName}
                    getBookings={this.getBookings} />}

            </>
        }
        else {
            return <Navigate replace to="/admin" />;
        }
    }
}

const mapStateToProps = (state) => ({
    AdminAuthentication: state.AdminAuthentication,
    AdminLeftMenu: state.AdminLeftMenu,
    Translations: state.Translations
})

export default connect(mapStateToProps, { addtranslation, loginUserInfoAdmin, leftMenuSelection })(AdminBookingListForEdit);