import { Component } from "react";
import API from "../../Api";
import FluidLogo from '../../Images/logo-fluid-studio.png'
import BackgroundImage from '../../Images/bg.png'
import $ from 'jquery';
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import { connect } from "react-redux";
import { loginUserInfo } from '../../Redux/Actions/AuthenticationActions'
import Page6 from "./Page6";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faTicketAlt, faTimes, faUserCircle } from "@fortawesome/fontawesome-free-solid";
import { bookingInfo } from '../../Redux/Actions/BookingAction'
import { addtranslation } from "../../Redux/Actions/TranslationAction";
import { bookingInfoNext } from '../../Redux/Actions/BookingActionNext'
import { bookingProducts, clearBookingProducts } from '../../Redux/Actions/BookingProductsAction'
import { currentPage } from '../../Redux/Actions/CurrentPageAction'
import { customerLunch } from '../../Redux/Actions/CustomerLunchAction'
import { clearCustomersList } from '../../Redux/Actions/CustomersListAction'
import { Navigate } from "react-router-dom";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import helpGuide from '../../UserGuide.pdf'


class Booking extends Component {

  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      customerTypes: [],
      OpportunitySizes: [],
      timeSlots: [],
      regions: [],
      verticals: [],
      visitType: [],
      isLoading: true,
      currentPage: this.props.CurrentPage.currentPage,
      bookingConfirmationId: '',
      minimumBookingTimeFromCurrentDateInMinutes: 1,
      redirect: '',
      applicationVersion: ''
    };
  }

  async componentDidMount() {

    await new API().getInitialDataForBookings().then(result => {
      this.props.addtranslation(result.data.translations)
      localStorage.setItem("isValidationRequired", result.data.isBookingSlotValidationRequired);
      localStorage.setItem("dateRangeSelectionInMonths", result.data.dateRangeSelectionInMonths);
      localStorage.setItem("LunchImageColor", result.data.lunchImageColor);
      this.setState({
        timeSlots: result.data.timeSlots,
        visitType: result.data.visitType,
        locations: result.data.locations,
        regions: result.data.regions,
        verticals: result.data.verticals,
        customerTypes: result.data.customerType,
        OpportunitySizes: result.data.opportunitySize,
        isLoading: false,
        applicationVersion: result.data.applicationVersion,
        minimumBookingTimeFromCurrentDateInMinutes: result.data.minimumBookingTimeFromCurrentDateInMinutes
      });



    });
  }

  onMyBookingClick = (e) => {
    this.setState({ redirect: '/mybookings' });
  }

  navBarToggle = (e) => {
    $(".custom-nav").toggleClass("opensidebar");
  }

  getOnlyFirstName(value) {
    let onlyName = value.split(" ")[0];
    return onlyName.charAt(0).toUpperCase() + onlyName.slice(1);
  }

  getFirstLetterUpper(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  signOutfromApp = (e) => {
    this.props.bookingInfo({
      page1: {
        locationId: '',
        visitTypeId: '',
        bookieName: '',
        bookieEmail: '',
        customerName: '',
        oppotunitySizeId: '',
        opportunityId: '',
        customerTypeId: '',
        regionId: '',
        subregionId: '',
        verticalId: '',
        timezone: '',
        disableFields: false
      }
    });
    this.props.bookingInfoNext({
      page2: {
        sizeOfVisitingParty: '',
        hclParticipants: '',
        timeSlotId: '',
        selectedDate: new Date(),
        selectedEndDate: new Date(),
        selectedTime: '',
      }
    });
    this.props.clearBookingProducts();
    this.props.currentPage(1);
    this.props.customerLunch(false);
    this.props.clearCustomersList();
    localStorage.clear();


    this.setState({ redirect: '/' });

  }


  sendData = (data) => {
    this.setState({ currentPage: data })
  }

  setBookingconfirmationId = (data) => {
    this.setState({ bookingConfirmationId: data });
  }

  handletoggleWindow = (e) => {
    let closeQuickActionWindow = true;
    if (e.target.getAttribute("class") && (e.target.getAttribute("class").includes('navbar-toggler') || e.target.getAttribute("class").includes('fa-user-circle')))
      closeQuickActionWindow = false;

    let nodes = document.getElementsByClassName("custom-nav");

    if (nodes.length > 0 && closeQuickActionWindow)
      nodes[0].setAttribute("class", "custom-nav")
  };
  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }

    return <>
      <div id="wrapper" onClick={(e) => this.handletoggleWindow(e)}>
        <div id="sidebar">
          <img src={BackgroundImage} width="100%" alt="Fluid Studio" />
        </div>

        <div className="content-area">
          <section className="wizard-section">
            <div className="row no-gutters">

              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                  <h2 className="welcome-user navbar-toggler" onClick={this.navBarToggle}> Welcome {this.getOnlyFirstName(this.props.BookingInfo.page1.bookieName)} <FontAwesomeIcon className="ms-1" icon={faUserCircle} size="xl"></FontAwesomeIcon> </h2>
                  <button className="navbar-toggler hide-desk" onClick={this.navBarToggle} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="custom-nav" >
                    <div className="user-profile-detail" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                      <span className="user-circle"> <i className='fas fa-user-circle'><FontAwesomeIcon icon={faUserCircle}></FontAwesomeIcon></i></span>
                      <div className="ps-2 d-flex justify-content-center flex-column">
                        <div className="name-user"> {this.getFirstLetterUpper(this.props.BookingInfo.page1.bookieName)} </div>
                        <div className="email"> {this.props.BookingInfo.page1.bookieEmail} </div>
                      </div>
                    </div>

                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                        <a onClick={this.onMyBookingClick} className="nav-link" aria-current="page" > <i ><FontAwesomeIcon icon={faTicketAlt}></FontAwesomeIcon></i> My Bookings</a>
                        <a className="nav-link" aria-current="page" target="_blank" href={helpGuide}> <i ><FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon></i> Help</a>
                        <a onClick={this.signOutfromApp} className="nav-link for-mobile" aria-current="page" > <i ><FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon></i> Sign out</a>
                        <span className="version"> Version :  {this.state.applicationVersion} </span>
                      </li>

                    </ul>
                    <a onClick={this.signOutfromApp} className="closesidebar for-desktop"> Sign out </a>
                    <a onClick={this.navBarToggle} className="closesidebar for-mobile"> <i className='fas fa-times'><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon></i> </a>

                  </div>
                </div>
              </nav>

              <div className="col-lg-12 col-md-12">
                <div className="form-wizard">
                  <div className="form-wizard-header">
                    <h2><img src={FluidLogo} width="200" alt="Fluid Studio" /></h2>
                    <div className="mb-2 for-mobile welcome-user"> Welcome {this.getOnlyFirstName(this.props.BookingInfo.page1.bookieName)} </div>
                    <ul className="list-unstyled form-wizard-steps clearfix">
                      <li id="1" className={this.state.currentPage == 1 ? 'active' : 'activated'}><span></span></li>
                      <li id="2" className={this.state.currentPage == 2 ? 'active' : this.getActivatedClass(2)}><span></span></li>
                      <li id="3" className={this.state.currentPage == 3 ? 'active' : this.getActivatedClass(3)}><span></span></li>
                      <li id="4" className={this.state.currentPage == 4 ? 'active' : this.getActivatedClass(4)}><span></span></li>
                      <li id="5" className={this.state.currentPage == 5 ? 'active' : this.getActivatedClass(5)}><span></span></li>
                    </ul>
                  </div>
                  {
                    this.getPageToDisplay()
                  }
                </div>
              </div>
            </div>
          </section>
        </div>

      </div>
      <footer className="text-center footer-booking">
        Copyright © {new Date().getFullYear()} HCL Technologies Limited
      </footer>
    </>

  }

  getPageToDisplay() {

    if (this.state.currentPage == 1) {
      return <Page1 sendData={this.sendData} regions={this.state.regions} visitType={this.state.visitType} verticals={this.state.verticals} OpportunitySizes={this.state.OpportunitySizes} locations={this.state.locations} customerTypes={this.state.customerTypes}></Page1>;
    }
    else if (this.state.currentPage == 2) {
      return <Page2 sendData={this.sendData} timeSlots={this.state.timeSlots} minimumBookingTimeFromCurrentDateInMinutes={this.state.minimumBookingTimeFromCurrentDateInMinutes}></Page2>;

    }
    else if (this.state.currentPage == 3) {
      return <Page3 sendData={this.sendData} sizeOfVisitingParty={this.props.BookingInfoNext.page2.sizeOfVisitingParty}></Page3>;

    }
    else if (this.state.currentPage == 4) {
      return <Page4 locationId={this.props.BookingInfo.page1.locationId} setBookingconfirmationId={this.setBookingconfirmationId} sendData={this.sendData}></Page4>
    }
    // else if (this.state.currentPage == 5) {
    //   return <Page5  setBookingconfirmationId={this.setBookingconfirmationId} sendData={this.sendData} locationId={this.props.BookingInfo.page1.locationId}></Page5>

    // }
    else {
      return <Page6 bookingId={this.state.bookingConfirmationId}></Page6>;
    }

  }

  getActivatedClass(number) {
    return this.state.currentPage > number ? 'activated' : '';
  }
}

const mapStateToProps = (state) => ({
  BookingInfo: state.BookingInfo,
  BookingInfoNext: state.BookingInfoNext,
  CurrentPage: state.CurrentPage,
  BookingProducts: state.BookingProducts,
  CustomerLunch: state.CustomerLunch,
  CustomersList: state.CustomersList,
  Translations: state.Translations
})

export default connect(mapStateToProps, { bookingInfo, clearBookingProducts, bookingInfoNext, currentPage, clearCustomersList, customerLunch, bookingProducts, loginUserInfo, addtranslation })(Booking);