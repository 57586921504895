import { Component } from "react";
import { connect } from "react-redux";
import checkImage from '../../Images/check.png'
import { bookingInfo } from '../../Redux/Actions/BookingAction'
import { bookingInfoNext } from '../../Redux/Actions/BookingActionNext'
import { bookingProducts, clearBookingProducts } from '../../Redux/Actions/BookingProductsAction'
import { currentPage } from '../../Redux/Actions/CurrentPageAction'
import { customerLunch } from '../../Redux/Actions/CustomerLunchAction'
import { clearCustomersList } from '../../Redux/Actions/CustomersListAction'
class Page6 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bookingId: this.props.bookingId
        };
        this.props.bookingInfo({
            page1: {
                isvalidated : true,
                locationId: '',
                visitTypeId: '',
                bookieName: this.props.BookingInfo.page1.bookieName,
                bookieEmail: this.props.BookingInfo.page1.bookieEmail,              
                customerName: '',
                oppotunitySizeId: '',
                opportunityId: '',
                customerTypeId: '',
                regionId: '',
                subregionId: '',
                verticalId: '',
                timezone : '',
                disableFields : false
            }
        });
        this.props.bookingInfoNext({
            page2: {
                sizeOfVisitingParty: '',
                hclParticipants: '',
                timeSlotId: '',
                selectedDate: new Date(),
                selectedEndDate: new Date(),
                selectedTime: '',
            }
        });
        this.props.clearBookingProducts();
        this.props.currentPage(1);
        this.props.customerLunch(false);
        this.props.clearCustomersList();

    }

    getTranslations(key) {

        if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
            return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
            return "";
    }


    render() {

        return <fieldset className="wizard-fieldset show">

            <div className="col-md-12 right-section">
                <div className="booking_confirm">
                    <center>

                        <div className="p-4 text-gray">
                            <img src={checkImage} className="check_img" /></div>
                        <div className="num"> {this.getTranslations("User_BookingSubmittedPage_BookingInfo")} - <b> {this.state.bookingId} </b> </div>

                        <div className="successfully_msg"> {this.getTranslations("User_BookingSubmittedPage_BookingInfoMessage")}
                        </div>
                        <br />
                        <div><a href="/fluidbooking" > {this.getTranslations("User_BookingSubmittedPage_GoBackLink")} </a></div>

                    </center>
                </div>

            </div>



        </fieldset>

    }
}
const mapStateToProps = (state) => ({
    BookingInfo: state.BookingInfo,
    BookingInfoNext: state.BookingInfoNext,
    CurrentPage: state.CurrentPage,
    BookingProducts: state.BookingProducts,
    CustomerLunch: state.CustomerLunch,
    CustomersList: state.CustomersList,
    Translations: state.Translations
})

export default connect(mapStateToProps, { bookingInfo, clearBookingProducts, bookingInfoNext, currentPage, clearCustomersList, customerLunch, bookingProducts })(Page6);

