import roorReducers from './Reducer'
import storage from 'redux-persist/lib/storage'
import { persistReducer , persistStore } from 'redux-persist'
import thunkMiddleware  from 'redux-thunk'
import { configureStore } from "@reduxjs/toolkit";

const persistConfig = {
    key: 'fluidroot',
    storage,
  }

  const persistedReducer = persistReducer(persistConfig, roorReducers)

  export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunkMiddleware]
  })

export const persistor = persistStore(store)
