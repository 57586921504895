import { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Carousel } from "react-bootstrap";
import { loginUserInfoAdmin } from "../../Redux/Actions/AdminAuthenticationActions";
import { adminCurrentPage } from "../../Redux/Actions/AdminCurrentPageAction";
import { saveEditBookingInfo } from "../../Redux/Actions/AdminEditBookingAction";
import AddCustomerInfoInEdit from "./AddCustomerInfoInEdit";
import CustomerDetailsInEdit from "./CustomerDetailsInEdit";

class EditPage3 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      translations : this.props.translations,
      customers: this.props.AdminEditBooking.bookingDetails.customersEdited,       
      isShowCustomerList: this.props.AdminEditBooking.bookingDetails.customersEdited.every(customer=>customer.isValidated),
    };
  }

  ContinueButtonClick = () => {
    this.props.adminCurrentPage(4);
    this.props.sendData(4)
  }

  PreviousButtonClick = () => {
    this.props.adminCurrentPage(2);
    this.props.sendData(2)
  }

  handleNext = (selectedIdx) => {
    //check if last user validated to show customer list     
    if (selectedIdx == this.props.AdminEditBooking.bookingDetails.sizeOfVisitingParty - 1) {
      this.setState({ isShowCustomerList: true });
    }
    else {
      let selectedItem = document.getElementById("customer_details" + selectedIdx)
      selectedItem.setAttribute("class", "carousel-item");
      let nextItem = selectedIdx + 1
      let nextSelectedItem = document.getElementById("customer_details" + nextItem)
      nextSelectedItem.setAttribute("class", "active carousel-item")
    }
  }

  handlePrev = (selectedIdx) => {
    let selectedItem = document.getElementById("customer_details" + selectedIdx)
    selectedItem.setAttribute("class", "carousel-item");
    let prevItem = selectedIdx - 1;
    let prevItemSelectedItem = document.getElementById("customer_details" + prevItem)
    prevItemSelectedItem.setAttribute("class", "active carousel-item")
  }

  getTranslations(key) {   

    if(this.props.Translations.translations.filter(p => p.appKey == key)[0] !=null )
    return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
    return "";
  }
 

  render() {
    const customer = []
    for (let i = 0; i < this.props.AdminEditBooking.bookingDetails.sizeOfVisitingParty; i++) {
     
      customer.push(
        <Carousel.Item key={"customer_details" + i} id={"customer_details" + i}>
          <AddCustomerInfoInEdit translations={this.state.translations} currentState={this.state.customers[i]} serialNo={i} customersNumber={this.props.AdminEditBooking.bookingDetails.sizeOfVisitingParty} handleNext={this.handleNext} handlePrev={this.handlePrev} />
        </Carousel.Item>
      )
    }

  

    return (<fieldset className="wizard-fieldset show">
      {this.state.isShowCustomerList ? <>
        <h6 className="text-center pb-1">{this.getTranslations("User_VisitingDelegationPage_ListHeading")}</h6>        
        <CustomerDetailsInEdit />
      </> :
      <>
       
        <Carousel controls={false} wrap={null} interval={null} indicators={false} style={{ background: 'gray' }} prevIcon={false} nextIcon={false}>
          {customer}
        </Carousel>
      </>
      }
      <div className="form-group clearfix row btn-fix-bottom">
        <div className="text-center main-btn-fix">
        <button onClick={this.PreviousButtonClick} className="form-wizard-previous-btn float-left">Previous</button>
        {this.state.isShowCustomerList && <button onClick={this.ContinueButtonClick} className="form-wizard-next-btn float-right ">Continue <i className="fa fa-angle-right"></i></button>}        
      </div>
      </div>
    </fieldset>
    )
  }
}

const mapStateToProps = (state) => ({ 
  Translations: state.Translations,
  AdminAuthentication: state.AdminAuthentication,
  AdminEditBooking: state.AdminEditBooking,
  AdminCurrentPage: state.AdminCurrentPage,
  AdminInitialData: state.AdminInitialData
})

export default connect(mapStateToProps, {  loginUserInfoAdmin , adminCurrentPage , saveEditBookingInfo })(EditPage3);
