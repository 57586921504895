import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import API from "../../Api";
import withRouter from "../WithRouter";
import BackgroundImage from '../../Images/bg.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEnvelope, faUser, faUserAlt, faUsers } from "@fortawesome/fontawesome-free-solid";
import { faCircleCheck, faHamburger, faLocation, faLocationPin, faSquarePollVertical, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import 'moment-timezone'
import { loginUserInfoAdmin } from '../../Redux/Actions/AdminAuthenticationActions'
import { connect } from "react-redux";
import QRCode from "react-qr-code";

class BookingDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bookingDetail: [],
            customers: [],
            //products: [],
            isLoading: true,
            isBookieFeedbackDone: false,
            isAllCustomersFeedbackDone: false,
            displayCustomerText: "",
            redirect: '',
            summary: [],
            qrData: '',
            adminEmails: [],
            token: ''
        };
        this.barCodeRef = React.createRef();
    }

    OnBackClick = (e) => {
        this.setState({ redirect: "/bookingList" });
    }

    getTranslations(key) {

        if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
            return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
            return "";
    }


    async componentDidMount() {

        await new API().getBookingDetails(this.props.params.id).then(result => {
            this.setState({
                token: result.data.token,
                adminEmails: result.data.adminEmails,
                qrData: result.data.qrData,
                bookingDetail: result.data.bookingDetail,
                customers: result.data.customers,
                summary: result.data.summaries,
                //products: result.data.productsDemostrated,
                isLoading: false,
                isBookieFeedbackDone: result.data.isBookieFeedbackDone,
                isAllCustomersFeedbackDone: result.data.isAllCustomersFeedbackDone,
                displayCustomerText: result.data.bookingDetail.visitingPartySize > 1 ? " Customers + " : " Customer + "
            });
        });
    }   

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        if (this.props.AdminAuthentication.isLogin) {
            return <>

                <div id="wrapper">

                    <div id="sidebar">
                        <img src={BackgroundImage} width="100%" alt="" />
                    </div>

                    <div className="content-area-admin">
                        <section className="wizard-section booking-area">
                            <div className="scroll-cust">
                                <div className="row no-gutters mt-4">
                                    <h6 className="pb-2">{this.getTranslations("Admin_BookingDetailsPage_Heading")} </h6>
                                </div>

                                <div className="row pb-2">
                                    <div className="loop-table-main">
                                        <div className="loop-table-div w-100 mx-0">
                                            <table className="table table-bordered cnf-book-table">
                                                <tbody>
                                                    <tr>
                                                        <td> <i className="fa fa-user"><FontAwesomeIcon icon={faUserAlt}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.customerName} </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-user"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.bookieName} </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-user"><FontAwesomeIcon icon={faUserGroup}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.visitType} </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-envelope"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.bookieEmail} </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-envelope"><FontAwesomeIcon icon={faLocation}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.location} </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-clock"><FontAwesomeIcon icon={faClock}></FontAwesomeIcon></i> <span> {moment.tz(moment.utc(this.state.bookingDetail.bookingDate),this.state.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')}  -  {moment.tz(moment.utc(this.state.bookingDetail.bookingEndDate),this.state.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')} </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-users"><FontAwesomeIcon icon={faUsers}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.participantsSize + this.state.bookingDetail.visitingPartySize} Guests ({this.state.bookingDetail.visitingPartySize} {this.state.displayCustomerText} {this.state.bookingDetail.participantsSize} HCL) </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-hamburger"><FontAwesomeIcon icon={faHamburger}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.lunchRequired ? "Yes" : "No"} </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-hamburger"><FontAwesomeIcon icon={faSquarePollVertical}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.vertical} </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-hamburger"><FontAwesomeIcon icon={faLocationPin}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.regionName} - {this.state.bookingDetail.subRegion} </span> </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>


                                    </div>
                                </div>

                                <div className="row no-gutters">
                                    <h6 className="pb-2">{this.getTranslations("Admin_BookingDetailsPage_VisitDelegationHeading")}</h6>
                                </div>

                                <div className="row pb-2">
                                    <div className="loop-table-main">
                                        <div className="loop-table-div w-100 mx-0">
                                            <table className="table table-bordered cnf-book-table">
                                                <tbody>

                                                    {this.state.customers.map((item, i) => {
                                                        return [
                                                            <tr key={item.id + 'customers'}>
                                                                <td> <i className="fa fa-user"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i>
                                                                    <span> {item.customerName} ( {item.customerRole} ) - {item.customerEmail}</span> </td>
                                                            </tr>
                                                        ]

                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="row no-gutters">
                                    <h6 className="pb-2">{this.getTranslations("Admin_BookingDetailsPage_ProductCategoryHeading")}</h6>
                                </div> */}

                                {/* <div className="row">
                                    <div className="loop-table-main">
                                        <div className="loop-table-div w-100 mx-0">
                                            <table className="table table-bordered cnf-book-table">
                                                <tbody>
                                                    {this.state.products.map((item, i) => {
                                                        return [
                                                            <tr key={item.id + 'products'}>
                                                                <td>  <span> {item} </span> </td>
                                                            </tr>]

                                                    })}
                                                </tbody>
                                            </table>

                                        </div>


                                    </div>
                                </div> */}

                                {this.state.bookingDetail.isConverted || this.state.bookingDetail.convertedComments != null ?
                                    <div className="row no-gutters mt-2">
                                        <h6 className="pb-2">{this.getTranslations("Admin_BookingDetailsPage_StrategicSummaryHeading")}</h6>
                                    </div> : ""}

                                <div className="row">

                                    <div> {this.state.bookingDetail.isConverted ? this.getTranslations("Admin_BookingDetailsPage_DealConverted") : ""}</div>
                                    <div> {(this.state.bookingDetail.convertedComments == null || this.state.bookingDetail.convertedComments == '') ? "" : this.getTranslations("Admin_BookingDetailsPage_BusinessSummaryHeading") + " - " + this.state.bookingDetail.convertedComments}    </div>
                                    <div className="loop-table-main">
                                        <div className="loop-table-div w-100 mx-0">
                                            <table className="table table-bordered cnf-book-table">
                                                <tbody>
                                                    {this.state.summary.map((item, i) => {
                                                        return [
                                                            <tr key={item.id + 'summary'}>
                                                                <td>  <span> {item.name} </span>  -  <span className="date-bold"> {item.summary} </span> </td>
                                                            </tr>]

                                                    })}
                                                </tbody>
                                            </table>

                                        </div>


                                    </div>
                                </div>

                                {
                                    this.state.qrData != "" ?
                                        <>
                                            <div id="barcode" className="mt-4" style={{ height: "auto", margin: "0 auto", maxWidth: 300, width: "100%" }}>
                                                <QRCode ref={this.barCodeRef}
                                                    size={256}
                                                    className="QRCode"
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={this.state.qrData}
                                                    viewBox={`0 0 256 256`}
                                                />
                                            </div>
                                            {/* <button onClick={this.SendQRCodeOnEmail}>Send to mail</button> */}
                                        </>

                                        : ""
                                }


                            </div>

                            <div className="row no-gutters">
                                <div className="d-flex button-wrapper">
                                    <button onClick={this.OnBackClick} className="outline-button me-0" > Back</button>
                                    {this.state.bookingDetail.isCancelled || this.state.bookingDetail.isConverted ? "" : <>
                                        <a href={'/bookiefeedback/' + this.props.params.id} className={this.state.isBookieFeedbackDone ? "fill-button mx-2 pe-5" : "fill-button mx-2"}> {this.getTranslations("Admin_BookingDetailsPage_ButtonSalesFeedback")}  <i className="fa fa-angle-right float-end">
                                            {this.state.isBookieFeedbackDone ? <FontAwesomeIcon icon={faCircleCheck}></FontAwesomeIcon> : ""}
                                        </i></a>
                                        <a href={'/feedbackCustomers/' + this.props.params.id} className={this.state.isAllCustomersFeedbackDone ? "fill-button pe-5" : "fill-button"}> {this.getTranslations("Admin_BookingDetailsPage_ButtonCustomerFeedback")} <i className="fa fa-angle-right float-end">
                                            {this.state.isAllCustomersFeedbackDone ? <FontAwesomeIcon icon={faCircleCheck}></FontAwesomeIcon> : ""}
                                        </i></a>
                                    </>}
                                    <a href={'/printbookingdetails/' + this.props.params.id} target="_blank" className="fill-button mx-2" > {this.getTranslations("Admin_BookingDetailsPage_ButtonDownload")} </a>
                                </div>
                            </div>

                        </section>
                    </div>

                </div>
                <footer className="text-center">
                Copyright © {new Date().getFullYear()} HCL Technologies Limited
                </footer>

            </>
        }
        else {
            return <Navigate replace to="/admin" />;
        }
    }
}

const mapStateToProps = (state) => ({
    AdminAuthentication: state.AdminAuthentication,
    Translations: state.Translations
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(withRouter(BookingDetails));