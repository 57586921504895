import { TRANSLATIONS } from './../Actions/Type'

let initialState = {
    translations : []
};

function TranslationReducer(state = initialState, action) {
    if (action.type == TRANSLATIONS)
        return { ...state, translations: action.translations }
    else
        return state;
}

export default TranslationReducer;