import { Component } from "react";
import { Navigate } from "react-router-dom";
import API from "../../Api";
import FluidLogo from '../../Images/logo-fluid-studio.png'
import BackgroundImage from '../../Images/bg.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartPie, faEllipsisH, faFilter, faKey, faQuestionCircle, faTimes, faUserCircle } from "@fortawesome/fontawesome-free-solid";
import moment from "moment";
import 'moment-timezone'
import axios from "axios";
import { Toast, ToastContainer } from 'react-bootstrap'
import "react-datepicker/dist/react-datepicker.css";
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import ReactPaginate from 'react-paginate'
import $ from 'jquery';
import CancelBooking from './CancelBooking';
import GuestDetails from './GuestDetails';
import ConfirmBooking from './ConfirmBooking';
import Loader from "../Loader";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { DateRangePicker } from 'rsuite';
import { loginUserInfoAdmin } from '../../Redux/Actions/AdminAuthenticationActions'
import { connect } from "react-redux";
import { leftMenuSelection } from '../../Redux/Actions/AdminLeftMenuAction'
import ConvertToDeal from "./ConvertToDeal";
import { addtranslation } from "../../Redux/Actions/TranslationAction";
import ChangePassword from "../AdminDashboard/ConfigurationsPopups/ChangePassword";
import WarningConsent from "../AdminDashboard/WarningConsent";
import ViewQRCode from "./ViewQRCode";
import helpGuide from '../../AdminGuide.pdf'

class BookingsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bookings: [],
      isLoading: true,
      selectedFilter: localStorage.getItem("SelectedFilter") == null ? 'Pending Confirmation' : localStorage.getItem("SelectedFilter"),
      selectedFilterId: localStorage.getItem("SelectedFilterId") == null ? 1 : localStorage.getItem("SelectedFilterId"),
      selectedId: '',
      selectedName: '',
      isOpen: false,
      isCancelOpen: false,
      isGuestPopupOpen: false,
      isConvertOpen: false,
      lunchRequired: '',
      isDateValid: true,
      startDate: new Date(),
      endDate: new Date(),
      startDateFilterValue: null,
      endDateFilterValue: null,
      value: [],
      selectedDetails: {},
      activePage: 1,
      TotalRecords: 0,
      RecordPerPage: 0,
      showToast: false,
      toastMessageHeader: 'Booking Status',
      toastMessage: '',
      isCancelToast: false,
      isChangePasswordOpen: false,
      isWarningOpen: false,
      isQRCodeOpen: false,
      QRType: 1,
      displayId: ''
    };
  }
  onPageMove = (e) => {
    localStorage.setItem('feedbackFromList', true);
  }

  CancelBookingMailToBookie(headers, result, addmore, comments) {
    let bodyMsg =
      `<html><head></head><body><br />Dear ${result.data.bookingDetails.bookingDetail.bookieName},<br /> <br /> Your booking has been rejected by FLUID Experience Centre Team with the comments -<b> ${comments} </b><br />
          <span style=color:blue;>Booking Details</span> :
          <b>Booking No -</b> ${result.data.id}
          <b>Customer Name -</b> ${result.data.bookingDetails.bookingDetail.customerName}
          <b>Customer Type -</b> ${result.data.bookingDetails.bookingDetail.customerTypeName}
          <b>Location -</b> ${result.data.bookingDetails.bookingDetail.location}
          <b>Booking Start Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingDate),result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')} 
          <b>Booking End Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingEndDate),result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')}  
          <b>Lunch -</b> ${result.data.bookingDetails.bookingDetail.lunchRequired ? 'Yes' : 'No'}
          <b>Visiting Party Size -</b> ${result.data.bookingDetails.bookingDetail.visitingPartySize}
          <b>Participant Size -</b> ${result.data.bookingDetails.bookingDetail.participantsSize} <br />
          
          <span style=color:blue;>Visiting delegation -</span> ${result.data.bookingDetails.customers.map((cutomer) => '<br />' + cutomer.customerName + '       (' + cutomer.customerRole + ')    , ' + cutomer.customerEmail)}
          <br />
          <b>Thanks</b>
          FLUID Experience Centre Team
         </body>
        </html>`
    bodyMsg = bodyMsg.replace(/(?:\r\n|\r|\n)/g, '<br>')
    let sendmessageBody = `{
        "message": {
        "subject": "Booking Rejected #${result.data.id}",
        "body": {
        "contentType": "Html",
        "content": "${bodyMsg}"
        },
        "toRecipients": [
        {
        "emailAddress": {
        "address": "${result.data.bookingDetails.bookingDetail.bookieEmail}"
        }
        }
        ]
        },
        "saveToSentItems": "true"
        }`
    const URL = `https://graph.microsoft.com/v1.0/users/fluidcentrebookingsystem@hcldwpdev.com/sendMail`;
    axios.post(URL, sendmessageBody, { headers })
      .then(response => {

        let reminder = {
          BookingId: result.data.bookingDetails.bookingDetail.id,
          CancelEmailSentToBookie: true
        };

        new API().updateBookingReminderAfterCancel(reminder).then(res => {
          this.CancelBookingEmailToAdmin(headers, result, addmore, comments);
        });
      }).catch(error => console.log("error while sending mail", error));
  }

  CancelBookingEmailToAdmin(headers, result, addmore, comments) {
    let bodyMsg =
      `<html><head></head><body><br />Dear Team,<br /> <br />Booking has been rejected by admin with comments -<b> ${comments} </b><br />
           <span style=color:blue;>Booking Details</span> :
           <b>Booking No -</b> ${result.data.id}
           <b>Customer Name -</b> ${result.data.bookingDetails.bookingDetail.customerName}
           <b>Customer Type -</b> ${result.data.bookingDetails.bookingDetail.customerTypeName}
           <b>Location -</b> ${result.data.bookingDetails.bookingDetail.location}
           <b>Booking Start Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingDate),result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')} 
           <b>Booking End Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingEndDate),result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')}  
           <b>Lunch -</b> ${result.data.bookingDetails.bookingDetail.lunchRequired ? 'Yes' : 'No'}
           <b>Visiting Party Size -</b> ${result.data.bookingDetails.bookingDetail.visitingPartySize}
           <b>Participant Size -</b> ${result.data.bookingDetails.bookingDetail.participantsSize} <br />
          
          <span style=color:blue;>Visiting delegation -</span> ${result.data.bookingDetails.customers.map((cutomer) => '<br />' + cutomer.customerName + '       (' + cutomer.customerRole + ')    , ' + cutomer.customerEmail)}        
          <br /> ${process.env.REACT_APP_ADMIN_PORTAL_URL}
          <br /> <b>Thanks</b>
          FLUID Experience Centre Team        
          </body>
         </html>`
    bodyMsg = bodyMsg.replace(/(?:\r\n|\r|\n)/g, '<br>')
    let sendmessageBody = `{
         "message": {
         "subject": "Booking Rejected #${result.data.id}",
         "body": {
         "contentType": "Html",
         "content": "${bodyMsg}"
         }${addmore ? "," + addmore : ''}
         },
         "saveToSentItems": "true"
         }`
    const URL = `https://graph.microsoft.com/v1.0/users/fluidcentrebookingsystem@hcldwpdev.com/sendMail`;
    axios.post(URL, sendmessageBody, { headers })
      .then(response => {

        let reminder = {
          BookingId: result.data.bookingDetails.bookingDetail.id,
          CancelEmailSentToAdmin: true,
          CancelEmailSentToBookie: true
        };

        new API().updateBookingReminderAfterCancel(reminder).then(res => {
        });

      }).catch(error => console.log("error while sending mail", error));
  }

  onCancelConfirm = (e, comments) => {
    new API().CancelBookingWithComments(this.state.selectedId, comments).then(result => {

      this.setState({
        isCancelOpen: false,
        showToast: true,
        isCancelToast: true,
        toastMessageHeader: 'Update',
        toastMessage: 'Cancelled'
      });
      this.getBookings(1, this.state.activePage);

      let ccRecipients = result.data.adminEmails.map((adminMail) => `{"emailAddress": {
          "address": "${adminMail.trim()}"
        }
       }`)

      let addmore = result.data.adminEmails.length > 0 ? `"toRecipients": [${ccRecipients}]` : '';
      const headers = { 'Authorization': `Bearer ${result.data.token}`, 'Content-Type': 'application/json' };
      this.CancelBookingMailToBookie(headers, result, addmore, comments);
    });
  }

  onActionClick = (e) => {
    this.setState({
      selectedId: e.target.id,
      isOpen: true,
      isCancelOpen: false,
      isGuestPopupOpen: false,
      selectedDetails: this.state.bookings.filter(p => p.id == e.target.id)[0],
      lunchRequired: this.state.bookings.filter(p => p.id == e.target.id)[0].lunchRequired ? "Yes" : "No",
      //selectedDate: moment(this.state.bookings.filter(p => p.id == e.target.id)[0].bookingDate).toDate(),
      selectedName: e.target.name,
      selectedEndDate: moment.utc(this.state.bookings.filter(p => p.id == e.target.id)[0].bookingEndDate).toDate(),
      selectedDate: moment.utc(this.state.bookings.filter(p => p.id == e.target.id)[0].bookingDate).toDate()
    });

  }

  onConvertClick = (e) => {
    this.setState({
      selectedId: e.target.id,
      isOpen: false,
      isCancelOpen: false,
      isConvertOpen: true,
      isGuestPopupOpen: false,
      selectedDetails: this.state.bookings.filter(p => p.id == e.target.id)[0],
      selectedName: e.target.name,
      selectedDate: moment.utc(this.state.bookings.filter(p => p.id == e.target.id)[0].bookingDate).toDate()
    });
  }

  onQRClick = (e) => {
    this.setState({
      selectedId: e.target.id,
      displayId: this.state.bookings.filter(p => p.id == e.target.id)[0].displayId,
      isOpen: false,
      isCancelOpen: false,
      isConvertOpen: false,
      isGuestPopupOpen: false,
      isQRCodeOpen: true,
      QRType: 1,
      selectedDetails: this.state.bookings.filter(p => p.id == e.target.id)[0],
      selectedName: e.target.name,
      selectedDate: moment.utc(this.state.bookings.filter(p => p.id == e.target.id)[0].bookingDate).toDate()
    });
  }

  onQRClickSales = (e) => {
    this.setState({
      selectedId: e.target.id,
      displayId: this.state.bookings.filter(p => p.id == e.target.id)[0].displayId,
      isOpen: false,
      isCancelOpen: false,
      isConvertOpen: false,
      isGuestPopupOpen: false,
      isQRCodeOpen: true,
      QRType: 2,
      selectedDetails: this.state.bookings.filter(p => p.id == e.target.id)[0],
      selectedName: e.target.name,
      selectedDate: moment.utc(this.state.bookings.filter(p => p.id == e.target.id)[0].bookingDate).toDate()
    });
  }

  onGuestActionClick = (e) => {
    this.setState({
      selectedId: e.target.id,
      selectedDetails: this.state.bookings.filter(p => p.id == e.target.id)[0],
      isOpen: false,
      isCancelOpen: false,
      isGuestPopupOpen: true
    });

  }

  onCancelActionClick = (e) => {
    this.setState({
      selectedId: e.target.id,
      selectedDetails: this.state.bookings.filter(p => p.id == e.target.id)[0],
      isOpen: false,
      isCancelOpen: true,
      isGuestPopupOpen: false
    });

  }

  onCloseModelAfterSuccess = (e) => {
    this.setState({
      showToast: true,
      toastMessageHeader: 'Success',
      toastMessage: 'Confirmed',
      isCancelToast: false
    });
    this.getBookings(this.state.selectedFilterId, 1);
  }

  onCloseModel = (e) => {
    this.setState({ isOpen: false, isQRCodeOpen: false, isWarningOpen: false, isCancelOpen: false, isChangePasswordOpen: false, isGuestPopupOpen: false, isConvertOpen: false });
  }

  onCloseWarningModel = (e) => {
    this.setState({ isOpen: false, isWarningOpen: false, isCancelOpen: false, isChangePasswordOpen: false, isGuestPopupOpen: false, isConvertOpen: false });
    this.props.loginUserInfoAdmin({ isLogin: false, Username: '', role: '' });
  }

  OnDropdownChange = (e) => {
    localStorage.setItem("SelectedFilter", e.target.name);
    localStorage.setItem("SelectedFilterId", e.target.id);
    this.setState({ startDateFilterValue: null, endDateFilterValue: null, selectedFilter: e.target.name, selectedFilterId: e.target.id, activePage: 1, value: [] });
    this.getBookings(e.target.id, 1);

  }

  handleSubmitClick = (value) => {
    let startDateValue = moment(value[0]).format('YYYY-MM-DD')
    let endDateValue = moment(value[1]).format('YYYY-MM-DD hh:mm')
    this.setState({ startDateFilterValue: startDateValue, endDateFilterValue: endDateValue })
    this.getDateFilteredBookings(this.state.selectedFilterId, 1, startDateValue, endDateValue);
  }

  OnPageClick = (e) => {
    this.setState({ activePage: e.selected + 1 });
    if (this.state.startDateFilterValue == null && this.state.endDateFilterValue == null) {
      this.getBookings(this.state.selectedFilterId, e.selected + 1);
    }
    else {
      this.getDateFilteredBookings(this.state.selectedFilterId, e.selected + 1, this.state.startDateFilterValue, this.state.endDateFilterValue);
    }
  }

  async getBookings(filterId, pageno) {
    await new API().getFilterBookings(filterId, pageno).then(result => {
      this.setState({
        bookings: result.data.bookings,
        isLoading: false,
        // regions : result.data.regions,
        TotalRecords: result.data.totalCount,
        RecordPerPage: result.data.recordPerPage,
        isOpen: false,
        isCancelOpen: false,
        isGuestPopupOpen: false
      });
    });
  }

  async getDateFilteredBookings(filterId, pageno, startDateVal, endDateVal) {
    await new API().getDateFilteredBookings(filterId, pageno, startDateVal, endDateVal).then(result => {
      this.setState({
        bookings: result.data.bookings,
        isLoading: false,
        regions: result.data.regions,
        TotalRecords: result.data.totalCount,
        RecordPerPage: result.data.recordPerPage,
        isOpen: false,
        isCancelOpen: false,
        isGuestPopupOpen: false
      });
    });
  }

  OnDateChange = (value) => {
    this.setState({ startDate: value != null ? value[0] : null, endDate: value != null ? value[1] : null, value: value });

    if (value == null) {
      this.getBookings(this.state.selectedFilterId, 1);
    }

  }

  async componentDidMount() {

    new API().getMasterTranslations().then(result => {
      this.props.addtranslation(result.data)
    })

    if (localStorage.getItem("SelectedFilterId") == null) {
      await new API().getFilterBookings(1, this.state.activePage).then(result => {
        this.setState({
          bookings: result.data.bookings,
          isLoading: false,

          // regions : result.data.regions,
          TotalRecords: result.data.totalCount,
          RecordPerPage: result.data.recordPerPage
        });
      });
    }
    else {
      this.getBookings(this.state.selectedFilterId, 1);
    }


  }

  OnCloseToast = (e) => {
    this.setState({ showToast: !this.state.showToast });
  }

  navBarToggle = (e) => {
    $(".custom-nav").toggleClass("opensidebar");
  }

  signOutfromApp = (e) => {

    this.props.loginUserInfoAdmin({ isLogin: false, Username: '', role: '' });
    this.props.leftMenuSelection({ selection: false, selectionLogs: false, selectedSubMenu: 0 });
    this.setState({ redirect: '/admin' });

  }

  handletoggleWindow = (e) => {
    let closeQuickActionWindow = true;
    if (e.target.getAttribute("class") && (e.target.getAttribute("class").includes('navbar-toggler') || e.target.getAttribute("class").includes('fa-user-circle')))
      closeQuickActionWindow = false;

    let nodes = document.getElementsByClassName("custom-nav");
    if (nodes.length > 0 && closeQuickActionWindow)
      nodes[0].setAttribute("class", "custom-nav")

  };

  getFirstLetterUpper(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  onNavigateToDashboard = () => {
    this.props.leftMenuSelection({ selection: false, selectionLogs: false, selectedSubMenu: 0 });

  }

  onChangePasswordClick = (e) => {
    this.setState({ isChangePasswordOpen: true, errorMessage: '' });
  }

  onConvertConfirm = (dataToPost) => {

    new API().convertedBookingToDeal(dataToPost).then(result => {
      if (result.data.isConverted) {

        this.setState({
          isOpen: false, isCancelOpen: false, isGuestPopupOpen: false, isConvertOpen: false,
          showToast: dataToPost.isConvertToDeal,
          isCancelToast: false,
          toastMessageHeader: 'Deal converted',
          toastMessage: 'Converted to deal'
        });
        this.getBookings(4, this.state.activePage);

        let ccRecipients = result.data.adminEmails.map((adminMail) => `{"emailAddress": {
          "address": "${adminMail.trim()}"
        }
       }`)

        let addmore = result.data.adminEmails.length > 0 ? `"toRecipients": [${ccRecipients}]` : '';
        const headers = { 'Authorization': `Bearer ${result.data.token}`, 'Content-Type': 'application/json' };

        if (dataToPost.isConvertToDeal) {
          this.ConvertToDealEmailToAdmin(headers, result, addmore, dataToPost);
          this.ConvertToDealMailToBookie(headers, result, addmore, dataToPost);
        }
      }
    })
  }

  ConvertToDealMailToBookie(headers, result, addmore, dataToPost) {
    let bodyMsg =
      `<html><head></head><body><br />Dear ${result.data.bookingDetails.bookingDetail.bookieName},<br /> <br /> <span> Your booking has been Converted to successful deal by FLUID Experience Centre Team <br /> </span>
          <span style=color:blue;>Booking Details</span> :
          <b>Booking No -</b> ${result.data.id}
          <b>Customer Name -</b> ${result.data.bookingDetails.bookingDetail.customerName}
          <b>Customer Type -</b> ${result.data.bookingDetails.bookingDetail.customerTypeName}
          <b>Location -</b> ${result.data.bookingDetails.bookingDetail.location}
          <b>Booking Start Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingDate),result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')} 
          <b>Booking End Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingEndDate),result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')}  
          <b>Lunch -</b> ${result.data.bookingDetails.bookingDetail.lunchRequired ? 'Yes' : 'No'}
          <b>Visiting Party Size -</b> ${result.data.bookingDetails.bookingDetail.visitingPartySize}
          <b>Participant Size -</b> ${result.data.bookingDetails.bookingDetail.participantsSize} <br />
          
          <span style=color:blue;>Visiting delegation -</span> ${result.data.bookingDetails.customers.map((cutomer) => '<br />' + cutomer.customerName + '       (' + cutomer.customerRole + ')    , ' + cutomer.customerEmail)}
          <br />
          <b>Thanks</b>
          FLUID Experience Centre Team
         </body>
        </html>`
    bodyMsg = bodyMsg.replace(/(?:\r\n|\r|\n)/g, '<br>')
    let sendmessageBody = `{
        "message": {
        "subject": "Booking ID #${result.data.id} Converted to DEAL",
        "body": {
        "contentType": "Html",
        "content": "${bodyMsg}"
        },
        "toRecipients": [
        {
        "emailAddress": {
        "address": "${result.data.bookingDetails.bookingDetail.bookieEmail}"
        }
        }
        ]
        },
        "saveToSentItems": "true"
        }`
    const URL = `https://graph.microsoft.com/v1.0/users/fluidcentrebookingsystem@hcldwpdev.com/sendMail`;
    axios.post(URL, sendmessageBody, { headers })
      .then(response => {
      }).catch(error => {
        new API().logServiceErrorToDatabase(error);
        console.log("error while sending mail", error);
      });
  }

  ConvertToDealEmailToAdmin(headers, result, addmore, dataToPost) {
    let bodyMsg =
      `<html><head></head><body><br />Congratulations !!<br /> <br /> Customer visit has been converted to deal successfully.<br />
         <span style=color:blue;>Booking Details</span> :
         <b>Booking No -</b> ${result.data.id}
         <b>Bookie Name -</b> ${result.data.bookingDetails.bookingDetail.bookieName}
         <b>Bookie Email -</b> ${result.data.bookingDetails.bookingDetail.bookieEmail}
         <b>Customer Name -</b> ${result.data.bookingDetails.bookingDetail.customerName}
         <b>Customer Type -</b> ${result.data.bookingDetails.bookingDetail.customerTypeName}
         <b>Location -</b> ${result.data.bookingDetails.bookingDetail.location}
         <b>Booking Start Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingDate),result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')} 
         <b>Booking End Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingEndDate),result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')}  
         <b>Lunch -</b> ${result.data.bookingDetails.bookingDetail.lunchRequired ? 'Yes' : 'No'}
         <b>Visiting Party Size -</b> ${result.data.bookingDetails.bookingDetail.visitingPartySize}
         <b>Participant Size -</b> ${result.data.bookingDetails.bookingDetail.participantsSize} <br />
        
        <span style=color:blue;>Visitors -</span> ${result.data.bookingDetails.customers.map((cutomer) => '<br />' + cutomer.customerName + '       (' + cutomer.customerRole + ')    , ' + cutomer.customerEmail)}   
        <br />  <span style=color:blue;>Business Summary -</span> <br />  ${dataToPost.comments}   
        <br /> <span style=color:blue;>Products Summary -</span> ${result.data.bookingDetails.summaries.map((summ) => '<br />' + summ.name + '  -  ' + summ.summary)}   
           
        <br /> <b>Thanks</b>
         DWP Fluid Booking Team        
        </body>
       </html>`
    bodyMsg = bodyMsg.replace(/(?:\r\n|\r|\n)/g, '<br>')
    let sendmessageBody = `{
       "message": {
       "subject": "Deal converted successfully (Booking ID #${result.data.id})",
       "body": {
       "contentType": "Html",
       "content": "${bodyMsg}"
       }${addmore ? "," + addmore : ''}
       },
       "saveToSentItems": "true"
       }`

    const URL = `https://graph.microsoft.com/v1.0/users/fluidcentrebookingsystem@hcldwpdev.com/sendMail`;
    axios.post(URL, sendmessageBody, { headers })
      .then(response => {
      }).catch(error => {
        new API().logServiceErrorToDatabase(error);
        console.log("error while sending mail", error);
      });
  }

  onChangePassword = (e) => {

    new API().updateMasterUserPassword(e).then(result => {
      if (result.data.result) {
        this.setState({ errorMessage: '', isWarningOpen: true, showToast: false, isChangePasswordOpen: false });

      }
      else {
        if (result.data.errorMessage != "") {
          this.setState({ isChangePasswordOpen: true, errorMessage: result.data.errorMessage });
        }
      }

    });
  }

  getTranslations(key) {

    if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
      return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
      return "";
  }

  render() {

    if (this.props.AdminAuthentication.isLogin) {
      return <>

        <ToastContainer position="bottom-end">
          <Toast delay={10000} autohide show={this.state.showToast} onClose={this.OnCloseToast}>
            <Toast.Header>
              <strong className="me-auto">{this.state.toastMessageHeader}</strong>
            </Toast.Header>
            <Toast.Body className={this.state.isCancelToast ? "bg-danger text-white" : "bg-success text-white"}> Booking ID {this.state.selectedDetails.displayId} {this.state.toastMessage}.</Toast.Body>
          </Toast>
        </ToastContainer>

        <div id="wrapper" className="wrapper-admin" onClick={(e) => this.handletoggleWindow(e)}>

          <div id="sidebar">
            <img src={BackgroundImage} width="100%" alt="" />
          </div>
          
          <div className="content-area-admin">
            <section className="wizard-section booking-area px-1">
              {
                this.state.isQRCodeOpen ? "" :
                  <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                    <a className="addbooking-button" href={'/fluidbooking'} target="_blank"> Add Booking </a>
                      <h2 className="welcome-user navbar-toggler" onClick={this.navBarToggle}> Welcome {this.getFirstLetterUpper(this.props.AdminAuthentication.Username)} <FontAwesomeIcon className="ms-1" icon={faUserCircle} size="xl"></FontAwesomeIcon> </h2>
                      <button className="navbar-toggler hide-desk" onClick={this.navBarToggle} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div className="custom-nav" >
                        <div className="user-profile-detail" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                          <span className="user-circle"> <i className='fas fa-user-circle'><FontAwesomeIcon icon={faUserCircle}></FontAwesomeIcon></i></span>
                          <div className="ps-2 d-flex justify-content-center flex-column">
                            <div className="name-user"> {this.getFirstLetterUpper(this.props.AdminAuthentication.Username)} </div>
                          </div>
                        </div>

                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                          <li className="nav-item">
                            <a href={'/dashboard'} onClick={this.onNavigateToDashboard} target="_blank" className="nav-link" aria-current="page" > <i className='fas fa-ticket-alt'><FontAwesomeIcon icon={faChartPie}></FontAwesomeIcon></i> Dashboard</a>
                            <a onClick={this.onChangePasswordClick} className="nav-link" aria-current="page" > <i className='fas fa-key'><FontAwesomeIcon icon={faKey}></FontAwesomeIcon></i> Change Password</a>
                            <a className="nav-link" aria-current="page" target="_blank" href={helpGuide}> <i ><FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon></i> Help</a>
                            <a onClick={this.signOutfromApp} className="nav-link for-mobile" aria-current="page" > <i className='fas fa-ticket-alt'><FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon></i> Sign out</a>

                          </li>

                        </ul>
                        <a onClick={this.signOutfromApp} className="closesidebar for-desktop"> Sign out </a>
                        <a onClick={this.navBarToggle} className="closesidebar for-mobile"> <i className='fas fa-times'><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon></i> </a>

                      </div>
                    </div>
                  </nav>
              }
              
              <h2 className="text-center"><img src={FluidLogo} width="200" alt="Fluid Studio" /></h2>


              <div className="row no-gutters">

              </div>

              <div className="row">
              
              </div>
              <div className="row mb-3">

                <div className="col-md-12 d-flex justify-content-between align-items-center customFilter">

                  <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="fa fa-filter" aria-hidden="true">
                        <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
                      </i>
                      <span className="px-2"> {this.state.selectedFilter} </span>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      {/* <li><button id="0" onClick={this.OnDropdownChange} className="dropdown-item" name="All bookings" >ALL</button></li> */}
                      <li><button id="1" onClick={this.OnDropdownChange} className="dropdown-item" name="Pending confirmation" >Pending confirmation</button></li>
                      <li><button id="2" onClick={this.OnDropdownChange} className="dropdown-item" name="Confirmed booking">Confirmed booking</button></li>
                      <li><button id="3" onClick={this.OnDropdownChange} className="dropdown-item" name="Cancelled booking">Cancelled booking</button></li>
                      <li><button id="4" onClick={this.OnDropdownChange} className="dropdown-item" name="Concluded">Concluded</button></li>
                      <li><button id="5" onClick={this.OnDropdownChange} className="dropdown-item" name="Converted deals">Converted deals</button></li>
                    </ul>
                  </div>
                  <div className="d-flex w-100">

                    <DateRangePicker
                      size="sm"
                      name="selectedDate"
                      placeholder="Select Booking Date Range"
                      onChange={this.OnDateChange}
                      // onChangeRaw={(e) => e.preventDefault()}
                      value={this.state.value}
                      showOneCalendar={true}
                      onOk={this.handleSubmitClick}
                      ranges={[]}
                      character=' - '
                      format='dd/MMM/yyyy'
                    />

                    {/* <div className="form-group text-center">
                <button onClick={this.handleSubmitClick} type="submit" className="w-100 fill-button btn-sm btn range-search-picker" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal"> Search </button>
              </div> */}

                  </div>
                  {
                    this.state.bookings.length > 0 ?

                      <ReactPaginate
                        forcePage={this.state.activePage - 1}
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={this.OnPageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={(this.state.TotalRecords > this.state.RecordPerPage) ? Math.ceil(this.state.TotalRecords / this.state.RecordPerPage) : 1}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        className="cust-pagination mx-2"
                      /> : ""
                  }
                </div>
              </div>
              <div className="row">
                {
                  this.state.isLoading ? <Loader /> :

                    <div className="col-md-12 text-center table-responsive min-h">

                      {
                        this.state.bookings.length == 0 ? <div className="nobookingtext">No Booking Found</div> : ""
                      }

                      <Table id="example"  >

                        <Tbody>
                          {this.state.bookings.map((item, i) => {
                            return [
                              <Tr key={item.id}>
                                <Td> {this.getTranslations("Admin_BookingListPage_BookingId")} : <span className="date-bold"> {item.displayId} </span>
                                  <div> {this.getTranslations("Admin_BookingListPage_BookieName")} : <span> {item.bookieName} </span></div>
                                </Td>
                                <Td>
                                  <div> {this.getTranslations("Admin_BookingListPage_OrgName")} : <span> {item.customerName} </span></div>
                                  <div> {this.getTranslations("Admin_BookingListPage_Email")} : <span> {item.bookieEmail}  </span> </div>


                                </Td>
                                <Td>
                                  <div> {this.getTranslations("Admin_BookingListPage_StartDate")} : <span className="date-bold"> {moment.tz(moment.utc(item.bookingDate),item.timezone).format('MMM Do YYYY, HH:mm A')}    </span></div>

                                  <div> {this.getTranslations("Admin_BookingListPage_EndDate")} : <span className="date-bold"> {moment.tz(moment.utc(item.bookingEndDate),item.timezone).format('MMM Do YYYY, HH:mm A')}   </span></div>

                                </Td>

                                <Td>
                                  <div> {this.getTranslations("Admin_BookingListPage_VisitType")} : <span> {item.visitType} </span> </div>
                                  <div> {this.getTranslations("Admin_BookingListPage_SizeOfVisitParty")} : <a onClick={this.onGuestActionClick} id={item.id} className="edit date-bold" data-bs-toggle="modal" data-bs-target="#guestModal"><span id={item.id}> {item.visitingPartySize} </span> </a> </div>
                                </Td>

                                <Td>
                                  <div> {this.getTranslations("Admin_BookingListPage_Lunch")} : <span> {item.lunchRequired ? 'Yes' : 'No'} </span></div>
                                  <div> {this.getTranslations("Admin_BookingListPage_Location")} : <span className="date-bold"> {item.location} </span></div>
                               
                                </Td>

                                <Td>
                                  <div className="dropdown">
                                    <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                      <i className="fa fa-ellipsis-h">
                                        <FontAwesomeIcon icon={faEllipsisH}></FontAwesomeIcon>
                                      </i>
                                    </button>

                                    {
                                      this.getOptionsToDisplay(item)
                                    }

                                  </div>

                                </Td>
                              </Tr>
                            ]

                          })}

                        </Tbody>
                      </Table>

                    </div>}
              </div>

            </section>

          </div>
        </div>
        <footer className="text-center">
          Copyright © {new Date().getFullYear()} HCL Technologies Limited
        </footer>
        {this.state.isCancelOpen && <CancelBooking Translations={this.props.Translations} isCancelOpen={this.state.isCancelOpen} onCloseModel={this.onCloseModel} onCancelConfirm={this.onCancelConfirm} />}
        {this.state.isGuestPopupOpen && <GuestDetails Translations={this.props.Translations} isGuestPopupOpen={this.state.isGuestPopupOpen} onCloseModel={this.onCloseModel} customers={this.state.selectedDetails.customers} />}
        {this.state.isOpen &&
          <ConfirmBooking Translations={this.props.Translations} isOpen={this.state.isOpen}
            onCloseModel={this.onCloseModel}
            onCloseModelAfterSuccess={this.onCloseModelAfterSuccess}
            selectedDetails={this.state.selectedDetails}
            // regions={this.state.regions}
            lunchRequired={this.state.lunchRequired}
            selectedDate={this.state.selectedDate}
            selectedEndDate={this.state.selectedEndDate}
            selectedName={this.state.selectedName}
            getBookings={this.getBookings} />}
        {
          this.state.isConvertOpen && <ConvertToDeal Translations={this.props.Translations} bookingId={this.state.selectedId} onConvertConfirm={this.onConvertConfirm} isConvertOpen={this.state.isConvertOpen} onCloseModel={this.onCloseModel} />
        }
        {
          this.state.isChangePasswordOpen && <ChangePassword Username={this.props.AdminAuthentication.Username} isChangeOpen={this.state.isChangePasswordOpen} onCloseModel={this.onCloseModel} onChangePassword={this.onChangePassword} errorMessage={this.state.errorMessage} />
        }

        {
          this.state.isQRCodeOpen && <ViewQRCode qrType={this.state.QRType} Translations={this.props.Translations} displayId={this.state.displayId} bookingId={this.state.selectedId} isQRCodeOpen={this.state.isQRCodeOpen} onCloseModel={this.onCloseModel} />
        }

        {
          this.state.isWarningOpen ?
            <WarningConsent infoMessage="Password changed successfully, Login again with new password." isWarningOpen={this.state.isWarningOpen} onCloseModel={this.onCloseWarningModel} />
            : ""
        }
      </>
    }
    else {
      return <Navigate replace to="/admin" />;
    }
  }

  getOptionsToDisplay(item) {

    if (item.isConverted) {
      return <> <ul className="dropdown-menu dropdown-menu-buttons" aria-labelledby="dropdownMenuButton1">
        <li><a id={item.id} href={'/bookingdetails/' + item.id}> {this.getTranslations("Admin_BookingListPage_ButtonDetails")} </a></li>
      </ul> </>
    }
    else if (item.isConcluded) {
      return <> <ul className="dropdown-menu dropdown-menu-buttons" aria-labelledby="dropdownMenuButton1">
        <li>  <a name="convertToDeal" id={item.id} onClick={this.onConvertClick} className="edit" data-toggle="modal" data-bs-toggle="modal" data-bs-target="#exampleModal"> {this.getTranslations("Admin_BookingListPage_ButtonConvertToDeal")}</a></li>
        <li><a id={item.id} href={'/bookingdetails/' + item.id}>{this.getTranslations("Admin_BookingListPage_ButtonDetails")}</a></li>
      </ul>
      </>
    }
    else if (item.isConfirmed || (item.isCancelled != null && item.isCancelled)) {
      return <> <ul className="dropdown-menu dropdown-menu-buttons" aria-labelledby="dropdownMenuButton1">
        <li><a id={item.id} href={'/bookingdetails/' + item.id}>{this.getTranslations("Admin_BookingListPage_ButtonDetails")}</a></li>
        {item.isConfirmed ? <>
          <li><a className="position-relative" id={item.id} onClick={this.onQRClick} data-toggle="modal" data-bs-toggle="modal" data-bs-target="#exampleModal" > {this.getTranslations("Admin_BookingListPage_ButtonQRCode")} </a></li>
          <li><a className="position-relative" id={item.id} onClick={this.onQRClickSales} data-toggle="modal" data-bs-toggle="modal" data-bs-target="#exampleModal" > {this.getTranslations("Admin_BookingListPage_ButtonQRCodeSales")} </a></li>

          {/* <li><a className="position-relative" id={item.id} onClick={this.onPageMove} href={'/bookiefeedback/' + item.id}> {this.getTranslations("Admin_BookingListPage_ButtonSalesFeedback")} {item.isBookieFeedbackDone ? <FontAwesomeIcon id={item.id} icon={faCircleCheck}></FontAwesomeIcon> : ""} </a></li> */}
          {/* <li><a className="position-relative" id={item.id} onClick={this.onPageMove} href={'/feedbackCustomers/' + item.id}> {this.getTranslations("Admin_BookingListPage_ButtonCustomerFeedback")} {item.isAllCustomersFeedbackDone ? <FontAwesomeIcon id={item.id} icon={faCircleCheck}></FontAwesomeIcon> : ""}</a></li> */}
        </>
          : ""}
      </ul>
      </>
    }
    else {
      return <> <ul className="dropdown-menu dropdown-menu-buttons" aria-labelledby="dropdownMenuButton1">
        <li><a name="edit" href={'/editbooking/' + item.id} id={item.id} className="edit" target="_blank">{this.getTranslations("Admin_BookingListPage_ButtonEdit")}</a></li>
        <li>  <a name="confirm" id={item.id} onClick={this.onActionClick} className="edit" data-toggle="modal" data-bs-toggle="modal" data-bs-target="#exampleModal">{this.getTranslations("Admin_BookingListPage_ButtonConfirm")}</a></li>
        <li>  <a id={item.id} onClick={this.onCancelActionClick} className="edit" data-toggle="modal">{this.getTranslations("Admin_BookingListPage_ButtonCancel")}</a></li>
      </ul> </>
    }
  }
}

const mapStateToProps = (state) => ({
  AdminAuthentication: state.AdminAuthentication,
  AdminLeftMenu: state.AdminLeftMenu,
  Translations: state.Translations
})

export default connect(mapStateToProps, { addtranslation, loginUserInfoAdmin, leftMenuSelection })(BookingsList);