import { Component } from "react";
import { Navigate } from "react-router-dom";
import API from "../../Api";
import BackgroundImage from '../../Images/bg.png'
import withRouter from "../WithRouter";
import { loginUserInfoAdmin } from '../../Redux/Actions/AdminAuthenticationActions'
import { connect } from "react-redux";

class FeedBackDetailCustomer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            selectedLevelBefore: '',
            selectedLevelAfter: '',
            selectedoverallRating: '',
            questions: [],
            products: [],
            canEdit: true,
            feedbackId: '',
            perceptionRed: '',
            perceptionAmber: '',
            isButtonDisabled : true
        }
    }

    onSaveClick = (e) => {
        this.saveData(true);
    }

    saveData(canedit) {
        let feedbackRequest = {
            FeedbackType: 2,
            Name: this.state.name,
            OverallRating: this.state.selectedoverallRating,
            BookingId: this.props.params.id,
            CustomerId: this.props.params.custId,
            ConfidenceLevelBefore: this.state.selectedLevelBefore,
            ConfidenceLevelAfter: this.state.selectedLevelAfter,
            Questions: this.state.questions,
            //Products: this.state.products,
            CanEdit: canedit,
            id: this.state.feedbackId
        }

        new API().addBookingFeedback(feedbackRequest).then(result => {
            if (result.data) {
                this.setState({ redirect: "/feedbackCustomers/" + this.props.params.id });

            }
        });
    }

    OnSubmitClick = (e) => {
        this.saveData(false);
    }


    async componentDidMount() {
        let feedbackRequest = {
            BookingId: this.props.params.id,
            FeedbackType: 2,
            CustomerId: this.props.params.custId
        }
        await new API().getBookingFeedback(feedbackRequest).then(result => {
            this.setState({
                perceptionRed: result.data.perceptionRed,
                perceptionAmber: result.data.perceptionAmber,
                name: result.data.name,
                questions: result.data.questions,
                isButtonDisabled : (result.data.confidenceLevelAfter > 0 && result.data.confidenceLevelBefore > 0 && result.data.overallRating > 0) ? false : true,
                //products: result.data.products,
                selectedLevelAfter: result.data.confidenceLevelAfter,
                selectedLevelBefore: result.data.confidenceLevelBefore,
                selectedoverallRating: result.data.overallRating,
                canEdit: result.data.canEdit,
                feedbackId: result.data.id
            });
        });
    }

    updateQuestionComment = (e) => {

        const oldQuestions = this.state.questions;
        const questionToModify = oldQuestions.find(p => p.id == e.target.id);
        questionToModify.comments = e.target.value;

        this.setState({ questions: oldQuestions })

    }

    updateProductsComment = (e) => {

        let id = e.target.id.split('-')[0];
        const oldProducts = this.state.products;
        const productToModify = oldProducts.find(p => p.id == id);
        productToModify.comments = e.target.value;

        this.setState({ products: oldProducts })

    }


    updateBeforeLevel = (e) => {
        this.setState({ selectedLevelBefore: e.target.value });
        if(this.state.selectedLevelAfter > 0 && this.state.selectedoverallRating > 0)
        {
            this.setState({isButtonDisabled : false});
        }
    }

    updateAfterLevel = (e) => {
        this.setState({ selectedLevelAfter: e.target.value });
        if(this.state.selectedLevelBefore > 0  && this.state.selectedoverallRating > 0)
        {
            this.setState({isButtonDisabled : false});
        }
    }

    OnBackClick = (e) => {

        this.setState({ redirect: "/feedbackCustomers/" + this.props.params.id });

    }

    getTranslations(key) {

        if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
            return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
            return "";
    }

    onChangeRating = (e) => {
        this.setState({ selectedoverallRating: e.target.value });
        if(this.state.selectedLevelBefore > 0 && this.state.selectedLevelAfter > 0)
        {
            this.setState({isButtonDisabled : false});
        }
    }

    addColorClass = (e) => {

        if (this.state.perceptionRed >= e) {
            return "redCustom";
        }
        else if (this.state.perceptionAmber >= e) {
            return "amberCustom";
        }
        else {
            return "greenCustom";
        }
    }


    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        if (this.props.AdminAuthentication.isLogin) {
            return <div>

                <>

                    <div id="wrapper">

                        <div id="sidebar">
                            <img src={BackgroundImage} width="100%" alt="" />
                        </div>

                        <div className="content-area">
                            <div className="form-wizard-feedback">

                                <h6 className="mt-2 feedback-heading"> Dear {this.state.name}, <br/> 
                               <span> {this.getTranslations("Admin_CustomerFeedbackPage_Heading")} </span> </h6>

                                <div className="row feedback-row">

                                    <div className="form-wizard form-pro-demo">
                                        <div className="row mb-3">

                                            <h6 className="pro-label mb-2">{this.getTranslations("Admin_CustomerFeedbackPage_PerceptionBefore")}</h6>

                                            <div className="col-md-9">
                                                <div className="stv-radio-buttons-wrapper number-feedback position-relative mb-2">

                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelBefore == 1 ? true : false} onChange={this.updateBeforeLevel} type="radio" className="stv-radio-button" name="confidenceLevelBefore" value="1" id="size1" />
                                                    <label className={this.addColorClass(1)} htmlFor="size1">1</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelBefore == 2 ? true : false} onChange={this.updateBeforeLevel} type="radio" className="stv-radio-button" name="confidenceLevelBefore" value="2" id="size2" />
                                                    <label className={this.addColorClass(2)} htmlFor="size2">2</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelBefore == 3 ? true : false} onChange={this.updateBeforeLevel} type="radio" className="stv-radio-button" name="confidenceLevelBefore" value="3" id="size3" />
                                                    <label className={this.addColorClass(3)} htmlFor="size3">3</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelBefore == 4 ? true : false} onChange={this.updateBeforeLevel} type="radio" className="stv-radio-button" name="confidenceLevelBefore" value="4" id="size4" />
                                                    <label className={this.addColorClass(4)} htmlFor="size4">4</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelBefore == 5 ? true : false} onChange={this.updateBeforeLevel} type="radio" className="stv-radio-button" name="confidenceLevelBefore" value="5" id="size5" />
                                                    <label className={this.addColorClass(5)} htmlFor="size5">5</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelBefore == 6 ? true : false} onChange={this.updateBeforeLevel} type="radio" className="stv-radio-button" name="confidenceLevelBefore" value="6" id="size6" />
                                                    <label className={this.addColorClass(6)} htmlFor="size6">6</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelBefore == 7 ? true : false} onChange={this.updateBeforeLevel} type="radio" className="stv-radio-button" name="confidenceLevelBefore" value="7" id="size7" />
                                                    <label className={this.addColorClass(7)} htmlFor="size7">7</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelBefore == 8 ? true : false} onChange={this.updateBeforeLevel} type="radio" className="stv-radio-button" name="confidenceLevelBefore" value="8" id="size8" />
                                                    <label className={this.addColorClass(8)} htmlFor="size8">8</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelBefore == 9 ? true : false} onChange={this.updateBeforeLevel} type="radio" className="stv-radio-button" name="confidenceLevelBefore" value="9" id="size9" />
                                                    <label className={this.addColorClass(9)} htmlFor="size9">9</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelBefore == 10 ? true : false} onChange={this.updateBeforeLevel} type="radio" className="stv-radio-button" name="confidenceLevelBefore" value="10" id="size10" />
                                                    <label className={this.addColorClass(10)} htmlFor="size10">10</label>
                                                    <div className="LeftMessage">{this.getTranslations("Admin_CustomerFeedbackPage_PerceptionLeftNote")}</div>
                                                    <div className="RightMessage">{this.getTranslations("Admin_CustomerFeedbackPage_PerceptionRightNote")}</div>
                                              
                                                </div>
                                                
                                            </div>

                                        </div>

                                        {/* <div className="row mt-2">
                                            <h6 className="pro-label mb-2">{this.getTranslations("Admin_CustomerFeedbackPage_Products")}</h6>

                                            {this.state.products.map((item, i) => {
                                                return [
                                                    <div key={item.id} className="col-md-6">
                                                        <div >
                                                            <label> {item.product} </label>

                                                            <div class="star-rating">
                                                                <input disabled={!this.state.canEdit} checked={item.comments == 5 ? true : false} onChange={this.updateProductsComment} type="radio" id={item.id + "-5stars"} name={item.id + "-5stars"} value="5" />
                                                                <label for={item.id + "-5stars"} class="star">&#9733;</label>
                                                                <input disabled={!this.state.canEdit} checked={item.comments == 4 ? true : false} onChange={this.updateProductsComment} type="radio" id={item.id + "-4stars"} name={item.id + "-4stars"} value="4" />
                                                                <label for={item.id + "-4stars"} class="star">&#9733;</label>
                                                                <input disabled={!this.state.canEdit} checked={item.comments == 3 ? true : false} onChange={this.updateProductsComment} type="radio" id={item.id + "-3stars"} name={item.id + "-3stars"} value="3" />
                                                                <label for={item.id + "-3stars"} class="star">&#9733;</label>
                                                                <input disabled={!this.state.canEdit} checked={item.comments == 2 ? true : false} onChange={this.updateProductsComment} type="radio" id={item.id + "-2stars"} name={item.id + "-2stars"} value="2" />
                                                                <label for={item.id + "-2stars"} class="star">&#9733;</label>
                                                                <input disabled={!this.state.canEdit} checked={item.comments == 1 ? true : false} onChange={this.updateProductsComment} type="radio" id={item.id + "-1stars"} name={item.id + "-1stars"} value="1" />
                                                                <label for={item.id + "-1stars"} class="star">&#9733;</label>
                                                            </div>

                                                        </div>
                                                    </div>

                                                ]
                                            })}


                                        </div> */}



                                        <div className="row mb-3 mt-2">
                                            <h6 className="pro-label mb-2">{this.getTranslations("Admin_CustomerFeedbackPage_PerceptionAfter")}</h6>
                                            <div className="col-md-9">
                                            <div className="stv-radio-buttons-wrapper number-feedback position-relative mb-2">

                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelAfter == 1 ? true : false} onChange={this.updateAfterLevel} type="radio" className="stv-radio-button" name="confidenceLevelAfter" value="1" id="After1" />
                                                    <label className={this.addColorClass(1)} htmlFor="After1">1</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelAfter == 2 ? true : false} onChange={this.updateAfterLevel} type="radio" className="stv-radio-button" name="confidenceLevelAfter" value="2" id="After2" />
                                                    <label className={this.addColorClass(2)} htmlFor="After2">2</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelAfter == 3 ? true : false} onChange={this.updateAfterLevel} type="radio" className="stv-radio-button" name="confidenceLevelAfter" value="3" id="After3" />
                                                    <label className={this.addColorClass(3)} htmlFor="After3">3</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelAfter == 4 ? true : false} onChange={this.updateAfterLevel} type="radio" className="stv-radio-button" name="confidenceLevelAfter" value="4" id="After4" />
                                                    <label className={this.addColorClass(4)} htmlFor="After4">4</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelAfter == 5 ? true : false} onChange={this.updateAfterLevel} type="radio" className="stv-radio-button" name="confidenceLevelAfter" value="5" id="After5" />
                                                    <label className={this.addColorClass(5)} htmlFor="After5">5</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelAfter == 6 ? true : false} onChange={this.updateAfterLevel} type="radio" className="stv-radio-button" name="confidenceLevelAfter" value="6" id="After6" />
                                                    <label className={this.addColorClass(6)} htmlFor="After6">6</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelAfter == 7 ? true : false} onChange={this.updateAfterLevel} type="radio" className="stv-radio-button" name="confidenceLevelAfter" value="7" id="After7" />
                                                    <label className={this.addColorClass(7)} htmlFor="After7">7</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelAfter == 8 ? true : false} onChange={this.updateAfterLevel} type="radio" className="stv-radio-button" name="confidenceLevelAfter" value="8" id="After8" />
                                                    <label className={this.addColorClass(8)} htmlFor="After8">8</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelAfter == 9 ? true : false} onChange={this.updateAfterLevel} type="radio" className="stv-radio-button" name="confidenceLevelAfter" value="9" id="After9" />
                                                    <label className={this.addColorClass(9)} htmlFor="After9">9</label>
                                                    <input disabled={!this.state.canEdit} checked={this.state.selectedLevelAfter == 10 ? true : false} onChange={this.updateAfterLevel} type="radio" className="stv-radio-button" name="confidenceLevelAfter" value="10" id="After10" />
                                                    <label className={this.addColorClass(10)} htmlFor="After10">10</label>
                                                    <div className="LeftMessage">{this.getTranslations("Admin_CustomerFeedbackPage_PerceptionLeftNote")}</div>
                                                    <div className="RightMessage">{this.getTranslations("Admin_CustomerFeedbackPage_PerceptionRightNote")}</div>
                                              
                                                </div>
                                               
                                            </div>


                                        </div>

                                        <div className="row">

                                            {this.state.questions.map((item, i) => {
                                                return [
                                                    <div key={item.id} className="col-md-12">
                                                        <div className="feedback-wrapper">
                                                            <div className="mb-2">
                                                                <h6>{item.question}</h6>
                                                                <textarea disabled={!this.state.canEdit} id={item.id} value={item.comments} onChange={this.updateQuestionComment} placeholder={this.getTranslations("Admin_CustomerFeedbackPage_Watermark")} className="form-control"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                ]

                                            })}

                                        </div>

                                        <div className="row mt-0">
                                            <div className="col-md-12">
                                                <div className="feedback-wrapper">
                                                    <div className="mb-2">
                                                        <h6>{this.getTranslations("Admin_CustomerFeedbackPage_OverallExperience")}</h6>
                                                        <div className={!this.state.canEdit ? "star-rating displaystar-rating" : "star-rating"}>
                                                            <input disabled={!this.state.canEdit} checked={this.state.selectedoverallRating == 5 ? true : false} onChange={this.onChangeRating} type="radio" id="5-stars" name="rating" value="5" />
                                                            <label for="5-stars" className="star">&#9733;</label>
                                                            <input disabled={!this.state.canEdit} checked={this.state.selectedoverallRating == 4 ? true : false} onChange={this.onChangeRating} type="radio" id="4-stars" name="rating" value="4" />
                                                            <label for="4-stars" className="star">&#9733;</label>
                                                            <input disabled={!this.state.canEdit} checked={this.state.selectedoverallRating == 3 ? true : false} onChange={this.onChangeRating} type="radio" id="3-stars" name="rating" value="3" />
                                                            <label for="3-stars" className="star">&#9733;</label>
                                                            <input disabled={!this.state.canEdit} checked={this.state.selectedoverallRating == 2 ? true : false} onChange={this.onChangeRating} type="radio" id="2-stars" name="rating" value="2" />
                                                            <label for="2-stars" className="star">&#9733;</label>
                                                            <input disabled={!this.state.canEdit} checked={this.state.selectedoverallRating == 1 ? true : false} onChange={this.onChangeRating} type="radio" id="1-star" name="rating" value="1" />
                                                            <label for="1-star" className="star">&#9733;</label>
                                                        </div>
                                                        <div>
                                                            {this.getTranslations("Admin_CustomerFeedbackPage_OverallExperienceNote")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="row no-gutters mt-3">
                                    <div className="d-flex button-wrapper">
                                        <button onClick={this.OnBackClick} className="outline-button" > Back</button>
                                        {!this.state.canEdit ? "" : <>
                                            <button disabled={this.state.isButtonDisabled} onClick={this.onSaveClick} className={this.state.isButtonDisabled ? "fill-button disabled" : "fill-button" }> Save </button>
                                            <button disabled={this.state.isButtonDisabled} onClick={this.OnSubmitClick} className={this.state.isButtonDisabled ? "fill-button disabled" : "fill-button" }> Submit </button>
                                        </>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <footer className="text-center">
                    Copyright © {new Date().getFullYear()} HCL Technologies Limited
                    </footer>

                </>

            </div>
        }
        else {
            return <Navigate replace to="/admin" />;
        }
    }
}

const mapStateToProps = (state) => ({
    AdminAuthentication: state.AdminAuthentication,
    Translations: state.Translations
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(withRouter(FeedBackDetailCustomer));